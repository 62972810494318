import React, { useContext, useEffect, useRef, useState } from 'react'

import LeftArrowIcon from '../../../icons/LeftArrowIcon'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import InputText from '../../../components/FormComponets/InputText'
import TextArea from '../../../components/FormComponets/TextArea'
import ManageThumbnail from '../../Creator/my-work/upload-creator-content/ManageThumnail'
import DeleteIcon from '../../../icons/Delete'
import CloudUploadIcon from '../../../icons/CloudUpload'
import Checkbox from '../../../components/Checkbox/Checkbox'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import { formatFileSize, getFileMimeType, getFileSize, getIcon, INITIAL_FORM_DATA, renderPreview, validate } from './helper'
import { useSelector } from 'react-redux'
import { ulid } from 'ulid'
import { addProjectContent, createSchoolCollection, editProjectContent, getUploadUrl, uploadFileTos3 } from '../../../services/schoolServices'
import { addImageInCollection } from '../../../services/creatorService'
import useToast from '../../../hooks/useToast'
import { SidebarContext } from '../../../App'


function SchoolProjectContentForm() {
    const navigate = useNavigate()
    const { setGlobalLoader } = useContext(SidebarContext)
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const { contentData, commissionXrefId } = location?.state || { contentData: "" }
    const showToast = useToast()
    const formType = searchParams.get('type') || 'add';
    const [changedData, setChangedData] = useState({
        video: false,
        image: false,
        thumbnail: false
    })
    const contentSubmitted = contentData?.isSubmited


    const [loading, setLoading] = useState(false)
    const [checkboxError, setCheckboxError] = useState(false)
    const [formData, setFormData] = useState(INITIAL_FORM_DATA)
    const [formState, setFormState] = useState({
        isValid: false,
        isLoading: false
    });
    const objectUrlsForPreview = useRef({})
    const [thumbnails, setThumbnails] = useState({})
    const { schoolId, userId, userType = "school" } = useSelector((state) => state.user);

    const state = useSelector((state) => state.user);


    function handleFormElementChange(e) {
        const { name, type, value, checked } = e.target;

        // Update the value based on the input change
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: {
                ...prevFormData[name], // Copy the previous object to ensure immutability
                value: name === INITIAL_FORM_DATA.isChecked.name ? checked : value, // Update the value based on input type
            },
        }));

    }

    const clearForm = () => {
        // Reset the form data to the initial state
        setFormData(INITIAL_FORM_DATA);
    };

    useEffect(() => {
        // Validate form state whenever formData changes
        let valid = validate({ formData })

        setFormState((prev) => ({ ...prev, isValid: valid })); // Update the validation state
    }, [formData]); // Run this effect whenever formData changes

    useEffect(() => {
        if (contentData && contentData?.type === 'edit' && formType === 'edit') {
            const addData = async () => {
                const {
                    content_title,
                    creator_bio,
                    creator_name,
                    inspiration,
                    name,
                    links,
                    url,
                    thumbnailUrl,
                    id
                } = contentData;

                let fileType = await getFileMimeType(url)
                // fileType=fileType.split('/')[1]||""
                let file_name = id + "." + fileType.split('/')[1]
                setThumbnails({ [file_name]: { url: thumbnailUrl, type: 'image/jpg', name: id + "_tumbnail.jpg", isEditData: true } })

                const files = [{
                    url,
                    thumbnailUrl,
                    name: id + "." + fileType.split('/')[1] || "",
                    type: fileType.split('/')[0] || "image",
                    size: await getFileSize(url),
                    isEditData: true
                }]
                // Create a new object based on the existing form data
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    collectionName: { ...prevFormData.collectionName, value: name },
                    creatorName: { ...prevFormData.creatorName, value: creator_name },
                    contentTitle: { ...prevFormData.contentTitle, value: content_title },
                    inspiration: { ...prevFormData.inspiration, value: inspiration },
                    bio: { ...prevFormData.bio, value: creator_bio },
                    links: { ...prevFormData.links, value: links },
                    files: { ...prevFormData.files, value: files },


                }));
            }
            addData()
        } else {
            clearForm();
        }
    }, [formType, contentData]);



    function handleFileChange(event) {
        const newFiles = Array.from(event.target.files);
        event.target.value = '';
        const allowedExtensions = new Set(['png', 'svg', 'gif', 'jpeg', 'jpg', 'mp4'])
        let files = newFiles.filter((file) => {
            let extension = file?.name?.split('.').pop().toLowerCase();
            return allowedExtensions.has(extension);
        })
        if (newFiles.length !== files.length) {
            alert("Only png, svg, gif, jpeg and mp4 files are allowed");
        }
        if (!files.length) return

        files.forEach(file => {
            objectUrlsForPreview.current[file?.name] = URL.createObjectURL(file);
        })
        const totalCount = formData.files.value.length + files.length;
        if (totalCount > 5) {
            alert("You can only upload a maximum of 5 files");
        } else {
            setFormData(prev => ({ ...prev, files: { ...prev.files, value: [...prev.files.value, ...files] } }))
        }
    }

    const removeFile = (index) => {
        const file = formData.files.value[index]
        // setSelectedFiles((files) => files.filter((file, i) => i !== index));
        const updatedFiles = formData.files.value.filter((file, i) => i !== index)
        setFormData(prev => ({ ...prev, files: { ...prev.files, value: updatedFiles } }))

        // removing object preview url 
        delete objectUrlsForPreview.current[file?.name]

        const temp = { ...thumbnails }
        delete temp[file?.name]
        setThumbnails({ ...temp })

        formType === "edit" && setChangedData({
            video: true,
            image: true,
            thumbnail: true
        })
    };


    const updateEditUrl = (url) => {
        const urlParts = url.split('/');
        let version = urlParts[6] ? urlParts[6] : "v1";
        version = `v${parseInt(version.slice(1)) + 1}`; // Increment version number
        urlParts[6] = version;
        const updatedUrl = urlParts.join('/');
        return updatedUrl;
    };





    const getSignedUrlAndUploadMedia = async (mediaItems, contentId) => {
        let response = [];
        if (!mediaItems.length) return response;

        let keyNames = [];
        await mediaItems.forEach(({ videoFile, thumbnailFile }) => {
            const _ulid = ulid();
            const videoKey = contentData?.type == "edit" && videoFile ? updateEditUrl(contentData?.url) : `content/${userType}/${userId}/${contentId}/video/${_ulid}`;
            const thumbnailKey = contentData?.type == "edit" && thumbnailFile ? updateEditUrl(contentData?.thumbnailUrl) : `content/${userType}/${userId}/${contentId}/video/${_ulid}_thumbnail`;


            response.push({
                videoName: videoFile?.name,
                videoKey,
                thumbnailName: thumbnailFile?.name,
                thumbnailKey
            });

            keyNames.push(videoKey, thumbnailKey);
        });

        const { urls } = await getUploadUrl(keyNames);
        if (urls.length !== keyNames.length) {
            throw new Error('The number of upload URLs does not match the number of keys.');
        }
        const uploadPromises = mediaItems.map((item, index) => {
            const videoUrl = urls[index * 2];
            const thumbnailUrl = urls[index * 2 + 1];

            const promises = [];
            if (formType == "edit") {
                if (videoUrl && changedData?.video) promises.push(uploadFileTos3(videoUrl, item.videoFile));
                if (thumbnailUrl && changedData?.thumbnail) promises.push(uploadFileTos3(thumbnailUrl, item.thumbnailFile));
            }
            else {

                if (videoUrl) promises.push(uploadFileTos3(videoUrl, item.videoFile));
                if (thumbnailUrl) promises.push(uploadFileTos3(thumbnailUrl, item.thumbnailFile));
            }

            return Promise.all(promises);
        });

        await Promise.all(uploadPromises);
        return response;
    };

    const getSignedUrlAndUploadImage = async (files, contentId) => {
        let response = [];
        if (!files.length) return response;

        let keyNames = await files.map((file) => {

            const key = formType == "edit" && contentData?.content_key ? updateEditUrl(contentData?.content_key) : `content/${userType}/${userId}/${contentId}/photography/${ulid()}`;
            response.push({ name: file?.name, key });
            return key;
        });

        const { urls } = await getUploadUrl(keyNames);

        const promises = [];

        const uploadPromises = urls.map((url, index) => {
            if (formType == "edit") {
                if (url && changedData?.image) promises.push(uploadFileTos3(url, files[index]))
            }
            else {
                if (url) promises.push(uploadFileTos3(url, files[index]))
            }
        })
        await Promise.all(promises)
        return response;
    };

    const validateForUpload = () => {
        if (!formData.creatorName.value) {
            alert("Creator Name is required");
            return false;
        }
        if (!formData.inspiration.value) {
            alert("Inspiration is required");
            return false;
        }
        if (formData.files.value?.length > 5) {
            alert("You can only upload a maximum of 5 files");
            return false;
        }
        if (!formData.files.value?.length) {
            alert("You have to upload at least 1 file");
            return false;
        }
        return true;
    };

    const handleContentSubmit = async (e) => {
        setGlobalLoader(true)
        e.preventDefault();

        if (!formData.isChecked.value) {
            setCheckboxError(true);
            setGlobalLoader(false)
            return;
        }

        if (!validateForUpload()) return;

        setLoading(true);
        try {
            const images = [];
            const videos = [];

            formData.files.value.forEach((item) => {
                if (item.type.includes('video')) {
                    let thumbnailData = thumbnails[item.name]
                    if (!thumbnailData?.isEditData) {
                        let payload = {
                            thumbnailFile: thumbnailData
                        }
                        if (!item?.isEditData) {
                            payload = {
                                ...payload,
                                videoFile: item,
                                isNew: true

                            }
                        }
                        videos.push({
                            ...payload,
                            // videoFile: item,
                            // thumbnailFile: thumbnailData
                        });
                    }
                } else {
                    if (!item?.url && !item?.isEditData) {
                        images.push(item);
                    }
                }

            });

            // setGlobalLoader(false)
            // return
            if (videos.length > 0 && videos.length !== Object.keys(thumbnails).length) {
                alert('Thumbnails are required for each video');
                setGlobalLoader(false)
                return;
            }

            // setGlobalLoader(false)
            // return
            if (contentData?.type === "edit") {

                let files, videoFiles;
                if (images.length) {
                    files = await getSignedUrlAndUploadImage(
                        images,
                        contentData?.id
                    );
                }
                if (videos.length) {
                    videoFiles = await getSignedUrlAndUploadMedia(
                        videos,
                        contentData?.id
                    )
                }

                const requestBody = {
                    id: contentData?.id,
                    commision_school_xref_id: contentData?.commision_school_xref_id || "",
                    files,
                    videoFiles,
                    creator_name: formData.creatorName.value,
                    content_title: formData.contentTitle.value,
                    inspiration: formData.inspiration.value,
                    creator_bio: formData.bio.value,
                    social_media_links: formData.links.value,
                };


                // Submit form data (use your service here)
                await editProjectContent({ ...requestBody });
                clearForm()
                setGlobalLoader(false)

                navigate(-1)
                showToast(contentData?.type === "edit" ? "Content edit successfully" : "New images added successfully", 'success')


            }
            else {
                let contentId = ulid();

                //                 const files =[]//
                // const videoFiles =[]
                const files = await getSignedUrlAndUploadImage(images, contentId);
                const videoFiles = await getSignedUrlAndUploadMedia(videos, contentId);
                const requestBody = {
                    commision_school_xref_id: commissionXrefId || "",
                    files,
                    videoFiles,
                    creator_name: formData.creatorName.value,
                    content_title: formData.contentTitle.value,
                    inspiration: formData.inspiration.value,
                    creator_bio: formData.bio.value,
                    school_id: schoolId,
                    school_user_id: userId,
                    social_media_links: formData.links.value,
                };


                // Submit form data (use your service here)
                await addProjectContent(requestBody);
                clearForm()
                setGlobalLoader(false)

                navigate(-1);
            }

        } catch (error) {
            alert("Error creating collection");
            console.error(error);
            setGlobalLoader(false)

        } finally {
            setLoading(false);
        }
    };





    return (
        <div className='max-w-[1400px] flex flex-col gap-6 mt-12 px-12'>
            <div className="flex flex-col gap-4">
                <button className="flex flex-row gap-2 items-center w-fit" onClick={() => navigate(-1)}>
                    <LeftArrowIcon />
                    <span className="text-violet text-lg font-semibold">Back to Project page</span>
                </button>
                <p className="mt-4 text-4xl font-semibold">
                    Add Content
                </p>

                <div className="flex flex-col w-full">
                    <div className="grid md:grid-cols-2 w-full gap-x-8 gap-y-6">

                        <InputText disabled={contentSubmitted} name={formData.creatorName.name} value={formData.creatorName.value} onChange={handleFormElementChange} className=" w-full" label='Creator name' placeholder='First and last name' isImportant={true} />

                        <InputText disabled={contentSubmitted} name={formData.contentTitle.name} value={formData.contentTitle.value} onChange={handleFormElementChange} className=" w-full" label='Content title' placeholder='What is your artwork name?' isImportant={true} />

                        <TextArea disabled={contentSubmitted} name={formData.inspiration.name} value={formData.inspiration.value} onChange={handleFormElementChange} className='p-3' wordLimit={200} rows="15" label='Inspiration' isImportant={true} placeholder='What has inspired you to create this piece?' />

                        <TextArea disabled={contentSubmitted} name={formData.bio.name} value={formData.bio.value} onChange={handleFormElementChange} className='p-3' wordLimit={200} rows="15" label='Tell us a bit about yourself' isImportant={true} placeholder='Creator bio written in first person' />

                        <div className="col-span-2">
                            <TextArea disabled={contentSubmitted} name={formData.links.name} value={formData.links.value} onChange={handleFormElementChange} className='p-3' rows="8" placeholder='Add your social media links or names here, if desired.' />
                        </div>


                        <div className="col-span-2">
                            <div className='w-full flex flex-col gap-2'>

                                {
                                    (formType !== "edit" || (formType === "edit" && formData.files.value.length !== 1)) &&
                                    <>

                                        <div className=''>
                                            Upload your content <span className='text-red-500'>*</span>
                                        </div>
                                        <label className="upload-control" htmlFor="upload-input">
                                            :
                                            <input
                                                type="file"
                                                multiple
                                                id="upload-input"
                                                accept="image/*, video/*"
                                                onChange={handleFileChange}
                                                disabled={formType == "edit" && formData.files.value.length == 1 ? true : false}
                                            />
                                            <div className="uploadicon p-2 mb-3 rounded-md">
                                                {/* <UploadIcon /> */}
                                                <CloudUploadIcon />
                                            </div>
                                            <div className="upload-options">
                                                <span className="click">Click to upload
                                                    {/* <span className='text-gray-500'>or drag and drop</span> */}
                                                </span>
                                            </div>
                                            <div className=" mt-0 p-0 text-gray-500">
                                                Image or Video (MP4 only)
                                                {/* Zip or folder */}
                                            </div>
                                        </label>
                                    </>
                                }
                                {formData.files.value.length > 0 && (
                                    <ul
                                        style={{ listStyleType: "none", margin: 0, padding: 0 }}
                                        className="flex flex-col gap-y-3"
                                    >
                                        {formData.files.value.map((file, index) => (
                                            <li className="selected-files my-1 flex flex-col gap-1" key={file?.name}>
                                                <div className="selected-file-grouping">
                                                    <div className="icons-files">{getIcon(file?.name || "")}</div>
                                                    <div className="file-details">
                                                        <div className="filename">{file?.name}</div>
                                                        <div className="filetype">
                                                            {/* {file?.size} */}
                                                            ({formatFileSize(file?.size || "")})
                                                        </div>
                                                    </div>
                                                    {
                                                        !contentSubmitted &&
                                                        <div
                                                            className="cursor-pointer"
                                                            onClick={() => removeFile(index)}
                                                        >
                                                            <DeleteIcon />
                                                        </div>
                                                    }
                                                </div>
                                                {
                                                    renderPreview({ file, previewObject: file?.url || objectUrlsForPreview.current[file?.name] })
                                                }

                                                <ManageThumbnail
                                                    fileName={file?.name}
                                                    isVideo={file.type?.includes('video')}
                                                    thumbnails={thumbnails}
                                                    setThumbnails={(e) => {
                                                        setThumbnails(e);
                                                        setChangedData({
                                                            ...changedData,
                                                            thumbnail: true
                                                        })
                                                    }}
                                                    disabledThumnailChange={thumbnails[file?.name]?.isEditData}
                                                    videoIndex={index}

                                                />

                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>

                        {
                            !contentSubmitted &&
                            <div className='w-full col-span-2'>
                                <Checkbox
                                    name={formData.isChecked.name}
                                    checkboxError={checkboxError}
                                    isSubmitting={loading}
                                    checked={formData.isChecked.value}
                                    onChange={handleFormElementChange}
                                    className={`${!formData.isChecked.value ? "text-black" : "text-black"}`}
                                    label={'I certify that the submitted creative works are original, created by me, and have not been plagiarized from any other sources'}
                                />

                            </div>
                        }


                    </div>
                    {
                        !contentSubmitted &&
                        <div className="w-full flex justify-end mt-4">
                            <PrimaryButton
                                onClick={handleContentSubmit}

                                disabled={!formState.isValid}
                                text={`${contentData?.type === "edit" ? "Save" : "Add"} to project gallery`} />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default SchoolProjectContentForm


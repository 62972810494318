import React, { useState, useEffect, useContext } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';


// import { CognitoIdentityServiceProvider } from 'aws-sdk';
// import { OAuth2Client } from 'google-auth-library';
import { checkUserEmail, confirmUser, createUserWithoutOtp, deleteUserByEmail, signUpUser } from '../../services/aws/AwsService';
import { setLocalStorage } from '../../lib/session';
import { generateToken, login, signup } from '../../services/AuthServices';
import useToast from '../../hooks/useToast';
import { useDispatch } from 'react-redux';
import { loginSuccess, profileData, profile as profileDispatcher } from '../../redux/actions';
import { SidebarContext } from '../../App';
import { useNavigate } from 'react-router-dom/dist';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import defPasswordGen from '../../utils/defPasswordGen';
import checkRestrictedEmailDomains from '../../utils/checkRestrictedEmailDomains';
// import { SignupContext } from '../../pages/registration/Registration';
import { useAuthProvider } from '../../contexts/AuthContext';





function GoogleAuth({ type = "login", text = "Sign in with Google", changeScreen, onSuccess, onError, userData = null, buttonClass = "", buttonStyle = {}, icon = '' }) {
    const { signupData, changeSignupData } = useAuthProvider()
    const { userType, code } = signupData

    const [user, setUser] = useState(userData);
    const [profile, setProfile] = useState(null);

    const showToast = useToast()
    const dispatch = useDispatch()
    const { refresh, setRefresh, setGlobalLoader } = useContext(SidebarContext)
    const navigate = useNavigate();

    // Handle Google login
    const handleLogin = useGoogleLogin({
        onSuccess: (response) => {
            setUser(response);
            setGlobalLoader(false)
            // console.log(response.access_token, 'response.access_tokenresponse.access_token')
            // loginWithGoogle(response.access_token)
            if (onSuccess) {
                onSuccess(response);
            }
        },
        onError: (error) => {
            console.log('Login Failed:', error);
            setGlobalLoader(false)
            if (onError) {
                onError(error);
            }
        },
        error_callback: () => {
            setGlobalLoader(false)
        }

    });

    const triggerLogin = async () => {
        setGlobalLoader(true); // start loader before login request

        handleLogin(); // initiate login

    };


    // Fetch user profile on successful login
    useEffect(() => {
        if (user && user.access_token) {
            setGlobalLoader(true)
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json',
                    },
                })
                .then(async (res) => {
                    setGlobalLoader(true)

                    setProfile(res.data);
                    let emailCheck
                    if (userType) {
                        emailCheck = userType !== 'creator' && await checkRestrictedEmailDomains(res?.data?.email)
                    }
                    if (emailCheck) {
                        showToast("Please provide your work email address.", 'error')
                        setGlobalLoader(false)
                        return
                    }
                    loginWithGoogle(res?.data?.email)
                    if (onSuccess) {
                        setGlobalLoader(false)
                        onSuccess(res.data);  // Pass the profile data to the parent
                    }
                })
                .catch((err) => {
                    setGlobalLoader(false)

                    console.log(err);
                    if (onError) {
                        onError(err);
                    }
                })
        }
    }, [user, onSuccess, onError, type]);





    // Handle Google login
    const loginWithGoogle = async (email) => {
        try {
            setGlobalLoader(true)
            // Check if email exists in Cognito
            const existingUser = await checkUserEmail(email);
            let { emailExists, emailVerified, cognitoId } = existingUser

            if (emailExists && emailVerified && cognitoId) {  //&& (existingUser?.userType === "brand")
                // If user exists, link Google to their existing Cognito account
                //   await linkGoogleToCognitoUser(existingUser.Username, googleId);
                let userData = {}
                let userType = existingUser?.userType

                // if (userType === 'brand') {
                const data = await generateToken({ payload: { locale: userType, sub: existingUser?.cognitoId } })
                userData = await login({ jwtToken: data.token }) // verify and create token from backend




                setLocalStorage("token", JSON.stringify(userData.token));
                setLocalStorage("userRole", userType);

                let loginDispatchData = {
                    isLoggedIn: true,
                    username: email.toLowerCase(),
                    token: userData.token,
                    userId: userData.user_id,
                    brandId: userData.brand_id,
                    schoolId: userData.school_id,
                    creatorId: userData.creator_id,
                    userType: userType || "brand",
                    isOwner: userData?.isOwner,
                    role: userData?.role
                }

                if (userType === "brand") {
                    loginDispatchData = {
                        ...loginDispatchData,
                        brandId: userData?.brandId
                    }
                }
                else if (userType === "school") {
                    loginDispatchData = {
                        ...loginDispatchData,
                        schoolId: userData?.schoolId
                    }
                }
                else if (userType === "creator") {
                    loginDispatchData = {
                        ...loginDispatchData,
                        creatorId: userData?.creatorId
                    }
                }
                dispatch(loginSuccess({...loginDispatchData}));;
                
                dispatch(profileDispatcher(userData?.profileImg || ""));

                showToast("Login successful", 'success')
                setGlobalLoader(false)
                setRefresh(!refresh)
                navigate("/");
                console.log('Google account linked to existing Cognito user');
                return

            }

            else {
                if (type === "login" || !signupData?.userType) {
                    showToast('User not exist.', 'error')
                    setGlobalLoader(false)
                    return
                }

                // If no user exists, proceed with creating a new Cognito user with Google
                // Handle Google sign-up (similar to earlier process)



                try {
                    setGlobalLoader(true)
                    if (!emailExists) {
                        const userRes = await addUserInCognito(email)
                        cognitoId = userRes?.cognitoId
                    }

                    const res = await handleSignupSubmit(email, cognitoId)
                    // navigate("/registration");

                    setGlobalLoader(false);

                } catch (error) {
                    console.log('error in singup with google ', error)
                    await deleteUserByEmail(email)
                    setGlobalLoader(false)
                }
                // setGlobalLoader(false)
            }

            // Proceed with authenticating the user
        } catch (error) {
            console.log('Google login failed:', error);
            setGlobalLoader(false)
        }
    };


    const [cognitoId, setCognitoId] = useState('')

    //---- add account in cognito ------------
    const addUserInCognito = async (email) => {
        try {
            setGlobalLoader(true)
            const attributeList = [
                new CognitoUserAttribute({
                    Name: "email",
                    Value: email,
                }),
                new CognitoUserAttribute({
                    Name: "locale",
                    Value: userType,
                }),
                new CognitoUserAttribute({
                    Name: "name",
                    Value: email?.split("@")[0] || "",
                }),
            ];
            const passwordDummy = await defPasswordGen(email)
            // const resp = await signUpUser(email, passwordDummy, attributeList)
            const resp = await createUserWithoutOtp(email, passwordDummy, attributeList)
            setCognitoId(resp?.cognitoId || "")
            return resp

        } catch (err) {
            await deleteUserByEmail(email)
            console.log(err, 'error in cognito signup')
        }
    }
    // ---------------------------------------



    const [loading, setLoading] = useState('')

    const handleSignupSubmit = async (email, cognitoId) => {
        try {
            setGlobalLoader(true)
            setLoading(true)
            if (cognitoId) {

                let payload = {
                    userType,
                    email: email,
                    fullName: email?.split("@")[0] || "",
                    role: "editor",
                    cognito_id: cognitoId,
                    signupMethod: 'google'
                }
                if (userType === "creator") {
                    payload = {
                        ...payload,
                        email: email,
                        "userType": "creator",
                        "cognito_id": cognitoId,
                        "refferal": code || ""
                    }
                }
                if (userType == "school") {
                    payload = {
                        ...payload,
                        userType: "school",
                        role: "teacher",
                    }
                }

                signup({ ...payload }).then(async (result) => {

                    try {
                        await confirmUser(email, "force")
                        setLocalStorage("token", JSON.stringify(result.token))
                        setLocalStorage("userRole", userType || "brand");
                        // dispatch(loginSuccess({
                        //     isLoggedIn: false,
                        //     username: email.toLowerCase(),
                        //     token: result?.token || '',
                        //     userId: result?.userId || '',
                        //     brandId: result?.brand_id || '',
                        //     schoolId: result?.school_id || '',
                        //     creatorId: result?.creator_id || '',
                        //     userRole: userType || "brand",
                        //     isOwner: result?.isOwner || '',
                        //     role: result?.role || '',
                        //     userType: result?.userType || ''
                        // }));;
                        // dispatch(profileDispatcher(result?.profileImg || ""));

                        dispatch(loginSuccess({
                            isLoggedIn: false,
                            username: email.toLowerCase(),
                            token: result.token,
                            userId: result.userId,
                            brandId: result.brand_id,
                            schoolId: result.school_id,
                            creatorId: result.creator_id,
                            userRole: userType || "brand",
                            isOwner: result?.isOwner,
                            role: result?.role,
                            userType: result.userType
                        }));;
                        dispatch(profileData({
                            isLoggedIn: false,
                            username: email.toLowerCase(),
                            token: result.token,
                            userId: result.user_id,
                            brandId: result.brand_id,
                            schoolId: result.school_id,
                            creatorId: result.creator_id,
                            userRole: userType || "brand",
                            isOwner: result?.isOwner,
                            role: result?.role,
                            id: result.creator_id || result.school_id || result.brand_id
                        }));
                        dispatch(profileDispatcher(result?.profileImg || ""));


                        changeScreen(3)
                        showToast(`${userType} register succesfully`, 'success')
                        setLoading(false)
                        // setGlobalLoader(false)
                    } catch (error) {
                        console.log(error)
                        setGlobalLoader(false)

                    }
                }).catch(async (err) => {
                    console.log(err?.response?.data?.status_code)

                    if (err?.response?.data?.status_code == 409) {  //------409 user exist------------
                        // loginWithGoogle(email)

                        const data = await generateToken({ payload: { locale: userType, sub: cognitoId } })
                        userData = await login({
                            // userRole: userType || "brand",
                            // cognitoId: cognitoId || "",
                            // loginType: "google"
                            jwtToken: data.token

                        }) // verify and create token from backend

                        setLocalStorage("token", JSON.stringify(userData.token));
                        setLocalStorage("userRole", "brand");
                        // dispatch(loginSuccess({
                        //     isLoggedIn: true,
                        //     username: email.toLowerCase(),
                        //     token: userData.token,
                        //     userId: userData.user_id,
                        //     brandId: userData.brand_id,
                        //     schoolId: userData.school_id,
                        //     creatorId: userData.creator_id,
                        //     userType: userType || "brand",
                        //     isOwner: userData?.isOwner,
                        //     role: userData?.role
                        // }));;

                        let loginDispatchData = {
                            isLoggedIn: true,
                            username: email.toLowerCase(),
                            token: userData.token,
                            userId: userData.user_id,
                            brandId: userData.brand_id,
                            schoolId: userData.school_id,
                            creatorId: userData.creator_id,
                            userType: userType || "brand",
                            isOwner: userData?.isOwner,
                            role: userData?.role
                        }
        
                        if (userType === "brand") {
                            loginDispatchData = {
                                ...loginDispatchData,
                                brandId: userData?.brandId
                            }
                        }
                        else if (userType === "school") {
                            loginDispatchData = {
                                ...loginDispatchData,
                                schoolId: userData?.schoolId
                            }
                        }
                        else if (userType === "creator") {
                            loginDispatchData = {
                                ...loginDispatchData,
                                creatorId: userData?.creatorId
                            }
                        }
                        dispatch(loginSuccess({...loginDispatchData}));;
                        
                        dispatch(profileDispatcher(userData?.profileImg || ""));

                        showToast("Login successful", 'success')
                        setRefresh(!refresh)
                        navigate("/");


                        console.log('Google account linked to existing Cognito user');
                        setGlobalLoader(false)
                        setLoading(false)
                    }
                    else {
                        const delResp = deleteUserByEmail(email)
                        console.log(" congnito account deleted succfully")
                        setGlobalLoader(false)
                        setLoading(false)
                        showToast('Registration Failed, try again.')
                    }

                    // setGlobalLoader(false)
                    // setLoading(false)

                });

            }
            else {
                showToast('something went wrong.Pls try again.')
                setGlobalLoader(false)
                setLoading(false)
            }
        } catch (e) {
            setGlobalLoader(false)
            setLoading(false)
        }

    };


    // Log out function to log the user out and reset the profile
    // const logOut = () => {
    //     googleLogout();
    //     setProfile(null);
    //     setUser(null);
    // };

    return (
        // <div className='w-full'>
        <button
            onClick={triggerLogin}
            className={`google-login-button ${buttonClass}`}
            style={buttonStyle}
        >
            {icon}
            {text}
        </button>
        // </div>
    );
}

export default GoogleAuth;

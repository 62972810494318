import React from 'react'

function GroupNftCard({title="",content,alt='',className=""}) {
    return (
        <div className={`group-nft text-[#333] bg-[#53389e1a]  text-[14px] p-[16px] min-h-[100px] rounded-[12px] ${className}`}>
            <div className="label">{title}</div>
            <div className="attribute-values">
                {content || alt}
            </div>
        </div>
    )
}

export default GroupNftCard
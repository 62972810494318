import React, { createContext, useContext, useState } from "react";

// Create a context for the auth screen
const AuthScreenContext = createContext();

export const SCREENS = {
  GET_STARTED: "GET_STARTED",
  BRAND_SIGNUP: "BRAND_SIGNUP",
  BRAND_LOGIN: "BRAND_LOGIN",
  CREATOR_SIGNUP: "CREATOR_SIGNUP",
  CREATOR_LOGIN: "CREATOR_LOGIN",
  // Add other screens here
};

export const AuthScreenProvider = ({ children }) => {
  const [currentScreen, setCurrentScreen] = useState(SCREENS.GET_STARTED);
  const [data, setData] = useState({})

  return (
    <AuthScreenContext.Provider value={{ currentScreen, setCurrentScreen, data, setData }}>
      {children}
    </AuthScreenContext.Provider>
  );
};

// Custom hook to use the AuthScreenContext
export const useAuthScreen = () => useContext(AuthScreenContext);

import React from "react";

const HomeIcon = () => {
  return (
    <svg className="bx bx-pie-chart-alt icon" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 16H14M9.0177 1.76406L2.23539 7.03919C1.78202 7.39181 1.55534 7.56812 1.39203 7.78892C1.24737 7.98451 1.1396 8.20484 1.07403 8.43911C1 8.70358 1 8.99076 1 9.56511V16.8C1 17.9202 1 18.4802 1.21799 18.908C1.40973 19.2843 1.71569 19.5903 2.09202 19.7821C2.51984 20 3.07989 20 4.2 20H15.8C16.9201 20 17.4802 20 17.908 19.7821C18.2843 19.5903 18.5903 19.2843 18.782 18.908C19 18.4802 19 17.9201 19 16.8V9.56511C19 8.99076 19 8.70358 18.926 8.43911C18.8604 8.20484 18.7526 7.98451 18.608 7.78892C18.4447 7.56812 18.218 7.39181 17.7646 7.03919L10.9823 1.76406C10.631 1.49081 10.4553 1.35418 10.2613 1.30166C10.0902 1.25532 9.9098 1.25532 9.73865 1.30166C9.54468 1.35418 9.36902 1.49081 9.0177 1.76406Z" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>



    // <svg
    //   className="bx bx-pie-chart-alt icon"
    //   width="26"
    //   height="26"
    //   viewBox="0 0 26 26"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M9.10063 16.4575V18.5492M13.2841 12.2741V18.5492M17.4675 8.09066V18.5492M8.89146 22.7327H17.6767C19.4339 22.7327 20.3125 22.7327 20.9836 22.3907C21.574 22.0899 22.054 21.6099 22.3548 21.0195C22.6968 20.3484 22.6968 19.4698 22.6968 17.7126V8.92735C22.6968 7.17014 22.6968 6.29154 22.3548 5.62037C22.054 5.03 21.574 4.55001 20.9836 4.2492C20.3125 3.90723 19.4339 3.90723 17.6767 3.90723H8.89146C7.13425 3.90723 6.25565 3.90723 5.58448 4.2492C4.99411 4.55001 4.51412 5.03 4.21331 5.62037C3.87134 6.29154 3.87134 7.17014 3.87134 8.92735V17.7126C3.87134 19.4698 3.87134 20.3484 4.21331 21.0195C4.51412 21.6099 4.99411 22.0899 5.58448 22.3907C6.25565 22.7327 7.13425 22.7327 8.89146 22.7327Z"
    //     stroke="#667085"
    //     strokeWidth="2.09172"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    // </svg>
  );
};

export default HomeIcon;

import React from 'react'

const DownArrowIcon = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.09717 7.71118L10.0972 12.7112L15.0972 7.71118" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default DownArrowIcon
import React, { useState } from "react";
import PurchasePopupContent from "./PurchasePopupContent";

const PurchasePopup = ({ className = "bg-violet rounded p-2 text-white", name = "OPEN", contentData={},onCloseAfterPurchase=()=>null }) => {

  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };


  return (
    <div className="relative">
      <button
        onClick={togglePopup}
        className={`px-4 py-2 button-primary-sw-100  ${className} `}
      >
        {name}
      </button>

      {isOpen && (
        <PurchasePopupContent isOpen={isOpen} togglePopup={togglePopup} onCloseAfterPurchase={onCloseAfterPurchase} contentData={contentData} />
      )}
    </div>
  );
};

export default PurchasePopup;
function NoResultScreen({
    heading='',
    img=null,
    text1='',
    text2='',
    btn=null
}) {
  return (
   <div className="w-full flex flex-col gap-20 px-4">
    <p className="text-4xl font-bold mt-8">{heading}</p>
    <div className="flex-row flex gap-20 px-12">
        {
            img &&
            <img src={img} alt="no-result" className="w-[260px] h-[320px]" />
        }
        <div className="flex flex-col gap-4 w-96">
            <p className="text-bold text-3xl font-semibold">{text1}</p>
            <p className="text-2xl text-slate-500">{text2}</p>
            <div className="w-fit">
                {/* <OutllinedPrimaryBtn label='Upload word' className='px-4' /> */}
                {btn}
            </div>
        </div>
    </div>
   </div>   
  )
}

export default NoResultScreen
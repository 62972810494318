import React from 'react'
import CheckCircle from '../icons/CheckCircle'

const NotifyMsg = ({className,messageType,message }) => {
    
    return (
        <div className={ `${className} w-full  flex justify-center`}>
            <div className={`flex  w-[100%] gap-4 text-[#067647] justify-start ps-5 p-3  items-center bg-[#DCFAE6]  rounded `}>
                <CheckCircle />
                <span className="font- text-[20px]">
                {message||""}
                </span>
            </div>
        </div>
    )
}

export default NotifyMsg
import React from 'react'

function CalenderIcon() {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.554 7.507H1.55396M12.3873 0.840332V4.17367M5.72062 0.840332V4.17367M5.55395 17.507H12.554C13.9541 17.507 14.6542 17.507 15.1889 17.2345C15.6593 16.9948 16.0418 16.6124 16.2815 16.142C16.554 15.6072 16.554 14.9071 16.554 13.507V6.507C16.554 5.10687 16.554 4.4068 16.2815 3.87202C16.0418 3.40162 15.6593 3.01917 15.1889 2.77948C14.6542 2.507 13.9541 2.507 12.554 2.507H5.55396C4.15382 2.507 3.45376 2.507 2.91898 2.77948C2.44857 3.01917 2.06612 3.40162 1.82644 3.87202C1.55396 4.4068 1.55396 5.10687 1.55396 6.507V13.507C1.55396 14.9071 1.55396 15.6072 1.82644 16.142C2.06612 16.6124 2.44857 16.9948 2.91898 17.2345C3.45376 17.507 4.15382 17.507 5.55395 17.507Z" stroke="#98A2B3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default CalenderIcon
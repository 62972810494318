

import { useEffect, useRef } from 'react';

const SemiCircularProgressBar = ({ percentage }) => {
    const barRef = useRef(null);
    const spanRef = useRef(null);

    useEffect(() => {
        const barElement = barRef.current;
        const spanElement = spanRef.current;
        let start = 0;
        const duration = 1000;
        const end = percentage;
        const startTime = performance.now();

        const animateProgress = (currentTime) => {
            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / duration, 1); // Ensuring progress doesn't exceed 1
            const currentPercentage = start + progress * (end - start);

            // Rotate the bar
            barElement.style.transform = `rotate(${45 + currentPercentage * 1.8}deg)`;

            // Update the percentage text
            spanElement.textContent = Math.floor(currentPercentage);

            if (progress < 1) {
                requestAnimationFrame(animateProgress);
            }
        };

        requestAnimationFrame(animateProgress);
    }, [percentage]);

    return (
        <div className="relative m-1 text-center float-left">
            <div className="relative overflow-hidden w-[90px] h-[40px] mb-[14px]">
                <div className="absolute top-0 left-0 w-[90px] h-[90px] rounded-full box-border border-[10px] rounded-pill border-gray-300 border-b-violet border-r-violet" ref={barRef}></div>
                <div className=' absolute bottom-[-5px]   w-full'>
                    <div className='flex justify-center '>
                        <div className='min-w-[20px] font-[600] text-xl ' ref={spanRef}>{percentage}</div>
                        <div className='w-[10px]'>%</div>

                    </div>
                </div>
            </div>
        </div>
    );
};


export default SemiCircularProgressBar;

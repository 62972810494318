export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const PROFILE = "PROFILE";
export const PROFILE_DATA = "PROFILE_DATA";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";

export const GALLERY_DATA_SUCCESS = "GALLERY_DATA_SUCCESS";
export const GALLERY_DATA_FILTER_PURCHASED = "GALLERY_DATA_FILTER_PURCHASED";
export const GALLERY_DATA_PREVENT_FETCHING_DATA = "GALLERY_DATA_PREVENT_FETCHING_DATA";
export const GALLERY_DATA_IMAGE_SCROLL_POSITION = "GALLERY_DATA_IMAGE_SCROLL_POSITION";
export const GALLERY_DATA_PAGE = "GALLERY_DATA_PAGE";

export const loginSuccess = ({isLoggedIn, username, token,userId,brandId,schoolId,userRole,isNew,isOwner=0,role,userType,isPolicyAccepted}) => ({
  type: LOGIN_SUCCESS,
  payload: { username, token ,userId,brandId,userRole,isNew,isOwner,role, isLoggedIn ,schoolId,userType,isPolicyAccepted},
});

export const signupSuccess = ({isLoggedIn, username, token,userId,brandId,userRole,isNew,isOwner=0,role}) => ({
  type: SIGNUP_SUCCESS,
  payload: { isLoggedIn },
});

export const logout = () => ({
  type: LOGOUT,
  
});

export const profile = (profileUrl) => ({
  type: PROFILE,
  payload: { url:profileUrl},
  
});

export const profileData = (profileData) => ({
  type: PROFILE_DATA,
  payload: {...profileData},
  
});

export const galleryDataSuccess = (imageData) => ({
  type: GALLERY_DATA_SUCCESS,
  payload: {imageData: imageData}
})

export const setGalleryDataPage = (page) => ({
  type: GALLERY_DATA_PAGE,
  payload: {page:page}
})

export const galleryDataFilterPurchased = (purchasedData) => ({
  type: GALLERY_DATA_FILTER_PURCHASED,
  payload: {purchased: purchasedData}
})

export const galleryDataPreventFetching = (bool = true) => ({
  type: GALLERY_DATA_PREVENT_FETCHING_DATA,
  payload: {value: bool}
})

export const setGalleryScrollPostion = (position) => ({
  type: GALLERY_DATA_IMAGE_SCROLL_POSITION,
  payload: {scrollPosition: position}
})
import BackNavigator from "../../../components/BackNavigator/BackNavigator";
import BorderLessButton from "../../../components/buttons/BorderLessButton";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import LoadingWrapper from "../../../components/loadingWrapper/LoadingWrapper";
import AddRounded from "../../../icons/AddRounded";
import AlertRounded from "../../../icons/AlertRounded";
import DeleteIcon from "../../../icons/Delete";
import EyeOff from "../../../icons/EyeOff";
import data from '../../../data/images.json';
import CustomImage from "../../../components/CustomImage/CustomImage";
import './ViewCollection.css'
import { makeGalleryArray } from "../../../utils/galleryUtils";
import CreatorGalleryCardActions from "../../../components/CreatorGalleryCardActions/CreatorGalleryCardActions";
import { useEffect, useRef, useState } from "react";
import { deleteCollection, getCollectionById, getUserProfile, publishCollection } from "../../../services/creatorService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DeletePopup from "../../../components/popups/DeletePopup";
import NotifyMsg from "../../../components/NotifyMsg";
import useToast from "../../../hooks/useToast";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import CollectionGallery from "../../../components/CollectionGallery";
import MessageInfoCard from "../../../components/MessageInfoCard";
import { revalidateCreatorCollection } from "../../../redux/reducerFiles/creatorCollectionReducer";

const ViewCollection = () => {
    const showToast = useToast()

    const navigate = useNavigate()

    const [columns, setColumns] = useState([])
    const [collectionData, setCollectionData] = useState({})
    const [isLicenced, setIsLicenced] = useState(false)
    // const [selectedImages, setSelectedImages] = useState([])
    const [disableBtn, setDisableBtn] = useState(false)
    const { collectionId } = useParams();
    const location = useLocation();
    // const loc = useLocation();
    const redirectStateRef = useRef(location.state || null)
    const pathname = useLocation().pathname
    const [isLoading, setIsLoading] = useState(true); // intial loading -----------
    const [pbLoading, setPbLoading] = useState(false); //public/unpublic loading
    const [deleteLoading, setDeleteLoading] = useState(false); //
    const [profileData, setProfileData] = useState(null)
    const { userId, profileData: profileDataState } = useSelector(state => state.user)
    const dispatch = useDispatch()
    // const debounceSearchText = useDebounce(searchText, 500);

    const [showSuccessMsg, setShowSuccessMsg] = useState(false)

    const [openDeleteModel, setOpenDeleteModel] = useState(false)

    const [refresh, setRefresh] = useState(false)

    const fetchAllCollection = async () => {
        try {
            setIsLoading(true)
            const collectionRes = await getCollectionById(collectionId || "")
            setCollectionData(collectionRes?.data || {})
            setIsLicenced(collectionRes?.data?.isLicenced || false)
            setColumns(makeGalleryArray(collectionRes?.data?.images || []))
            setIsLoading(false);
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        const fetchUser = async () => {
            setProfileData(profileDataState)
        }
        fetchAllCollection()
        fetchUser()
    }, [refresh])



    // handle publish/unpublish-------------------
    const handlePublish = async colData => {
        // if(isLicenced){
        //     handleDeleteModel(true)
        //     return
        // }
        // if(!colData.isPublished){

        if (!profileData || !profileData?.alias?.length || !profileData?.location?.length || !profileData?.bio?.length) {
            toast.info("Please complete your profile first.")
            navigate('/creator/profile/edit/publish', { state: { colData, profileData, pathname: pathname, collectionName: collectionData?.name } });
            return;
        }
        // }

        setPbLoading(true)
        try {
            setDisableBtn(true)
            const data = {
                creator_collection_id: colData.creator_collection_id,
                type: colData.isPublished ? 'unpublish' : 'publish'
            }
            const res = await publishCollection(data)
            location.state = null
            setShowSuccessMsg(colData.isPublished ? false : true)
            setTimeout(() => setShowSuccessMsg(false), 15000)
            await fetchAllCollection()
            dispatch(revalidateCreatorCollection({ creatorId: userId }));

        } catch (err) {
            console.log(err)
        } finally {
            setDisableBtn(false)
            setPbLoading(false)

        }
    }



    useEffect(() => {
        if (!profileData) return
        if (redirectStateRef.current && redirectStateRef.current?.isRedirectFromEditCreatorProfileForCollectionPublish) {
            handlePublish(redirectStateRef.current.colData)
            window.history.replaceState({}, '')
            redirectStateRef.current = null
        }
    }, [redirectStateRef.current, profileData])

    //   handle add new images in collection --------------
    const handleAddOneMore = () => {
        navigate('/creator/upload?type=edit', {
            state: {
                collectionData: {
                    name: collectionData?.name,
                    inspiration: collectionData?.creator_inspiration,
                    id: collectionData?.creator_collection_id || collectionId,
                    type: 'edit'
                }
            }
        })
    }

    // handle delete function of collection -----------
    const handleDeleteCollection = async () => {
        setDeleteLoading(true)
        const resp = await deleteCollection(collectionId)
        showToast("Collection deleted successfully", 'success')
        dispatch(revalidateCreatorCollection({ creatorId: userId }));
        navigate('/', { replace: true })
        setDeleteLoading(false)
    }
    // handle delete model opening/closing 
    const handleDeleteModel = (state = false, type = 'collection') => {
        setOpenDeleteModel(state)
    }

    return (
        <div className="pt-[31px] pb-[50px]">
            <BackNavigator text="Back to collections" />
            <DeletePopup isOpen={openDeleteModel}
                title='Delete collection?'
                subText='Your collection and all images in it will be permanently removed from our database and/or store.'
                onClose={() => handleDeleteModel(false)}
                onDelete={handleDeleteCollection}
                loading={deleteLoading}

                isDelete={isLicenced ? false : true}        // this use when collection is not deletable
                type={'collection'}    // this use when collection is not deletable

            />
            <div className="px-[30px]  custom-max-width " >
                <LoadingWrapper loading={isLoading}>
                    <div className="flex justify-between items-center pt-[28px]">
                        <h1 className="font-semibold text-[30px] text-primary-black">{collectionData?.name || "-"}</h1>
                        <div className="flex gap-[10px] items-center">
                            <BorderLessButton Icon={<DeleteIcon className="w-[15px]" />} onClick={() => handleDeleteModel(true)} text="Delete" />
                            <BorderLessButton Icon={<AddRounded className="w-[15px]" />} onClick={handleAddOneMore} text="Add more pieces" />
                            <PrimaryButton
                                disabled={disableBtn}
                                onClick={() => handlePublish(collectionData)}
                                text={pbLoading
                                    ? (collectionData?.isPublished ? "Unpublishing..." : "Publishing...")
                                    : (collectionData?.isPublished ? "Hide from store" : "Publish to store")
                                }
                            />

                        </div>
                    </div>
                    <div className="mt-[50px]" >

                        {showSuccessMsg && <NotifyMsg messageType={'success'} message={"Success! Your collection now available for sale in our store."} className="mb-5 " innerClass={'justify-start ps-5'} />}

                        {!collectionData?.isPublished &&
                            <MessageInfoCard heading={'Publish this collection to start selling your art'} subHeading={'Publishing a collection makes it visible in our store. You can un-publish it at any time. '} />
                        }
                        {/* <div className="unpublishalert flex gap-[12px] p-[12px]  items-start border border-[silver] rounded-xl alertClass">
                            <AlertRounded />
                            <div className="flex flex-col pt-2" >
                                <p className="text-sm font-semibold text-[14px] leading-5 mb-1">Publish this collection to start selling your art</p>
                                <p className="text-sm text-[14px] leading-5 mb-1 ">Publishing a collection makes it visible in our store. You can un-publish it at any time. </p>
                            </div>
                        </div> */}
                    </div>
                    <CollectionGallery columns={columns} collectionData={collectionData} refresh={refresh} setRefresh={setRefresh} />


                </LoadingWrapper>
            </div>
        </div>
    )
}

export default ViewCollection;
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import InputText from '../../../components/FormComponets/InputText'
import TextArea from '../../../components/FormComponets/TextArea'

import { useDispatch, useSelector } from 'react-redux'
import { editUserProfile, getUserProfile } from '../../../services/creatorService'

import { toast } from 'react-toastify'
import BackNavigator from '../../../components/BackNavigator/BackNavigator'
import { profileData } from '../../../redux/actions'


const EditCreatorProfileForCollectionPublish = () => {
    const navigate = useNavigate()
    const [alias, setAlias] = useState('')
    const [bio, setBio] = useState('')
    const [location, setLocation] = useState('')
    const { state } = useLocation();
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const { userId } = useSelector(state => state.user);

    const handleSaveAndPublish = async () => {

        if (!location?.length || !bio?.length || !alias?.length) {
            toast.error("All fields are required to publish your collection")
            return
        }

        setLoading(true)
        let payload = {
            location: location || "",
            bio: bio || "",
            alias: alias || "",
            creator_id: userId || ""
        }

        await editUserProfile(payload)
        const resp = await getUserProfile({creator_id:userId})
        dispatch(profileData(resp?.data || {}));
        setLoading(false)
        toast.success("Your profile is updated. ")
        navigate(state.pathname, { state: { colData: state.colData, isRedirectFromEditCreatorProfileForCollectionPublish: true } });

    }



    useEffect(() => {
        (async function () {
            try {
                const userData = state?.profileData
                setAlias(userData?.alias)
                setLocation(userData?.location)
                setBio(userData?.bio)
            } catch (error) {
                console.log(error)
            } finally {
                // setLoading(false)
            }
        })();
    }, [state])


    return (
        <div className='custom-max-width p-4'>
            <BackNavigator text="Back to collections" />
            <div className=" px-[30px]">
                <div className='pt-2 pb-4'>
                    <h2 className='font-[600]'>
                        Publish “{state?.collectionName}” to be available for sale
                    </h2>
                    <p className=''>We need a few more details to start selling your work</p>
                </div>

                <div className="flex flex-col gap-4">
                    <div className="flex flex-row gap-4">
                        <div className='flex-1 py-4 flex-col flex border-text-seconday-gray '>
                            <h6 className='font-[600] col-3 text-primary-black text-nowrap'> City and State/Province*</h6>
                            <div className='flex gap-5 w-full'>
                                <div className='w-[71%]'>
                                    <InputText className=' w-full p-2' value={location} placeholder='Enter city' onChange={(e) => setLocation(e.target.value)} />
                                </div>

                            </div>
                        </div>
                        <div className='flex-1 py-4 flex-col flex border-text-seconday-gray '>
                            <h6 className='font-[600] col-3 text-primary-black'>Alias*</h6>
                            <div className='flex gap-5 w-full'>
                                <div className='w-[71%]'>
                                    <InputText className='  w-full p-2' value={alias} placeholder='Enter alias' onChange={(e) => setAlias(e.target.value)} />
                                </div>

                            </div>
                        </div>


                    </div>
                    <div className='w-full  py-4 flex-col flex border-text-seconday-gray '>
                        <div className='col-3'>
                            <h6 className='font-[600]  text-primary-black'>Tell us a bit about yourself*</h6>
                        </div>
                        <div className='flex gap-5 w-full'>
                            <div className='w-full'>
                                <TextArea className=' w-full min-h-[20vh]' value={bio || ""} placeholder='A short bio. This will set you apart and help connect with the right buyer.' wordLimit={200} onChange={(e) => setBio(e.target.value)} />
                            </div>

                        </div>
                    </div>
                    <div className="flex gap-3 pt-2 flex-row ml-auto">
                        <button className="button-secondary-sw text-primary-black" style={{ width: '80px' }} onClick={() => navigate(-1)}> Cancel </button>
                        <button className="button-primary-sw " style={{ width: loading?"110px":'80px' }} onClick={handleSaveAndPublish} disabled={loading}>{loading ? "Publishing..." : "Publish"}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditCreatorProfileForCollectionPublish


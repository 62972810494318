import React from 'react'

function LinkedinIcon() {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.8397 0.5H2.383C1.91312 0.5 1.46249 0.686657 1.13024 1.01891C0.797986 1.35116 0.611328 1.80179 0.611328 2.27167V22.7283C0.611328 23.1982 0.797986 23.6488 1.13024 23.9811C1.46249 24.3133 1.91312 24.5 2.383 24.5H22.8397C23.3095 24.5 23.7602 24.3133 24.0924 23.9811C24.4247 23.6488 24.6113 23.1982 24.6113 22.7283V2.27167C24.6113 1.80179 24.4247 1.35116 24.0924 1.01891C23.7602 0.686657 23.3095 0.5 22.8397 0.5ZM7.76466 20.945H4.15633V9.48333H7.76466V20.945ZM5.958 7.895C5.54869 7.8927 5.14924 7.7692 4.81006 7.54009C4.47088 7.31098 4.20717 6.98653 4.05221 6.60769C3.89724 6.22885 3.85798 5.81259 3.93936 5.41145C4.02074 5.01032 4.21913 4.64228 4.50948 4.35378C4.79984 4.06529 5.16914 3.86927 5.57079 3.79046C5.97245 3.71165 6.38844 3.75359 6.76628 3.91099C7.14412 4.06838 7.46687 4.33417 7.69379 4.67481C7.92072 5.01546 8.04165 5.41569 8.04133 5.825C8.04519 6.09903 7.99384 6.37104 7.89034 6.6248C7.78684 6.87857 7.63331 7.1089 7.4389 7.30207C7.24449 7.49523 7.01318 7.64728 6.75875 7.74915C6.50433 7.85102 6.232 7.90062 5.958 7.895ZM21.0647 20.955H17.458V14.6933C17.458 12.8467 16.673 12.2767 15.6597 12.2767C14.5897 12.2767 13.5397 13.0833 13.5397 14.74V20.955H9.93133V9.49167H13.4013V11.08H13.448C13.7963 10.375 15.0163 9.17 16.878 9.17C18.8913 9.17 21.0663 10.365 21.0663 13.865L21.0647 20.955Z" fill="#0A66C2" />
        </svg>
    )
}

export default LinkedinIcon
import React from 'react';

function EyeOn({ className = "", color = "#98A2B3" }) {
  return (
    <svg className={className} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* Eye outline */}
      <path
        d="M9.64238 3.12528C5.38821 3.12528 2.59665 6.87932 1.65881 8.3643C1.5453 8.54404 1.48855 8.6339 1.45679 8.77251C1.43293 8.87661 1.43294 9.04084 1.45681 9.14493C1.48859 9.28354 1.5456 9.374 1.65962 9.55492C2.22074 10.4748 3.4439 12.1728 5.24563 13.395C6.79182 14.4735 8.78515 14.792 9.64238 14.792C13.8965 14.792 16.6881 11.0379 17.6259 9.55293C17.739 9.37321 17.7961 9.28335 17.8279 9.14475C17.8518 9.04065 17.8518 8.87645 17.828 8.77235C17.7962 8.63376 17.7395 8.54401 17.6259 8.3643C16.6881 6.87932 13.8965 3.12528 9.64238 3.12528Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {/* Pupil (small dot in center of eye) */}
      <circle cx="9.642" cy="8.958" r="3" fill={color} />
    </svg>
  );
}

export default EyeOn;

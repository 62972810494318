import React, { useState, useEffect } from "react";
import "./CommissionGrid.css";

import FilterIcon from "../icons/Filter";
import CalendarIcon from '../icons/Calendar.js'
import { useSelector } from "react-redux";
import DatePicker from "./dateRangePicker.js/dateRangePicker";
import moment from "moment";

import { Link, useLocation } from "react-router-dom";
import { getAllCommission } from "../services/brandServices.js";
import LoadingWrapper from "./loadingWrapper/LoadingWrapper.jsx";
import { StyledTableBodyCell, StyledTableHeadCell } from "./StyledTableCell/StyledTableCell.jsx";
import CommissionTabView from "./CommissionTabView/CommissionTabView.jsx";
import NotifyMsg from "./NotifyMsg.js";
import CustomDateRangePicker from "./dateRangePicker.js/dateRangePicker/CustomDateRangePicker.jsx";
import Badge from "./badge/index.jsx";

const CommissionGrid = () => {
  const [commissions, setCommissions] = useState([]);
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const [commissionToDisplay, setCommissionToDisplay] = useState(null)
  const [itemsPerPage] = useState(5);
  const [openCalendar, setOpenCalender] = useState(false);
  const [dateRange, setDateRange] = useState({ startDate: new Date(), endDate: new Date() });
  const [isLoading, setIsLoading] = useState(false);
  const [showNotify, setShowNotify] = useState(false)

  const location = useLocation();
  const message = location.state?.message
  const messageType = location.state?.messageType

  useEffect(() => {

    let id
    if (message) {
      id = setTimeout(() => { setShowNotify(false) }, 15000)
      setShowNotify(true)
    }
    return () => {
      clearTimeout(id)
    }

  }, [message])

  const getDateFormated = (date = new Date()) => {//date object
    const monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return `${monthList[date.getMonth()]} ${date.getDate()},${date.getFullYear()}`

  }

  const { username, userId, brandId } = useSelector((state) => state.user);

  const fetchCommissions = async (fromDateFilter = false) => {
    try {
      // const response = await axios.get(
      //   `https://mc54wwmd2jqfhvis2huj46wl240ilczp.lambda-url.us-east-1.on.aws/?createdby=${username}`
      // );
      setIsLoading(true)
      const response = await getAllCommission({ user_id: brandId, page: currentPage, limit: itemsPerPage, dateRanges: fromDateFilter ? dateRange : null });
      if (response?.data?.length) {
        setCommissions(response.data);
        setTotalCount(response.totalCount);
        setCommissionToDisplay(response?.data?.length > 0 ? response?.data[0] : null)
      }
      else {
        setCommissions([{}, {}, {}]);
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error("Error fetching commissions", error);
    }
  };

  useEffect(() => {
    fetchCommissions();
  }, [username, currentPage]);
  // useEffect(() => {
  //  !openCalendar && fetchCommissions();
  // }, [openCalendar]);

  const handleCalendarClose = () => {
    fetchCommissions(true);//from date filter
  }


  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = commissions.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(totalCount / itemsPerPage);

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers.map((number) => (
      <li
        key={number}
        id={number}
        onClick={handleClick}
        className={currentPage === number ? "active" : null}
      >
        {number}
      </li>
    ));
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleViewClick = (commission) => {
    setCommissionToDisplay(commission)
    window.scrollTo(0, 0);
    // setActiveTab("basicdetails");
  };

  const makeTableData = (field) => {
    if (field) return field
    else return '-'
  }

  const statusTypeMapping = {
    in_progress: 'info',
    submitted: 'success',
  };

  // const commissionToDisplay =
  //   selectedCommission ||
  //   (commissions.length > 0 ? commissions[commissions.length - 1] : null);
  return (
    <div className="grid-container">
      <div className="col-lg-12 mb-5">
        <div className="row align-items-center">
          <div className="col-lg-12 d-flex justify-content-between align-items-center">
            <div className="heading-text">Commissions</div>

            <div className="button-placement1">
              <Link to="/commissions/create" style={{ textDecoration: "none" }}>
                <button className="button-primary-sw">New Commission</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {showNotify && <NotifyMsg messageType={messageType} message={message} className="mb-5" />}

      <LoadingWrapper loading={isLoading}>
        {commissionToDisplay && (
          <CommissionTabView commissionToDisplay={commissionToDisplay} />
        )}
        {
          !commissions?.length ?
            <p className="noActiveCommissionText">You don’t have any active commissions</p>
            : null
        }
        <div className="table-responsives mt-3">
          <DatePicker onClose={handleCalendarClose} open={openCalendar} setOPen={setOpenCalender} setDateRange={setDateRange} selectionRange={dateRange} />
          <div className="w-full flex justify-between my-2 items-end">
            <div className="flex flex-col gap-3 h-[85px]">
              <p className="text-[#101828] text-[18px] my-auto font-semibold">Commission history</p>
              {
                !commissions?.length ?
                  <p className="font-normal text-[16px] text-[#475467] my-0">Nothing to show here yet</p>
                  : null
              }
            </div>
            <div className="flex gap-2">
              {/* <div onClick={() => setOpenCalender(true)} className="flex px-2 py-1 rounded-md text-[14px] font-normal gap-2 items-center border border-silver cursor-pointer">
                <CalendarIcon />
                <span>{getDateFormated(dateRange.startDate)}{" - "}{getDateFormated(dateRange.endDate)}</span>
              </div> */}
              {/* <CustomDateRangePicker
                onClose={handleCalendarClose} 
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                setDateRange={setDateRange}
                buttonClassName='h-full py-2 '
                /> */}
              {/* <div className="flex px-2 py-1 rounded-md text-[14px] font-[600] gap-2 items-center border border-silver cursor-pointer">
                <FilterIcon />
                <spn>Filter</spn>
              </div> */}
            </div>
          </div>
          <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
           <table style={{ width: '100%', borderCollapse: 'collapse', border: '2px solid #0c0d0f'}}>
            <thead>
              <tr className="font-style-grid">
                {/* <th>ID</th> */}
                <StyledTableHeadCell>Name</StyledTableHeadCell>
                {/* <StyledTableHeadCell>Target Location</StyledTableHeadCell> */}
                <StyledTableHeadCell>Due date</StyledTableHeadCell>
                <StyledTableHeadCell>Type</StyledTableHeadCell>
                {/* <StyledTableHeadCell>Content Usage</StyledTableHeadCell>
                <StyledTableHeadCell>Created On</StyledTableHeadCell> */}
                <StyledTableHeadCell>Status</StyledTableHeadCell>
                <StyledTableHeadCell>Details</StyledTableHeadCell>
              </tr>
            </thead>
            <tbody>
              {commissions.map((commission, index) => (
                <tr
                  key={commission.id}
                  className={
                    index % 2 === 0
                      ? "odd-row font-style-grid"
                      : "even-row font-style-grid"
                  }
                >
                  {/* <StyledTableBodyCell>{commission.id}</td> */}
                  <StyledTableBodyCell ><strong>{makeTableData(commission.name)}</strong></StyledTableBodyCell>
                  {/* <StyledTableBodyCell>{makeTableData(commission.target_location)}</StyledTableBodyCell> */}
                  {/* <StyledTableBodyCell>{new Date(commission.timeline).toLocaleDateString()}</StyledTableBodyCell> */}
                  <StyledTableBodyCell>
                    {
                       commission.end_date ?
                        moment(commission.end_date).format('MMMM DD, YYYY')
                        : '-'
                    }
                  </StyledTableBodyCell>
                  <StyledTableBodyCell style={{ whiteSpace: "pre-wrap" }}>
                    {makeTableData(commission.media_type)?.replace(/,/g, ",\n")}
                  </StyledTableBodyCell>
                  {/* <StyledTableBodyCell>{makeTableData(commission.content_usage)}</StyledTableBodyCell> */}
                  {/* <StyledTableBodyCell>
                    {
                      commission.created_at ?
                        moment(commission.created_at).format('MMMM DD, YYYY')
                        : '-'
                    }
                  </StyledTableBodyCell> */}
                  <StyledTableBodyCell className="uppercase"> 
                    <Badge
                             text={makeTableData((commission.status === "submitted") ? "Completed" : "in progress")}
                             type={statusTypeMapping[commission.status]}
                             width = "125px"
                   />
                   </StyledTableBodyCell>
                  <StyledTableBodyCell>
                    <button className="bg-none text-violet font-[600]" onClick={() => handleViewClick(commission)}>
                      {Object.values(commission)?.length ? 'VIEW' : '-'}
                    </button>
                  </StyledTableBodyCell>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
        <div className="pagination-container mt-4">
          <button
            className="previous-button"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="arrow-left">
                <path
                  id="Icon"
                  d="M16.0436 10.8603H4.37695M4.37695 10.8603L10.2103 16.6936M4.37695 10.8603L10.2103 5.02698"
                  stroke="#344054"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
            Previous
          </button>
          <ul className="pagination">{renderPageNumbers()}</ul>
          <button
            className="next-button"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="arrow-right">
                <path
                  id="Icon"
                  d="M4.37646 10.8603H16.0431M16.0431 10.8603L10.2098 5.02698M16.0431 10.8603L10.2098 16.6936"
                  stroke="#344054"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
          </button>
        </div> 
      </LoadingWrapper>
    </div>
  );
};

export default CommissionGrid;

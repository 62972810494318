


import React, { useEffect, useState } from 'react'
import { StyledTableBodyCell, StyledTableHeadCell } from '../../components/StyledTableCell/StyledTableCell'
import { getAllBrands } from '../../services/brandServices'
import Pagination from '../../components/Pagenation/Pagenation'
import moment from 'moment'
import LoadingWrapper from '../../components/loadingWrapper/LoadingWrapper'
import { deleteGalleryImageByCreatorId, getAllCreators } from '../../services/creatorService'


const DELETE_TYPE = {
    DELETE_ALL_IMAGE:"DELETE_ALL_IMAGE",
    DELETE_WITH_GALLERY_ID:"DELETE_WITH_GALLERY_ID"
}

const CreatorList = () => {

    const [creatorData, setCreatorData] = useState([])
    const [loading, setLoading] = useState(false)
    const [deleteButtonState, setDeleteButtonState] = useState({
        loading:false,
        id:""
    })
    const [limit, setLimit] = useState(10)

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    const handlePageChange = (pageNo) => {
        setCurrentPage(pageNo)
    }



    const handleGetCreatorData = async (currentPage) => {
        setLoading(true)
        try {
            const payload = {
                limit,
                offset: limit * (currentPage - 1)
            }
            const resp = await getAllCreators(payload)
            setTotalPages(Math.ceil(resp.totalCount / limit))
            setCreatorData(resp?.creatorDetails || [])
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        handleGetCreatorData(currentPage)
    }, [currentPage])

    const makeTableData = (field) => {
        if (field) return field
        else return '-'
    }
    // const wait4Seconds = () => new Promise(resolve => setTimeout(resolve, 4000));

    const handleDelete = async (payload) => {
        setDeleteButtonState({
            loading:true,
            id:payload?.id
        })
        const data = {
            creatorId: payload.id,
            deleteType: DELETE_TYPE.DELETE_ALL_IMAGE,
            galleryIds: []
        }
        try {
            const res = await deleteGalleryImageByCreatorId(data)
        } catch(error){
            console.log(error)
        } finally {
            setDeleteButtonState({
                loading:false,
                id:""
            })
        }
    }

    const fullName=(firstName="",lastName="")=>{
        let fullName=''
        if(firstName){
            fullName+=`${firstName} `
        }
        if(lastName){
            fullName+=`${lastName} `
        }

        return fullName
    }

    return (
        <div>
            <p className="text-[36px] font-semibold text-center py-3">Creator List</p>

            <LoadingWrapper loading={loading}>

                <div className="w-full overflow-auto">
                    <table>
                        <thead>
                            <tr className="font-style-grid">
                                {/* <StyledTableHeadCell><span className="font-bold">Sr. No</span></StyledTableHeadCell> */}
                                <StyledTableHeadCell><span className="font-bold">Id</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">Email</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">Referral Code</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">Creator Code</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">User Type</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">Name</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">Alias Name</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">Email Verified</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">Published Pieces</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">Total Pieces</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">Created At</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">Update At</span></StyledTableHeadCell>
                                {/* <StyledTableHeadCell><span className="font-bold">Actions</span></StyledTableHeadCell> */}
                            </tr>
                        </thead>
                        <tbody>
                            {creatorData.map((creator, index) => (
                                <tr
                                    key={creator.id}
                                    className={
                                        index % 2 === 0
                                            ? "even-row font-style-grid"
                                            : "odd-row font-style-grid"
                                    }
                                >
                                    <StyledTableBodyCell>{makeTableData(creator.cognito_id)}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData(creator?.cognitoDetails?.email)}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData(creator?.refferal)}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData(creator?.creator_code)}</StyledTableBodyCell>

                                    <StyledTableBodyCell>{makeTableData(creator?.cognitoDetails?.locale)}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData( fullName(creator?.first_name,creator?.last_name) || creator?.cognitoDetails?.name||"")}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData( creator?.alias)}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData(creator.cognitoDetails?.email_verified)}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData((creator.publishedCount) ?  creator.publishedCount : "0")}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData((creator.publishedCount + creator.unPublishedCount) ?  creator.publishedCount + creator.unPublishedCount : "0")}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData(moment(creator.created_at).format('MMMM DD, YYYY'))}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData(moment(creator.updated_at).format('MMMM DD, YYYY'))}</StyledTableBodyCell>
                                    {/* <StyledTableBodyCell>
                                        <div className="flex flex-row">
                                            <button
                                                onClick={()=>handleDelete(brand)}
                                                className="font-semibold bg-red-600 text-white rounded-lg py-2 px-3"
                                            disabled={deleteButtonState.loading ? true : false}
                                            >
                                                {deleteButtonState.loading && deleteButtonState.id === brand?.id ? "Deleting..." : 'Delete all images'}
                                            </button>
                                        </div>
                                    </StyledTableBodyCell> */}

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </LoadingWrapper>


            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={(pageNo) => handlePageChange(pageNo)} />
        </div>
    )
}

export default CreatorList

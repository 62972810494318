import React, { useContext, useEffect, useRef, useState } from 'react'

import LeftArrowIcon from '../../../icons/LeftArrowIcon'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import InputText from '../../../components/FormComponets/InputText'
import TextArea from '../../../components/FormComponets/TextArea'
import ManageThumbnail from '../../Creator/my-work/upload-creator-content/ManageThumnail'
import DeleteIcon from '../../../icons/Delete'
import CloudUploadIcon from '../../../icons/CloudUpload'
import Checkbox from '../../../components/Checkbox/Checkbox'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import { formatFileSize, getIcon, INITIAL_FORM_DATA, renderPreview, validate } from './helper'
import { useDispatch, useSelector } from 'react-redux'
import { ulid } from 'ulid'
import { createSchoolCollection, getUploadUrl, uploadFileTos3 } from '../../../services/schoolServices'
import { addImageInCollection } from '../../../services/creatorService'
import useToast from '../../../hooks/useToast'
import { SidebarContext } from '../../../App'
import useModal from '../../../components/popups/modal/useModal'
import Disclaimer from '../../../components/popups/Disclaimer'
import { loginSuccess } from '../../../redux/actions'


function SchoolCollectionContentForm() {
    const { setGlobalLoader } = useContext(SidebarContext)
    const {open,setOpen,toggle} = useModal()
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const { collectionData } = location?.state||{collectionData:""}
    const showToast=useToast()


    const formType = searchParams.get('type') || 'add';
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [checkboxError, setCheckboxError] = useState(false)
    const [formData, setFormData] = useState(INITIAL_FORM_DATA)
    const [formState, setFormState] = useState({
        isValid: false,
        isLoading: false
    });
    const objectUrlsForPreview = useRef({})
    const [thumbnails, setThumbnails] = useState({})
    const { schoolId, userId, isNew } = useSelector((state) => state.user);
    const dispatch = useDispatch()   

    function handleFormElementChange(e) {
        const { name, type, value, checked } = e.target;
    
        // Update the value based on the input change
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: {
                ...prevFormData[name], // Copy the previous object to ensure immutability
                value: name === INITIAL_FORM_DATA.isChecked.name ? checked : value, // Update the value based on input type
            },
        }));
    
    }
    
    const clearForm = () => {
        // Reset the form data to the initial state
        setFormData(INITIAL_FORM_DATA);
    };

    useEffect(() => {
        // Validate form state whenever formData changes
        let valid = validate({formData})

        setFormState((prev)=>({...prev,isValid:valid})); // Update the validation state
    }, [formData]); // Run this effect whenever formData changes
    
    useEffect(() => {
        if (collectionData && collectionData?.type === 'edit' && formType === 'edit') {
    
            const {
                content_title, 
                creator_bio, 
                creator_name, 
                inspiration, 
                name, 
                social_media_links
            } = collectionData;
    
            // Create a new object based on the existing form data
            setFormData((prevFormData) => ({
                ...prevFormData,
                collectionName: { ...prevFormData.collectionName, value: name },
                creatorName: { ...prevFormData.creatorName, value: creator_name },
                contentTitle: { ...prevFormData.contentTitle, value: content_title },
                inspiration: { ...prevFormData.inspiration, value: inspiration },
                bio: { ...prevFormData.bio, value: creator_bio },
                links: { ...prevFormData.links, value: social_media_links },
            }));
        } else {
            clearForm();
        }
    }, [formType, collectionData]);
    
    

    function handleFileChange(event) {
        const newFiles = Array.from(event.target.files);
        event.target.value = '';
        const allowedExtensions = new Set(['png', 'svg', 'gif', 'jpeg', 'jpg', 'mp4'])
        let files = newFiles.filter((file) => {
            let extension = file?.name?.split('.').pop().toLowerCase();
            return allowedExtensions.has(extension);
        })
        if (newFiles.length !== files.length) {
            alert("Only png, svg, gif, jpeg and mp4 files are allowed");
        }
        if (!files.length) return

        files.forEach(file => {
            objectUrlsForPreview.current[file?.name] = URL.createObjectURL(file);
        })
        const totalCount = formData.files.value.length + files.length;
        if (totalCount > 5) {
            alert("You can only upload a maximum of 5 files");
        } else {
            setFormData(prev => ({ ...prev, files: { ...prev.files, value: [...prev.files.value, ...files] } }))
        }
    }

    const removeFile = (index) => {
        const file = formData.files.value[index]
        // setSelectedFiles((files) => files.filter((file, i) => i !== index));
        const updatedFiles = formData.files.value.filter((file, i) => i !== index)
        setFormData(prev => ({ ...prev, files: { ...prev.files, value: updatedFiles } }))

        // removing object preview url 
        delete objectUrlsForPreview.current[file?.name]

        const temp = { ...thumbnails }
        delete temp[file?.name]
        setThumbnails({ ...temp })
    };



    const getSignedUrlAndUploadMedia = async (mediaItems, collectionId) => {
        let response = [];
        if (!mediaItems.length) return response;

        let keyNames = [];
        mediaItems.forEach(({ videoFile, thumbnailFile }) => {
            const _ulid = ulid();
            const videoKey = `gallery/school/${userId}/${collectionId}/video/${_ulid}`;
            const thumbnailKey = `gallery/school/${userId}/${collectionId}/video/${_ulid}_thumbnail`;

            response.push({
                videoName: videoFile?.name,
                videoKey,
                thumbnailName: thumbnailFile?.name,
                thumbnailKey
            });

            keyNames.push(videoKey, thumbnailKey);
        });

        const { urls } = await getUploadUrl(keyNames);
        if (urls.length !== keyNames.length) {
            throw new Error('The number of upload URLs does not match the number of keys.');
        }

        const uploadPromises = mediaItems.map((item, index) => {
            const videoUrl = urls[index * 2];
            const thumbnailUrl = urls[index * 2 + 1];

            const promises = [];
            if (videoUrl) promises.push(uploadFileTos3(videoUrl, item.videoFile));
            if (thumbnailUrl) promises.push(uploadFileTos3(thumbnailUrl, item.thumbnailFile));

            return Promise.all(promises);
        });

        await Promise.all(uploadPromises);
        return response;
    };

    const getSignedUrlAndUploadImage = async (files, collectionId) => {
        let response = [];
        if (!files.length) return response;

        let keyNames = files.map((file) => {
            const key = `gallery/school/${userId}/${collectionId}/${ulid()}`;
            response.push({ name: file?.name, key });
            return key;
        });

        const { urls } = await getUploadUrl(keyNames);
        await Promise.all(
            urls.map((url, index) => uploadFileTos3(url, files[index]))
        );

        return response;
    };

    const validateForUpload = () => {
        if (!formData.creatorName.value) {
            alert("Creator Name is required");
            return false;
        }
        if (!formData.inspiration.value) {
            alert("Inspiration is required");
            return false;
        }
        if (formData.files.value?.length > 5) {
            alert("You can only upload a maximum of 5 files");
            return false;
        }
        if (!formData.files.value?.length) {
            alert("You have to upload at least 1 file");
            return false;
        }
        return true;
    };

    const handleCreateCollection = async (e) => {
        e.preventDefault();
        
        if (!formData.isChecked.value) {
            setCheckboxError(true);
            setGlobalLoader(false)
            return;
        }
        
        if (!validateForUpload()) return;
        
        try {
            setLoading(true);
            setGlobalLoader(true)
            setFormState({
                ...formState,
                isLoading:true
            })
            const images = [];
            const videos = [];

            formData.files.value.forEach((item) => {
                if (item.type.includes('video')) {
                    videos.push({
                        videoFile: item,
                        thumbnailFile: thumbnails[item.name]
                    });
                } else {
                    images.push(item);
                }
            });

            if (videos.length !== Object.keys(thumbnails).length) {
                alert('Thumbnails are required for each video');
                return;
            }
            if (collectionData?.type === "edit") {

                let files, videoFiles;
                if (images.length) {
                    files = await getSignedUrlAndUploadImage(
                        images,
                        collectionData?.school_collection_id
                    );
                }
                if (videos.length) {
                    videoFiles = await getSignedUrlAndUploadMedia(
                        videos,
                        collectionData?.school_collection_id
                    )
                }
                const requestBody = {
                    id: collectionData?.school_collection_id,
                    files,
                    videoFiles,
                    name: formData.collectionName.value,
                    creator_name: formData.creatorName.value,
                    content_title: formData.contentTitle.value,
                    inspiration: formData.inspiration.value,
                    creator_bio: formData.bio.value,
                    school_id: schoolId,
                    school_user_id: userId,
                    social_media_links: formData.links.value,
                    isNew
                };



                // Submit form data (use your service here)
                const res = await addImageInCollection({ ...requestBody });
                // if(res?.data?.isNewFieldUpdated){
                //     dispatch(loginSuccess({isNew:false}))
                // }
                clearForm()
                setGlobalLoader(false)

                navigate(-1)
                showToast("New images added successfully", 'success')


            }
            else {

                let collectionId = ulid();

                const files = await getSignedUrlAndUploadImage(images, collectionId);
                const videoFiles = await getSignedUrlAndUploadMedia(videos, collectionId);

                const requestBody = {
                    id: collectionId,
                    files,
                    videoFiles,
                    name: formData.collectionName.value,
                    creator_name: formData.creatorName.value,
                    content_title: formData.contentTitle.value,
                    inspiration: formData.inspiration.value,
                    creator_bio: formData.bio.value,
                    school_id: schoolId,
                    school_user_id: userId,
                    social_media_links: formData.links.value,
                    isNew
                };



                // Submit form data (use your service here)
                const res = await createSchoolCollection(requestBody);
                if(res?.data?.isNewFieldUpdated){
                    dispatch(loginSuccess({isNew:false}))
                }
                clearForm()
                setGlobalLoader(false)


                // alert("Collection created successfully!");
                navigate("/school/gallery");
            }

        } catch (error) {
            alert("Error creating collection");
            console.error(error);
            setGlobalLoader(false)
            setFormState({
                ...formState,
                isLoading:false
            })

        } finally {
            setLoading(false);
            setOpen(false)
            setGlobalLoader(false)
            setFormState({
                ...formState,
                isLoading:false
            })
        }
    };


 

    return (
        <>
            <div className='max-w-[1400px] flex flex-col gap-6 mt-12 px-12'>
                <div className="flex flex-col gap-4">
                    <button className="flex flex-row gap-2 items-center w-fit" onClick={() => navigate(-1)}>
                        <LeftArrowIcon />
                        <span className="text-violet text-lg font-semibold">Back to Project page</span>
                    </button>
                    <p className="mt-4 text-4xl font-semibold">
                        Add Content
                    </p>

                    <div className="flex flex-col w-full">
                        <InputText name={formData.collectionName.name} value={formData.collectionName.value} onChange={handleFormElementChange} className=" w-full mb-4 col-7" label='Name your collection' placeholder='Collection is a group of one or more images made by the same artist/creator' isImportant={true} />
                        <div className="grid md:grid-cols-2 w-full gap-x-8 gap-y-6">

                            <InputText name={formData.creatorName.name} value={formData.creatorName.value} onChange={handleFormElementChange} className=" w-full" label='Creator name' placeholder='First and last name' isImportant={true} />

                            <InputText name={formData.contentTitle.name} value={formData.contentTitle.value} onChange={handleFormElementChange} className=" w-full" label='Content title' placeholder='What is your artwork name?' isImportant={true} />

                            <TextArea name={formData.inspiration.name} value={formData.inspiration.value} onChange={handleFormElementChange} className='p-3' wordLimit={200} rows="15" label='Inspiration' isImportant={true} placeholder='What has inspired you to create this piece?' />

                            <TextArea name={formData.bio.name} value={formData.bio.value} onChange={handleFormElementChange} className='p-3' wordLimit={200} rows="15" label='Tell us a bit about yourself' isImportant={true} placeholder='Creator bio written in first person' />

                            <div className="col-span-2">
                                <TextArea name={formData.links.name} value={formData.links.value} onChange={handleFormElementChange} className='p-3' rows="8" placeholder='Add your social media links or names here, if desired.' />
                            </div>

                            <div className="col-span-2">
                                <div className='w-full flex flex-col gap-2'>
                                    <div className=''>
                                        Upload your content <span className='text-red-500'>*</span>
                                    </div>
                                    <label className="upload-control" htmlFor="upload-input">
                                        <input
                                            type="file"
                                            multiple
                                            id="upload-input"
                                            accept="image/*, video/*"
                                            onChange={handleFileChange}
                                        />
                                        <div className="uploadicon p-2 mb-3 rounded-md">
                                            {/* <UploadIcon /> */}
                                            <CloudUploadIcon />
                                        </div>
                                        <div className="upload-options">
                                            <span className="click">Click to upload
                                                {/* <span className='text-gray-500'>or drag and drop</span> */}
                                            </span>
                                        </div>
                                        <div className=" mt-0 p-0 text-gray-500">
                                            Image or Video (MP4 only)
                                            {/* Zip or folder */}
                                        </div>
                                    </label>
                                    {formData.files.value.length > 0 && (
                                        <ul
                                            style={{ listStyleType: "none", margin: 0, padding: 0 }}
                                            className="flex flex-col gap-y-3"
                                        >
                                            {formData.files.value.map((file, index) => (
                                                <li className="selected-files my-1 flex flex-col gap-1" key={file?.name}>
                                                    <div className="selected-file-grouping">
                                                        <div className="icons-files">{getIcon(file?.name || "")}</div>
                                                        <div className="file-details">
                                                            <div className="filename">{file?.name}</div>
                                                            <div className="filetype">
                                                                ({formatFileSize(file?.size || "")})
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="cursor-pointer"
                                                            onClick={() => removeFile(index)}
                                                        >
                                                            <DeleteIcon />
                                                        </div>
                                                    </div>
                                                    {
                                                        renderPreview({ file, previewObject: objectUrlsForPreview.current[file?.name] })
                                                    }

                                                    <ManageThumbnail fileName={file?.name} isVideo={file.type?.includes('video')} thumbnails={thumbnails} setThumbnails={setThumbnails} videoIndex={index} />

                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>

                            <div className='w-full col-span-2'>
                                <Checkbox
                                    name={formData.isChecked.name}
                                    checkboxError={checkboxError}
                                    isSubmitting={loading}
                                    checked={formData.isChecked.value}
                                    onChange={handleFormElementChange}
                                    className={`${!formData.isChecked.value ? "text-black" : "text-black"}`}
                                    label={'I certify that the submitted creative works are original, created by me, and have not been plagiarized from any other sources'}
                                />

                            </div>


                        </div>
                        <div className="w-full flex justify-end mt-4">
                            <PrimaryButton onClick={()=>setOpen(true)}
                                disabled={!formState.isValid}
                                text='Add to project gallery' />
                        </div>
                    </div>
                </div>
            </div>
            {
            open &&
            <Disclaimer
                open={open}
                setOpen={setOpen}
                toggle={toggle}
                onClick={handleCreateCollection} 
                disable={formState.isLoading}
            />
        }
        </>
    )
}

export default SchoolCollectionContentForm


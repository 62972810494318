import React from 'react'

const CameraPlus = () => {
    return (
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M41.3729 22.2078V27.9585C41.3729 32.1142 41.3729 34.1921 40.5641 35.7794C39.8527 37.1756 38.7176 38.3108 37.3213 39.0222C35.7341 39.8309 33.6562 39.8309 29.5004 39.8309H16.1439C11.9882 39.8309 9.91031 39.8309 8.32303 39.0222C6.92681 38.3108 5.79165 37.1756 5.08025 35.7794C4.27148 34.1921 4.27148 32.1142 4.27148 27.9585V18.3121C4.27148 14.1564 4.27148 12.0785 5.08025 10.4912C5.79165 9.09499 6.92681 7.95983 8.32303 7.24843C9.91031 6.43966 11.9882 6.43966 16.1439 6.43966H23.7497M35.8077 15.715V4.58459M30.2425 10.1498H41.3729M30.2425 23.1353C30.2425 27.2334 26.9203 30.5556 22.8222 30.5556C18.7241 30.5556 15.4019 27.2334 15.4019 23.1353C15.4019 19.0372 18.7241 15.715 22.8222 15.715C26.9203 15.715 30.2425 19.0372 30.2425 23.1353Z" stroke="#7F56D9" stroke-width="1.59006" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default CameraPlus
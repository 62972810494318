import React from 'react'

const MyContentIcon = () => {
    return (
        <svg className="bx bx-pie-chart-alt icon" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.84521 12.3612L12.1724 17.5248C12.3129 17.595 12.3832 17.6301 12.4569 17.644C12.5221 17.6562 12.5891 17.6562 12.6544 17.644C12.728 17.6301 12.7983 17.595 12.9388 17.5248L23.266 12.3612M1.84521 17.7164L12.1724 22.88C12.3129 22.9502 12.3832 22.9853 12.4569 22.9992C12.5221 23.0114 12.5891 23.0114 12.6544 22.9992C12.728 22.9853 12.7983 22.9502 12.9388 22.88L23.266 17.7164M1.84521 7.00597L12.1724 1.84237C12.3129 1.77212 12.3832 1.737 12.4569 1.72317C12.5221 1.71093 12.5891 1.71093 12.6544 1.72317C12.728 1.737 12.7983 1.77212 12.9388 1.84237L23.266 7.00597L12.9388 12.1696C12.7983 12.2398 12.728 12.275 12.6544 12.2888C12.5891 12.301 12.5221 12.301 12.4569 12.2888C12.3832 12.275 12.3129 12.2398 12.1724 12.1696L1.84521 7.00597Z" stroke="#667085" stroke-width="2.14208" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default MyContentIcon
import React from 'react'

function TwitterIcon() {
    return (
        <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.23111 20.1497C17.2878 20.1497 22.2414 12.6463 22.2414 6.13945C22.2414 5.92633 22.2414 5.71417 22.227 5.50297C23.1906 4.80592 24.0225 3.94285 24.6836 2.95417C23.785 3.35257 22.8316 3.61372 21.8554 3.72889C22.8834 3.11357 23.6528 2.14567 24.0202 1.00537C23.0537 1.57897 21.9962 1.98319 20.8935 2.20057C20.1511 1.41114 19.1692 0.888407 18.0998 0.71325C17.0304 0.538093 15.933 0.72028 14.9776 1.23162C14.0221 1.74296 13.2618 2.55494 12.8143 3.54191C12.3669 4.52889 12.2571 5.63583 12.5022 6.69145C10.5445 6.59324 8.62935 6.08448 6.88105 5.19817C5.13275 4.31186 3.59036 3.06783 2.35399 1.54681C1.72432 2.6308 1.53147 3.91403 1.81469 5.13523C2.09792 6.35642 2.83594 7.42376 3.87847 8.11993C3.09483 8.09696 2.32824 7.88556 1.64359 7.50361V7.56601C1.6439 8.70286 2.03744 9.8046 2.75746 10.6844C3.47748 11.5641 4.47965 12.1678 5.59399 12.3929C4.86907 12.5906 4.10847 12.6195 3.37063 12.4774C3.68541 13.4557 4.29802 14.3112 5.12283 14.9243C5.94764 15.5374 6.94342 15.8774 7.97095 15.8969C6.95003 16.6994 5.78091 17.2927 4.53047 17.6429C3.28003 17.9931 1.97281 18.0933 0.683594 17.9378C2.93543 19.3829 5.55552 20.1494 8.23111 20.1458" fill="#1DA1F2" />
        </svg>

    )
}

export default TwitterIcon
import React from 'react'
import alert_icon from '../../../../icons/alert-Icon.svg'
import { editUserProfile } from '../../../../services/creatorService'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { loginSuccess } from '../../../../redux/actions'

function ProfileCompleteAlert({creator_id=null}) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { username, token ,userId,userRole,isNew } = useSelector(state => state.user);
    const handleProfileUpdateForIsNewField = async ({creator_id,isNew=0,rediredToProfileEdit=false}) => {
        try {
            if(!creator_id) return  
            // return
            const data = {creator_id,isNew}
            dispatch(loginSuccess({isNew:!isNew}));;
            // dispatch(loginSuccess({username, token, userId, userRole: "brand"}));;


            const res = await editUserProfile(data)

            if(rediredToProfileEdit){
                navigate('/creator/profile/edit')
            }
        } catch(err){
            console.log(err)
        }
    }
    return (
        <div className="border rounded-lg border-gray-200 p-4 pb-2 w-full">
            <div className="flex flex-row gap-2 items-center">
                <div className="flex flex-row gap-2 items-start w-full">
                    <img className='w-10 h-10' src={alert_icon} alt='alert-icon' />
                    <div className="flex flex-col">
                        <p className="text-slate-900 font-bold text-lg">Share your story and increase your visibility!</p>
                        <p className="text-slate-700 text-lg -mt-4">A complete profile helps your work get noticed and sold.</p>
                    </div>
                </div>
                <div className="flex flex-row gap-4">
                    <button onClick={()=>handleProfileUpdateForIsNewField({creator_id})} className='text-nowrap border-[1px] border-gray-400 p-2 rounded text-slate-800 font-medium'>Dismiss</button>
                    <button onClick={()=>handleProfileUpdateForIsNewField({creator_id,rediredToProfileEdit:true})} className='text-nowrap border-[1px] border-violet p-2 rounded text-violet font-medium'>Update Profile</button>
                </div>
            </div>
        </div>
    )
}

export default ProfileCompleteAlert
import React, { useState, useEffect, useRef } from "react";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { StyledTableBodyCell, StyledTableHeadCell } from "../../components/StyledTableCell/StyledTableCell";
import { createSchoolCommissionXref, fetchAllCommissionsForAdmin, getAllSchools, getCommissionSchoolXref } from "../../services/adminServices";
import LoadingWrapper from "../../components/loadingWrapper/LoadingWrapper";
import { constants } from "../../constants/constants";
import Popup from "../../components/popups/PopupLayout/PopupLayout";
import Spinner from "react-bootstrap/esm/Spinner";
import SelectDropDown from "../../components/dropDown/SelectDropDown";
import useToast from "../../hooks/useToast";
import Pagination from "../../components/Pagenation/Pagenation";
import Search from "../../components/searchGroup/Search";
import useDebounce from "../../utils/debounce";

const AdminPage = () => {
    const [commissions, setCommissions] = useState([]);
    const [schools, setSchools] = useState([]);
    const [commissionSchoolXrefs, setCommissionSchoolXrefs] = useState([]);
    const [loading, setLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [alert, setAlert] = useState({ isOpen: false, message: '' })
    const [submitData, setSubmitData] = useState({ school_id: '', commission_id: '' })
    const showToast = useToast()
    const [searchText, setSearchText] = useState('')
    const debounceSearchText = useDebounce(searchText, 500);
    const [schoolDropdownHasMore, setSchoolDropdownHasMore] = useState(true);
    const [schoolDropdownLoading, setSchoolDropdownLoading] = useState(false);
    const [schoolDropdownPage, setSchoolDropdownPage] = useState(1);
    const [schoolSearchText, setSchoolSearchText] = useState('')
    const schoolDebounceSearchText = useDebounce(schoolSearchText, 500);
    const [commissionDropdownHasMore, setCommissionDropdownHasMore] = useState(true);
    const [commissionDropdownLoading, setCommissionDropdownLoading] = useState(false);
    const [commissionDropdownPage, setCommissionDropdownPage] = useState(1);
    const [commissionSearchText, setCommissionSearchText] = useState('')
    const commissionDebounceSearchText = useDebounce(commissionSearchText, 500);
    const [relationTableLoading, setRelationTableLoading] = useState(false)
    const LIMIT = 20; // Number of items per page

    const fetchSchools = async (payload) => {
        if (!schoolDropdownHasMore || schoolDropdownLoading) return;

        setSchoolDropdownLoading(true);

        try {
            const response = await getAllSchools(payload); // Use the passed page
            let data = response?.data?.map((item) => {
                return {
                    label: item?.school_name || item?.organization_name,
                    value: item?.id
                }
            })


            if (!data?.length) {
                data = []
            }

            if(payload?.page === 1) {
                setSchools(prev => [...data]);
            } else {
                setSchools(prev => [...prev, ...data]);
            }


            // Update hasMore based on response data length
            if (data.length < LIMIT) {
                setSchoolDropdownHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching schools:", error);
        } finally {
            setSchoolDropdownLoading(false);
        }
    };

    const handleSchoolDropdownScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.target;
        if (scrollHeight - scrollTop <= clientHeight + 10 && schoolDropdownHasMore && !schoolDropdownLoading) {
            setSchoolDropdownPage(prev => {
                const nextPage = prev + 1;
                const payload = {
                    limit: LIMIT,
                    page: nextPage,
                    searchText: schoolDebounceSearchText
                }
                fetchSchools(payload); // Fetch next page
                return nextPage; // Return the next page number
            });
        }
    };


    const fetchAllCommissions = async (payload) => {
        if (!commissionDropdownHasMore || commissionDropdownLoading) return;

        setCommissionDropdownLoading(true);

        try {
            const response = await fetchAllCommissionsForAdmin(payload); // Use the passed page
            const data = response?.data?.map((item) => {
                return {
                    label: item?.name,
                    value: item?.id
                }
            })

            if (!data?.length) {
                data = []
            }

            if(payload.page === 1) {
                setCommissions(prev => [...data]);
            } else {
                setCommissions(prev => [...prev, ...data]);
            }

            // Update hasMore based on response data length
            if (data.length < LIMIT) {
                setCommissionDropdownHasMore(false);
            }
        } catch (error) {
            console.error("Error fetching commissions:", error);
        } finally {
            setCommissionDropdownLoading(false);
        }
    };


    const handleCommissionDropdownScroll = (event) => {
        const { scrollTop, clientHeight, scrollHeight } = event.target;
        if (scrollHeight - scrollTop <= clientHeight + 10 && commissionDropdownHasMore && !commissionDropdownLoading) {
            setCommissionDropdownPage(prev => {
                const nextPage = prev + 1;
                const payload = {
                    limit: LIMIT,
                    page: nextPage,
                    searchText: commissionDebounceSearchText
                }
                fetchAllCommissions(payload); // Fetch next page
                return nextPage; // Return the next page number
            });
        }
    };



    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(5);

    const handlePageChange = (pageNo) => {
        setCurrentPage(pageNo);
        const payload = {
            limit,
            page: pageNo, // Ensure you're passing the correct page
        };
        if (debounceSearchText) {
            payload['searchText'] = debounceSearchText
        }
        getCommissionSchoolXrefsData(payload);
    };



    const getCommissionSchoolXrefsData = async (payload) => {
        try {
            setSubmitLoading(true);
            setRelationTableLoading(true);
            const data = await getCommissionSchoolXref({ limit: payload.limit, page: payload.page, searchText: payload?.searchText || '' });
            setTotalPages(data.totalPages);
            setCommissionSchoolXrefs(data.data);
            setSubmitLoading(false);
            setRelationTableLoading(false);
        } catch (error) {
            console.error("Error fetching commission school Xrefs:", error);
            setSubmitLoading(false);
            setRelationTableLoading(false);
        }
    };

    useEffect(() => {
        const payload = {
            limit: LIMIT,
            page: 1,
            searchText: ''
        }
        fetchSchools(payload); // Fetch schools on component mount
        fetchAllCommissions(payload);

        const initialPayload = {
            limit,
            page: currentPage,
        };
        if (debounceSearchText) {
            initialPayload['searchText'] = debounceSearchText
        }
        getCommissionSchoolXrefsData(initialPayload); // Fetch data when the component mounts
    }, []);

    useEffect(() => {
        const initialPayload = {
            limit,
            page: currentPage,

        };

        initialPayload['searchText'] = debounceSearchText
        getCommissionSchoolXrefsData(initialPayload); // Fetch data when the component mounts
    }, [debounceSearchText])

    useEffect(() => {
        const initialPayload = {
            limit: LIMIT,
            page: commissionDropdownPage,
        };
        // if (commissionDebounceSearchText && commissionDebounceSearchText?.length) {

            initialPayload['searchText'] = commissionDebounceSearchText
            setCommissions([])
            fetchAllCommissions(initialPayload); // Fetch data when the component mounts
        // }
    }, [commissionDebounceSearchText])

    useEffect(() => {
        const initialPayload = {
            limit: LIMIT,
            page: schoolDropdownPage,
        };
        // if (schoolDebounceSearchText && schoolDebounceSearchText?.length) {
            initialPayload['searchText'] = schoolDebounceSearchText
            setSchools([])
            fetchSchools(initialPayload); // Fetch data when the component mounts
        // }
    }, [schoolDebounceSearchText])


    const handleDataChange = (event) => {
        setSubmitData({
            ...submitData,
            [event.target.name]: event.target.value
        })
    }
    const handleCreateRelation = async () => {
        try {
            if (!submitData.commission_id || !submitData.school_id) {
                setAlert({ isOpen: true, message: constants.ADMIN_COPY_CREATE_COMMISSION_SCHOOL_XREF_VALIDATION })
                return;
            }
            setSubmitLoading(true)
            const { label } = schools.find(item => item.value === submitData.school_id)
            await createSchoolCommissionXref({ ...submitData, orgName: label, userType: 'school' })
            setKey((prevKey) => prevKey + 1); // Change key to remount components

            showToast("Commission successfully assigned to the school!", 'success')
            setSubmitData({ school_id: '', commission_id: '' })
            setSubmitLoading(false)
            const initialPayload = {
                limit,
                page: currentPage,
            };
            if (debounceSearchText) {
                initialPayload['searchText'] = debounceSearchText
            }
            getCommissionSchoolXrefsData(initialPayload);
            const payload = {
                limit:LIMIT,
                page:1,
                searchText:commissionDebounceSearchText
            }
            fetchAllCommissions(payload);
        } catch (error) {
            setAlert({ isOpen: true, message: error.response.data.message || constants.ADMIN_COPY_SOMETHING_WENT_WRONG })
            setSubmitLoading(false)
        }
    }

    const makeTableData = (field) => {
        if (field) return field
        else return '-'
    }

    const handleAlert = () => setAlert({ isOpen: false, message: '' })
    const [key, setKey] = useState(0);

    return (
        <LoadingWrapper loading={loading}>
            <div className="p-5 w-full" >
                <p className="text-[36px] font-semibold text-center">Assign Commission</p>
                <div className="mt-5 flex flex-col gap-5 items-center" >
                    <div className="flex lg:justify-between md:flex-row flex-col gap-4 md:items-end items-start w-full">
                        <div className="flex items-center md:flex-row flex-col gap-4 md:items-end">
                            <SelectDropDown
                                key={`school-${key}`}
                                options={schools}
                                dropDownLabel={"Select School"}
                                onChange={handleDataChange}
                                name="school_id"
                                value={submitData.school_id}
                                onMenuScrollToBottom={handleSchoolDropdownScroll}
                                onInputChange={(val) => {
                                    setSchoolDropdownPage(1)
                                    setSchoolDropdownHasMore(true)
                                    setSchoolSearchText(val)
                                }}
                                inputValue={schoolSearchText}
                                isSearchable={true}
                                isLoading={schoolDropdownLoading}
                            />
                            <SelectDropDown
                                key={`commission-${key}`}
                                options={commissions}
                                dropDownLabel={"Select Commissions"}
                                onChange={handleDataChange}
                                name="commission_id"
                                value={submitData.commission_id}
                                onMenuScrollToBottom={handleCommissionDropdownScroll}
                                onInputChange={(val) => {
                                    setCommissionDropdownPage(1)
                                    setCommissionDropdownHasMore(true)
                                    setCommissionSearchText(val)
                                }}
                                inputValue={commissionSearchText}
                                isSearchable={true}
                                isLoading={commissionDropdownLoading}
                            />
                        </div>
                        <PrimaryButton
                            className={`w-[200px] ${submitLoading ? 'w-[156.8px]' : 'auto'} text-nowrap`}
                            onClick={handleCreateRelation}
                            text={submitLoading ? <Spinner animation="border" size="sm" /> : "Assign Commission"}
                        />
                    </div>
                    <LoadingWrapper loading={relationTableLoading}>
                        <div className="w-full">
                            <div className="w-full justify-end flex mb-4">
                                <Search 
                                className='w-[391px]' 
                                value={searchText} 
                                onChange={(value) => {
                                        setCurrentPage(1)
                                        setSearchText(value)
                                        if(value?.length) {
                                            setCommissionSchoolXrefs([])
                                        }
                                } } 
                                crossIcon={true} />
                            </div>
                            <table>
                                <thead>
                                    <tr className="font-style-grid">
                                        <StyledTableHeadCell><span className="font-bold">School Name</span></StyledTableHeadCell>
                                        <StyledTableHeadCell><span className="font-bold">Commission Name</span></StyledTableHeadCell>
                                        <StyledTableHeadCell><span className="font-bold">Status</span></StyledTableHeadCell>
                                        <StyledTableHeadCell><span className="font-bold">Accepted</span></StyledTableHeadCell>
                                        <StyledTableHeadCell><span className="font-bold">Created At</span></StyledTableHeadCell>

                                    </tr>
                                </thead>
                                <tbody>
                                    {commissionSchoolXrefs.map((xrefData, index) => (
                                        <tr
                                            key={xrefData.id}
                                            className={
                                                index % 2 === 0
                                                    ? "even-row font-style-grid"
                                                    : "odd-row font-style-grid"
                                            }
                                        >
                                            <StyledTableBodyCell>{makeTableData(xrefData.school_name)}</StyledTableBodyCell>
                                            <StyledTableBodyCell>{makeTableData(xrefData.name)}</StyledTableBodyCell>
                                            <StyledTableBodyCell>{makeTableData((xrefData?.status || "").replace('_', " ").toUpperCase())}</StyledTableBodyCell>
                                            <StyledTableBodyCell>{makeTableData(xrefData.is_accepted ? "True" : "False")}</StyledTableBodyCell>
                                            <StyledTableBodyCell>{makeTableData(xrefData.created_at)}</StyledTableBodyCell>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {
                                commissionSchoolXrefs.length ?
                                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={(pageNo) => handlePageChange(pageNo)} />
                                    :
                                    <p className="text-slate-600 text-2xl text-center">No Data</p>
                            }
                        </div>
                    </LoadingWrapper>
                </div>
            </div>
            <Popup isOpen={alert.isOpen} togglePopup={handleAlert}>
                {alert.message}
            </Popup>
        </LoadingWrapper>
    )

};

export default AdminPage;

import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import InputText from '../../../../components/FormComponets/InputText'
import TextArea from '../../../../components/FormComponets/TextArea'
import UploadIcon from "../../../../icons/upload";
import RadioButton from '../../../../components/RadioButton/RadioButton';
import SecondaryButton from '../../../../components/buttons/SecondaryButton';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import CloudUploadIcon from '../../../../icons/CloudUpload';
import { useDispatch, useSelector } from 'react-redux';
import { ulid } from "ulid";
import { getUploadUrl, uploadFileTos3 } from '../../../../services/brandServices';
import DeleteIcon from '../../../../icons/Delete';
import CommonFile from '../../../../icons/CommonFile';
import { addImageInCollection, creatorCollection } from '../../../../services/creatorService';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import useToast from '../../../../hooks/useToast';
import AddRounded from '../../../../icons/AddRounded'
import ManageThumnail from './ManageThumnail';
import { revalidateCreatorCollection } from '../../../../redux/reducerFiles/creatorCollectionReducer';




const UploadCreatorContent = () => {
    const navigate = useNavigate();
    const showToast = useToast()

    const [collectionName, setCollectionName] = useState('');
    const [inspiration, setInspiration] = useState('');
    const [selectedFiles, setSelectedFiles] = useState('');
    const [thumbnails, setThumbnails] = useState({})
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [checkboxError, setCheckboxError] = useState(false)
    const dispatch = useDispatch()
    const { username, userId } = useSelector((state) => state.user);


    const location = useLocation();
    const collectionData = location.state?.collectionData


    useEffect(() => {
        if (collectionData?.name) {
            setCollectionName(collectionData?.name)
        }
        if (collectionData?.inspiration) {
            setInspiration(collectionData.inspiration)
        }
    }, [collectionData])



    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        event.target.value = '';
        const allowedExtensions = new Set(['png', 'svg', 'gif', 'jpeg','jpg','mp4'])
        const files = newFiles.filter((file) => {
            let extension = file.name?.split('.').pop().toLowerCase();
            return allowedExtensions.has(extension);
        })
        if (newFiles.length !== files.length) {
            alert("Only png, svg, gif, jpeg and mp4 files are allowed");
        }
        if (!files.length) return

        const totalCount = selectedFiles.length + files.length;
        if (totalCount > 5) {
            alert("You can only upload a maximum of 5 files");
        } else {
            setSelectedFiles((prev) => [...prev, ...files]);
        }
    };
    
    const removeFile = (index) => {
        const file = selectedFiles[index]
        setSelectedFiles((files) => files.filter((file, i) => i !== index));
        const temp = {...thumbnails}
        delete temp[file.name]
        setThumbnails({...temp})
    };

    const getSignedUrlAndUploadMedia = async (mediaItems, collectionId) => {
        let response = [];
    
        if (!mediaItems.length) return response;
    
        // Create an array of keys and gather responses
        let keyNames = [];
    
        mediaItems.forEach(({ videoFile, thumbnailFile }) => {
            const _ulid = ulid();
            const videoKey = `gallery/${userId}/${collectionId}/video/${_ulid}`;
            const thumbnailKey = `gallery/${userId}/${collectionId}/video/${_ulid}_thumbnail`;
    
            response.push({
                videoName: videoFile.name,
                videoKey,
                thumbnailName: thumbnailFile.name,
                thumbnailKey
            });
    
            keyNames.push(videoKey, thumbnailKey); // Push both keys
        });
    
        // Get upload URLs for both videos and thumbnails
        const { urls } = await getUploadUrl(keyNames);
    
        // Ensure we received the expected number of URLs
        if (urls.length !== keyNames.length) {
            throw new Error('The number of upload URLs does not match the number of keys.');
        }
    
        // Create upload promises for both video files and their corresponding thumbnails
        const uploadPromises = mediaItems.map((item, index) => {
            const videoUrl = urls[index * 2];
            const thumbnailUrl = urls[index * 2 + 1];
    
            const promises = [];
    
            // Check if videoUrl is available
            if (videoUrl) {
                promises.push(uploadFileTos3(videoUrl, item.videoFile));
            }
    
            // Check if thumbnailUrl is available
            if (thumbnailUrl) {
                promises.push(uploadFileTos3(thumbnailUrl, item.thumbnailFile));
            }
    
            return Promise.all(promises);
        });
    
        // Await all upload promises
        await Promise.all(uploadPromises);
    
        return response;
    };
    

    const getSignedUrlAndUploadImage = async (files, collectionId) => {
        let response = [];

        if (!files.length) return response;
        
        if (!files.length) return response;
        let keyNames = files.map((file) => {
            const key = `gallery/${userId}/${collectionId}/${ulid()}`;
            response.push({ name: file.name, key });
            return key;
        });

        const { urls } = await getUploadUrl(keyNames);

        await Promise.all(
            urls.map((url, index) => uploadFileTos3(url, files[index]))
        );
        return response;
    };

    const validate = () => {
        if (!collectionName) {
            alert("Collection Name is required");
            return false;
        }
        if (!inspiration) {
            alert("Your Inspiration is required");
            return false;
        }
        if (selectedFiles?.length > 5) {
            alert("You can only upload a maximum of 5 files");
            return false;
        }
        if (selectedFiles?.length === 0) {
            alert("You have to upload minimum 1 file");
            return false;
        }


        return true;
    };



    const handleCreateCollection = async (e) => {
        try {
            
            e.preventDefault();

            if(!isChecked) {
                setCheckboxError(true)
                return 
            }

            if (!validate()) return;
            setLoading(true);
            const images = []
            const videos = []
            selectedFiles.forEach((item,index)=>{
                if(item.type.includes('video')){
                    videos.push({
                        videoFile: item,
                        thumbnailFile: thumbnails[item.name]
                    })
                } else {
                    images.push(item)
                }
            })

            if(videos.length !== Object.keys(thumbnails).length) {
                return showToast('Thumbnails are required for each video')
              }
            
            if (collectionData?.type === "edit") {
                let files,videoFiles;
                if(images.length){
                    files = await getSignedUrlAndUploadImage(
                        images,
                        collectionData?.id
                    );
                }
                if(videos.length){
                    videoFiles = await getSignedUrlAndUploadMedia(
                        videos,
                        collectionData?.id
                    )
                }
                const requestBody = {
                    id: collectionData?.id,
                    files,
                    videoFiles,
                    name: collectionName,
                    inspiration,
                    creator_id: userId,
                }

                await addImageInCollection({ ...requestBody });
                dispatch(revalidateCreatorCollection({ creatorId: userId }));

                navigate(-1)
                showToast("New images added successfully", 'success')

                // navigate("/creator", { state: { messageType: "success", message: "Success! We have received your collection details and our team will get back to you soon.", hideBreadCrumb: true } });
            }
            else {

                let collectionId = ulid();
                const files = await getSignedUrlAndUploadImage(
                    images,
                    collectionId
                );
                const videoFiles = await getSignedUrlAndUploadMedia(
                    videos,
                    collectionId
                );
                const requestBody = {
                    id: collectionId,
                    files,
                    videoFiles,
                    name: collectionName,
                    inspiration,
                    creator_id: userId,
                };
                await creatorCollection(requestBody);
                dispatch(revalidateCreatorCollection({ creatorId: userId }));
                showToast("Collection Created successfully", 'success')

                navigate("/creator", { state: { messageType: "success", message: "Success! We have received your collection details and our team will get back to you soon.", hideBreadCrumb: true } });
            }
            setInspiration('')
            setCollectionName('')
            setSelectedFiles([]);

        } catch (error) {
            setErrorMessage("something went wrong")
            console.error("Error creating commission", error);
        } finally {
            setLoading(false)
        }
    };

    function formatFileSize(size) {
        const units = ["bytes", "KB", "MB", "GB"];
        if (size < 1024) return `${size.toFixed(2)}${units[0]}`;
        let i = 0;
        let formattedSize = size;

        while (formattedSize >= 1024 && i < units.length - 1) {
            formattedSize /= 1024;
            i++;
        }

        return `${formattedSize.toFixed(2)}${units[i]}`;
    }

    const getIcon = (fileName) => {
        let split = fileName.split(".");
        let extension = split[split.length - 1].toLowerCase();;
        return <CommonFile extName={extension} />;
    };

    return (
        <div>
            <div className='grid-container'>
                <div className='col-lg-12 mb-5'>
                    <div className="back-to-galleries-div ">
                        <Link to="/creator" className="back-to-galleries-link flex gap-2  text-[#6941C6] font-bold">
                            <svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g id="arrow-left">
                                    <path
                                        id="Icon"
                                        d="M16.0436 10.8603H4.37695M4.37695 10.8603L10.2103 16.6936M4.37695 10.8603L10.2103 5.02698"
                                        stroke="#344054"
                                        stroke-width="1.66667"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </g>
                            </svg>

                            Back to all projects
                        </Link>
                    </div>
                    <div className='row align-items-center w-full custom-max-width '>
                        <h1 className='mt-5 text-[30px] font-bold'>Upload your work</h1>
                        <div className='w-full mt-4'>
                            <div className='py-4'>
                                <InputText value={collectionName} onChange={(e) => setCollectionName(e.target.value)} className=" w-2/5" label='Name your collection' placeholder='Collection is a group of one or more images or videos' isImportant={true} />
                            </div>
                            <div className='py-4 w-11/12'>
                                <TextArea value={inspiration} onChange={(e) => setInspiration(e.target.value)} className='p-3' wordLimit={200} rows="10" label='What has inspired you to create this work?' isImportant={true} placeholder='Tell the brands a little bit about this piece(s). This makes your work stand out to brands.' />
                            </div>
                            <div className='w-11/12 flex flex-col gap-4'>
                                <div className='mb-3'>
                                    Upload your work*
                                </div>
                                <label className="upload-control" htmlFor="upload-input">
                                <input
                                    type="file"
                                    multiple
                                    id="upload-input"
                                    accept="image/*, video/*"
                                    onChange={handleFileChange}
                                />
                                    <div className="uploadicon p-2 mb-3 rounded-md">
                                        {/* <UploadIcon /> */}
                                        <CloudUploadIcon />
                                    </div>
                                    <div className="upload-options">
                                        <span className="click">Click to upload
                                            {/* <span className='text-gray-500'>or drag and drop</span> */}
                                        </span>
                                    </div>
                                    <div className=" mt-0 p-0 text-gray-500">
                                       Image or Video (MP4 only)
                                        {/* Zip or folder */}
                                    </div>
                                </label>
                                {selectedFiles.length > 0 && (
                                    <ul
                                        style={{ listStyleType: "none", margin: 0, padding: 0 }}
                                        className="flex flex-col gap-y-3"
                                    >
                                        {selectedFiles.map((file, index) => (
                                            <li className="selected-files my-1 flex flex-col gap-1" key={file.name}>
                                                <div className="selected-file-grouping">
                                                    <div className="icons-files">{getIcon(file.name)}</div>
                                                    <div className="file-details">
                                                        <div className="filename">{file.name}</div>
                                                        <div className="filetype">
                                                            ({formatFileSize(file.size)})
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="cursor-pointer"
                                                        onClick={() => removeFile(index)}
                                                    >
                                                        <DeleteIcon />
                                                    </div>
                                                </div>
                                                <ManageThumnail fileName={file.name} isVideo={file.type?.includes('video')} thumbnails={thumbnails} setThumbnails={setThumbnails} videoIndex={index} />
                                                
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            <div className='pt-3 ps-3 w-11/12'>
                                <Checkbox checkboxError={checkboxError} isSubmitting={loading} checked={isChecked ? true : false} onChange={() => setIsChecked(!isChecked)} className={`${!isChecked ? "text-black" : "text-black"}`} name="" label={'I certify – that the submitted creative works are original, created by me, have not been plagiarized from any other sources, are non-AI generated, and aren’t currently licensed or sold to anyone else.'} />

                                {/* <RadioButton checked={isChecked ? true : false} onChange={() => setIsChecked(!isChecked)} name="" label={'I certify that the submitted creative works are original, created by me, have not been plagiarized from any other sources, are non-AI generated, and aren’t currently licensed or sold to anyone else'} /> */}
                            </div>
                            <div className='flex justify-end gap-3   mt-4 w-11/12'>
                                <SecondaryButton onClick={()=>navigate(-1)} text='Cancel' />
                                {/* <PrimaryButton text='Upload' disabled className='bg-slate-400' /> */}
                                <div className="">
                                    <button
                                        className="button-primary-sw btn-center "
                                        type="submit"
                                        disabled={loading}
                                        style={{ width: "100px" }}
                                        onClick={handleCreateCollection}
                                    >
                                        {loading ? "Uploading..." : "Upload"}
                                    </button>
                                </div>
                            </div>
                            {errorMessage && (
                                <div className="form-row">
                                    <div className="login-form-group">
                                        <div className="alert text-red-400">{errorMessage}</div>
                                    </div>
                                </div>
                            )}
                        </div>



                    </div>
                </div>
            </div>
        </div>
    )
}

export default UploadCreatorContent



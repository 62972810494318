import React from 'react'

function OutllinedPrimaryBtn({
    label = "",
    type = "button",
    className = "",
    onClick = () => { },
    disabled = false,
    style={}
}) {
    return (
        <button style={style} type={type} className={`${disabled && 'cursor-not-allowed opacity-90'} border-[1px] border-violet p-2 rounded text-violet w-full font-semibold text-lg ${className}`} disabled={disabled} onClick={onClick}>{label}</button>
    )
}

export default OutllinedPrimaryBtn
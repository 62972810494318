import React, { useState } from 'react';
import ImageGallery from '../../../components/Galleries';
import ScrollToTop from '../../../components/common/ScrollToTop';

function Shop() {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <ScrollToTop isLoading={isLoading}>
      <ImageGallery filterDropDownKey="filter_for_shop" title='Shop' limit="10" onLoadingChange={setIsLoading}/>
    </ScrollToTop>
  )
}

export default Shop
import React from "react";
import Select from "react-select";
import "./selectDropDown.css"; // Ensure this file is correctly styled
import CheckMark from "../../icons/CheckMark"; // Ensure CheckMark is a valid component

function MultiSelectDropDown({
    options,
    value = [],
    onChange,
    name = '',
    dropDownLabel,
    className = "",
    placeholder
}) {
    // Handle multiple selections
    const handleChange = (selectedOptions) => {
        const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
        onChange({ target: { value: values, name: name }, data: selectedOptions });
    };

    // Modify options to include icons and checkmark for selected items
    const modifiedOptions = options.map((option) => {
        const isSelected = value.includes(option.value);
        return {
            ...option,
            label: (
                <div className="flex gap-3 justify-between items-center w-full">
                    <div className="flex gap-3">
                        {option.icon && <span className="">{option.icon}</span>}
                        <span className="text-[13px]">{option.label}</span>
                    </div>
                    {isSelected && <span className="optionSelected"><CheckMark /></span>}
                </div>
            )
        };
    });

    // Convert selected values to option objects
    const defaultValue = modifiedOptions.filter(option => value.includes(option.value));

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: 16,
            color: '#101828',
            backgroundColor: state.isSelected ? '#d9d7d7' : 'white',
            cursor: 'pointer'
        }),
        control: (provided, state) => ({
            ...provided,
            border: "1px solid #D0D5DD",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.15)",
            borderRadius: "8px",
            borderColor: state.isFocused ? '#D6BBFB' : provided.borderColor,
            '&:hover': {
                borderColor: state.isFocused ? '#D6BBFB' : provided.borderColor,
            },
        }),
    };

    return (
        <div >
            {dropDownLabel && <label className='text-black mb-2 text-[14px]'>{dropDownLabel}</label>}
            <Select
                isMulti
                name={name}
                styles={customStyles}
                className={`w-[200px] rounded-[8px] ${className} `}
                onChange={handleChange}
                value={defaultValue}
                options={modifiedOptions}
                placeholder={placeholder || "Select..."}
            />
        </div>
    );
}

export default MultiSelectDropDown;

import React, { useState, useRef, useEffect } from 'react';
import "./CustomSelect.css"
import DownArrowIcon from '../../../icons/DownArrowIcon';
import CheckMark from '../../../icons/CheckMark';

export const CustomSelect = ({ options, value:selectedValue, onChange, name, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  const handleOptionClick = (optionValue) => {
    onChange({ target: { name, value: optionValue } });
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`relative ${className} `} ref={selectRef}>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex justify-between border border-silver p-2 py-2 text-[16px] rounded-lg focus:outline-none box-shadow-default"
      >
        <span className='text-primary-black'>
          {options.find(option => option.value === selectedValue)?.label || <span className='text-secondary-gray'>Select an option</span>}
        </span>
        <DownArrowIcon />
      </button>
      {isOpen && (
        <div className="absolute w-full bg-white border border-silver rounded-lg mt-1 z-10 max-h-[280px] overflow-auto">
          {options.map(({ label, value, icon }, index) => (
            <div   key={index}
              className={`flex items-center justify-between gap-2 p-3 px-1 cursor-pointer hover:bg-[#F9FAFB] m-1 ${selectedValue === value && "bg-[#F9FAFB]"}`}
              onClick={() => handleOptionClick(value)}>
                <div className="flex gap-3 ">
                    <span className="">{icon}</span>
                    <span className="text-[16px] ">{label}</span>
                </div>
                {selectedValue === value && <span className="optionSelected pe-2 "><CheckMark /></span>}
            </div>
          
          ))}
        </div>
      )}
    </div>
  );
};
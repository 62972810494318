import React from "react";

const InviteIcon = () => {
  return (
    <svg
      className="bx bx-pie-chart-alt icon"
      width="26"
      height="26"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.6167 3.65668L7.34819 7.66872C7.81231 7.9936 8.04437 8.15605 8.29679 8.21897C8.51975 8.27454 8.75295 8.27454 8.97592 8.21897C9.22834 8.15605 9.4604 7.9936 9.92451 7.66872L15.656 3.65668M4.98613 12.7822H12.2866C13.466 12.7822 14.0557 12.7822 14.5062 12.5527C14.9024 12.3508 15.2246 12.0286 15.4265 11.6324C15.656 11.1819 15.656 10.5922 15.656 9.41279V4.92021C15.656 3.7408 15.656 3.1511 15.4265 2.70062C15.2246 2.30437 14.9024 1.98221 14.5062 1.78031C14.0557 1.55078 13.466 1.55078 12.2866 1.55078H4.98613C3.80672 1.55078 3.21701 1.55078 2.76654 1.78031C2.37029 1.98221 2.04813 2.30437 1.84623 2.70062C1.6167 3.1511 1.6167 3.7408 1.6167 4.92021V9.41279C1.6167 10.5922 1.6167 11.1819 1.84623 11.6324C2.04813 12.0286 2.37029 12.3508 2.76654 12.5527C3.21701 12.7822 3.80672 12.7822 4.98613 12.7822Z"
        stroke="#7F56D9"
        strokeWidth="1.40393"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InviteIcon;




import React, { useState, useEffect, useCallback } from 'react';
import './form.css';

// Utility function to count words
const countWords = (text) => {
    return text.trim().split(/\s+/).filter(Boolean).length;
};

const TextArea = ({
    isImportant = false,
    placeholder = 'Enter text',
    className = '',
    value = '',
    onChange,
    label,
    wordLimit,
    disabled=false,
    ...props
}) => {
    const [text, setText] = useState(value);

    useEffect(() => {
        setText(value);
    }, [value]);

    const handleChange = useCallback((e) => {
        const newValue = e.target.value;
        const wordCount = countWords(newValue);

        if (wordLimit && wordCount > wordLimit) return;

        setText(newValue);
        if (onChange) onChange(e);
    }, [wordLimit, onChange]);

    const currentWordCount = countWords(text);
    const isLimitExceeded = wordLimit && currentWordCount >= wordLimit;

    return (
        <div className="textarea-container">
            {label && (
                <label className="textarea-label">
                    {label}
                    {isImportant && <span className="textarea-important">*</span>}
                </label>
            )}
            <textarea
                placeholder={placeholder}
                value={text}
                onChange={handleChange}
                disabled={disabled}
                {...props}
                className={`textarea ${disabled && 'cursor-not-allowed'} ${className}`}
            />
            {wordLimit && (
                <div className={`character-counter ${isLimitExceeded ? 'limit-exceeded' : ''}`}>
                    {currentWordCount} / {wordLimit} Words max
                </div>
            )}
        </div>
    );
};


export default TextArea;


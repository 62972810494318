import React from 'react'
import LoginLogo from '../components/LoginLogo'

const AuthLayout = ({ children }) => {
    return (
        <div className="w-full min-h-screen position-relative ">
            <div className="flex flex-col sm:py-8 sm:px-12 w-full position-absolute">
                <LoginLogo />
            </div>
            {children}
        </div>
    )
}

export default AuthLayout
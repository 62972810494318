import React, { createContext, useContext, useState } from "react";

// Create a context for the auth screen
const AuthContext = createContext();



export const AuthProvider = ({ children }) => {
    const [signupData, setSignupData] = useState({
        screenType: "start",
        screenNo: 1,
        userType: "",
        code: "",

    })
    const changeSignupData = (data) => {
        setSignupData({ ...signupData, ...data })
    }
    return (
        <AuthContext.Provider value={{ signupData, setSignupData, changeSignupData }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use the AuthContext
export const useAuthProvider = () => useContext(AuthContext);

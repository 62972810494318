import React from "react";

const HeartIcon = ({isFavorite=false}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={isFavorite ? "red" : "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9932 5.13313C9.9938 2.79571 6.65975 2.16696 4.15469 4.30733C1.64964 6.4477 1.29697 10.0263 3.2642 12.5577C4.89982 14.6624 9.84977 19.1014 11.4721 20.5381C11.6536 20.6989 11.7444 20.7793 11.8502 20.8108C11.9426 20.8384 12.0437 20.8384 12.1361 20.8108C12.2419 20.7793 12.3327 20.6989 12.5142 20.5381C14.1365 19.1014 19.0865 14.6624 20.7221 12.5577C22.6893 10.0263 22.3797 6.42518 19.8316 4.30733C17.2835 2.18947 13.9925 2.79571 11.9932 5.13313Z"
        stroke={isFavorite ? "red" : "#7F56D9"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HeartIcon;

import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];

  // Determine page numbers to show based on the current page
  if (totalPages <= 5) {
    // Show all pages if there are 5 or less
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  } else if (currentPage <= 3) {
    // Show the first 3 pages, ..., last page
    pageNumbers.push(1, 2, 3, 4, '...', totalPages);
  } else if (currentPage > totalPages - 3) {
    // Show the first page, ..., last 3 pages
    pageNumbers.push(1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
  } else {
    // Show the first page, ..., current page - 1, current page, current page + 1, ..., last page
    pageNumbers.push(1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages);
  }

  return (
    <div className="flex justify-center items-center space-x-2 mt-4">
      {/* Previous button */}
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={`px-3 py-1 border rounded ${
          currentPage === 1 ? 'bg-gray-200 text-gray-400 cursor-not-allowed' : 'bg-white text-violet hover:bg-blue-100'
        }`}
      >
        Previous
      </button>

      {/* Page numbers */}
      {pageNumbers.map((page, index) =>
        typeof page === 'number' ? (
          <button
            key={index}
            onClick={() => onPageChange(page)}
            className={`px-3 py-1 border rounded ${
              currentPage === page ? 'bg-violet text-white' : 'bg-white text-violet hover:bg-violet'
            }`}
          >
            {page}
          </button>
        ) : (
          <span key={index} className="px-3 py-1">
            {page}
          </span>
        )
      )}

      {/* Next button */}
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`px-3 py-1 border rounded ${
          currentPage === totalPages ? 'bg-gray-200 text-gray-400 cursor-not-allowed' : 'bg-white text-violet hover:bg-violet'
        }`}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;

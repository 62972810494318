import React from 'react'

function AccountIcon() {
    return (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.13378 11.5C5.49205 11.5 3.14278 12.7755 1.64711 14.755C1.3252 15.181 1.16425 15.394 1.16951 15.6819C1.17358 15.9043 1.31325 16.1849 1.48825 16.3222C1.71477 16.5 2.02867 16.5 2.65647 16.5H13.6111C14.2389 16.5 14.5528 16.5 14.7793 16.3222C14.9543 16.1849 15.094 15.9043 15.0981 15.6819C15.1033 15.394 14.9424 15.181 14.6205 14.755C13.1248 12.7755 10.7755 11.5 8.13378 11.5Z" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" strokeLinejoin="round" />
            <path d="M8.13378 9C10.2048 9 11.8838 7.32107 11.8838 5.25C11.8838 3.17893 10.2048 1.5 8.13378 1.5C6.06271 1.5 4.38378 3.17893 4.38378 5.25C4.38378 7.32107 6.06271 9 8.13378 9Z" stroke="#98A2B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default AccountIcon
import React from 'react'
import BearSleepingIcon from '../icons/BearSleeping';

function ComingSoon() {
    return (
        <div className='w-full min-h-[100vh] flex justify-center items-center'>
            <div className='flex flex-col items-center'>
                <BearSleepingIcon />
                <p className='text-center text-[50px] text-violet'>Coming soon</p>
                <p className='text-[20px] text-center '>We are busy building an exceptional digital experience for you.<br />
                    Please revisit this page later.</p>
            </div>

        </div>
    )
}

export default ComingSoon
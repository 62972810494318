const commissionStatus = {
    progress: 'in_progress',
    active: 'active',
    complete: 'complete',
    failed: 'failed'
}
const EXCLUSIVE_LISCENCE_LINK = 'https://www.swiirl.io/2-year-exclusive-license-agreement'
const ADMIN_COPY_CREATE_COMMISSION_SCHOOL_XREF_VALIDATION = 'Missing required fields.'
const ADMIN_COPY_SOMETHING_WENT_WRONG = 'Something went wrong.'


export const constants = {
    commissionStatus,
    ADMIN_COPY_CREATE_COMMISSION_SCHOOL_XREF_VALIDATION,
    ADMIN_COPY_SOMETHING_WENT_WRONG,
    EXCLUSIVE_LISCENCE_LINK
}
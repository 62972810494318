import axios from "axios";
import { GetWithToken, PatchWithToken, Post, PostWithToken, PutWithToken } from "../lib/axios";
// const baseUrl = 'https://l26dkbzcng.execute-api.us-east-2.amazonaws.com/brand';
const baseUrl = process.env.REACT_APP_API_URL + "/brand";



export const getBrandServer = async () => {
    try {
        const res = await GetWithToken({ endpoint: '/brand' })
        return res
    } catch (error) {
        console.log(error)
    }
}





export const createSchoolCollection = async (payload) => {
    try {
        let response = await PostWithToken({baseUrl:'http://localhost:4000', endpoint: `/school/collection/create`, data: { ...payload } })
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const getAllSchoolCollections = async ({ school_id, searchText = '', offset, limit }) => {
    try {
        let response = await GetWithToken({baseUrl:'http://localhost:4000', endpoint: `/school/collection/get-collections-by-schoolId/${school_id}?search=${searchText}&limit=${limit}&offset=${offset}` })
        return response.data
    } catch (error) {
        console.error(error)
        throw error

    }
}


export const getAllProjectsBySchoolId = async ({ school_id, searchText = '', offset, limit, projectStatus = 'in_progress' }) => {
    try {
        let response = await GetWithToken({ baseUrl: 'http://localhost:4000', endpoint: `/school/projects/get-projects-by-schoolId/${school_id}?search=${searchText}&limit=${limit}&offset=${offset}&project_status=${projectStatus}` })
        return response.data
    } catch (error) {
        console.error(error)
        throw error

    }
}


export const getCommissionDetails = async ({ commissionXrefId }) => {
    try {
        let response = await GetWithToken({ baseUrl: 'http://localhost:4000', endpoint: `/school/commission/get-commission-details?commissionXrefId=${commissionXrefId}` })
        return response.data
    } catch (error) {
        console.error(error)
        throw error

    }
}




export const sendInvitationMailToSchoolUsers = async ({ payload }) => {
    try {
        const res = await PostWithToken({ endpoint: '/school/send-invite-mail', data: payload })
        return res.data
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const editSchoolUser = async (schoolUser) => {
    try {
        const res = await PatchWithToken({baseUrl:'http://localhost:4000', endpoint: '/school/update', data: { schoolUser } })
        return res
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const editSchool = async (school) => {
    try {
        const res = await PatchWithToken({ endpoint: '/school/update', data: { school } })
        return res
    } catch (error) {
        console.log(error)
        throw error
    }
}





export const getUploadUrl = async (keyNames, isGetUrl = false) => {
    try {
        const res = await PostWithToken({ endpoint: '/brand/upload-image', data: { keyNames, isGetUrl } })
        return res?.data

    } catch (error) {
        console.log(error)
    }
}



export const uploadFileTos3 = async (url, file) => {
    try {
        let response = await axios.put(url, file, {
            headers: {
                'Content-Type': file.type,
                'Authorization': undefined,
                "access-control-allow-origin": "*"
            }
        })
        return response

    } catch (error) {
        console.error(error)
        throw error
    }
}

export const getAllSchoolUsers = async (payload) => {
    const { limit, page, search = '' } = payload;
    try {
        const response = await GetWithToken({endpoint:`/school/all/users?limit=${limit}&page=${page}&search=${search}`});
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};




export const getAllSchools = async ({page,limit, searchText=''}) => {
    try {
        const { data } = await GetWithToken({baseUrl:'http://localhost:4300',endpoint:`/brand/admin/get-all-schools?page=${page}&limit=${limit}&searchText=${searchText}`})
        return data;
    } catch (error) {
        throw error;
    }
}




export const addProjectContent = async (payload) => {
    try {
        const response = await PostWithToken({ baseUrl: "http://localhost:4000", endpoint: "/school/project/add-content", data: payload }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};


export const editProjectContent = async (payload) => {
    try {
        const response = await PutWithToken({ baseUrl: "http://localhost:4000", endpoint: "/school/project/edit-content", data: payload }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};




export const getProjectContent = async (commissionXrefId) => {
    try {
        const response = await GetWithToken({ baseUrl: "http://localhost:4000", endpoint: `/school/project/get-project-contents?commision_school_xref_id=${commissionXrefId}` }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};

export const getProjectParticipants = async (commissionXrefId) => {
    try {
        const response = await GetWithToken({ baseUrl: "http://localhost:4000", endpoint: `/school/project/get-project-participants?commision_school_xref_id=${commissionXrefId}` }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};


export const acceptRejectProject = async (payload) => {
    try {
        const response = await PatchWithToken({ baseUrl: "http://localhost:4000", endpoint: `/school/project/accept`,data:payload }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};


export const submitProject = async (payload) => {
    try {
        const response = await PatchWithToken({ baseUrl: "http://localhost:4000", endpoint: `/school/project/submit`,data:payload }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};




// useModal.js
import { useState } from 'react';

const useModal = () => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen((prev) => !prev);
  };

  return {
    open,
    setOpen,
    toggle,
  };
};

export default useModal;

import React from "react";
import CommissionGalleries from "../../../components/CommissionGalleries";
import BackNavigator from "../../../components/BackNavigator/BackNavigator";

const CommGalleries = () => {
  return (
    <div className="custom-max-width">
      <BackNavigator text="Back to all galleries" />
      <CommissionGalleries />
    </div>
  );
};

export default CommGalleries;

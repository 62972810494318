import React from 'react';

function DropDown({ options = [{ label: 'sample', value: '', icon: null }], className = '',onChange=null,name='dropdown',value='', containerClassName="", dropDownLabel }) {
  const handleChange = (e) => {
   onChange && onChange(e)
  }

  return (
    <div className={containerClassName}>
    {
      dropDownLabel && <label className='text-black mb-2 text-[14px]' htmlFor="">{dropDownLabel}</label>
    }
    <select onChange={handleChange} name={name} value={value} className={`border border-silver p-2 text-[16px] rounded-lg focus-visible:outline-none ${className}`}>
      {options.map(({ label, value, icon }, index) => (
        <option key={index} value={value} className='flex gap-2 w-[100px] py-3 px-2 cursor-pointer'>
          {icon}
          {label}
        </option>
      ))}
     
    </select>
      </div>
  )
}

export default DropDown
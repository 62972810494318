import React from 'react'

function LocationIcon() {
    return (
        <svg width="17px" height="20px" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8579 15.7319C13.929 15.7319 15.6079 14.053 15.6079 11.9819C15.6079 9.91087 13.929 8.23193 11.8579 8.23193C9.78684 8.23193 8.10791 9.91087 8.10791 11.9819C8.10791 14.053 9.78684 15.7319 11.8579 15.7319Z"
                stroke="#7F56D9"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M11.8579 26.9819C16.8579 21.9819 21.8579 17.5048 21.8579 11.9819C21.8579 6.45909 17.3808 1.98193 11.8579 1.98193C6.33506 1.98193 1.85791 6.45909 1.85791 11.9819C1.85791 17.5048 6.85791 21.9819 11.8579 26.9819Z"
                stroke="#7F56D9"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>
    )
}

export default LocationIcon;
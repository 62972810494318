import React from "react";

const EditIcon = ({size}) => {
  return (
    <svg
      width={size||"27"}
      height={size||"26"}
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9486 19.2442L21.9026 20.3884C21.3479 20.9951 20.5956 21.3359 19.8112 21.3359C19.0268 21.3359 18.2745 20.9951 17.7197 20.3884C17.1642 19.783 16.412 19.443 15.6278 19.443C14.8436 19.443 14.0914 19.783 13.5359 20.3884M4.12317 21.3359H5.8745C6.38612 21.3359 6.64193 21.3359 6.88266 21.2781C7.09609 21.2269 7.30012 21.1424 7.48727 21.0277C7.69836 20.8983 7.87925 20.7175 8.24101 20.3557L21.3799 7.21683C22.2463 6.35042 22.2463 4.94567 21.3799 4.07926C20.5134 3.21284 19.1087 3.21284 18.2423 4.07926L5.10341 17.2181C4.74164 17.5799 4.56076 17.7608 4.4314 17.9718C4.31672 18.159 4.2322 18.363 4.18096 18.5765C4.12317 18.8172 4.12317 19.073 4.12317 19.5846V21.3359Z"
        stroke="#344054"
        stroke-width="2.09172"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default EditIcon;

export const getMimeTypeFromFileName = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    const mimeTypes = {
        // Image Formats
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        gif: 'image/gif',
        bmp: 'image/bmp',
        tiff: 'image/tiff',
        webp: 'image/webp',
        svg: 'image/svg+xml',
        
        // Video Formats
        mp4: 'video/mp4',
        mkv: 'video/x-matroska',
        webm: 'video/webm',
        avi: 'video/x-msvideo',
        mov: 'video/quicktime',
        flv: 'video/x-flv',
        mpeg: 'video/mpeg',
        wmv: 'video/x-ms-wmv',

        // Audio Formats
        mp3: 'audio/mpeg',
        wav: 'audio/wav',
        ogg: 'audio/ogg',
        aac: 'audio/aac',
        
        // Document Formats
        pdf: 'application/pdf',
        doc: 'application/msword',
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        xls: 'application/vnd.ms-excel',
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ppt: 'application/vnd.ms-powerpoint',
        pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        
        // Archive Formats
        zip: 'application/zip',
        tar: 'application/x-tar',
        gz: 'application/gzip',
        rar: 'application/x-rar-compressed',
        
        // Miscellaneous
        json: 'application/json',
        xml: 'application/xml',
    };
    return mimeTypes[extension] || 'application/octet-stream'; // Default type
};

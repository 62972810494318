import React, { useEffect } from 'react';
import loaderImage from '../../icons/GlobalLoaderImageWithoutBG.svg'

const GlobalLoader = ({ isLoading }) => {
    useEffect(() => {
        // Disable scrolling when loading is true
        if (isLoading) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            // Cleanup overflow when the component unmounts
            document.body.style.overflow = 'unset';
        };
    }, [isLoading]);

    if (!isLoading) return null;

    return (
        <div
            className="fixed inset-0 flex items-center justify-center z-[999]"
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }} // rgba for semi-transparent background
        >
            <img
                src={loaderImage}
                alt="Loading"
                className='bg-transparent'
            />
        </div>
    );
};

export default GlobalLoader;


import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { forgotPassword } from "../../../services/aws/AwsService"; // Import the AWS service
import "./ForgotPassword.css";
import AuthLayout from "../../../HOC/AuthLayout";

const ForgotPassword = () => {
  const [username, setUsername] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  // Email validation function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault(); // Prevent form submission

    // Check if the email is valid
    if (!validateEmail(username)) {
      setErrorMessage("Please enter a valid email address.");
      setSuccessMessage("");
      return;
    }

    try {
      await forgotPassword(username);
      setErrorMessage("");
      setSuccessMessage(
        "Verification code sent successfully. Check your email."
      );
      navigate(`/reset-password?username=${username}`);
    } catch (error) {
      setSuccessMessage("");
      setErrorMessage(`Error: ${error.message || JSON.stringify(error)}`);
    }
  };

  return (
    <AuthLayout>
      <div className="login-container">
        <div className="login-form-wrapper">
          <form className="loginform" onSubmit={handleForgotPassword}>
            <div className="login-form-container">
              <div className="login-form-header flex justify-center">
                <div className='text-center'>
                  <h1 className='font-[600]'>Forgot password</h1>
                  <p className='text-[#475467] mb-0'>Welcome! Please enter your email.</p>
                </div>
              </div>

              <div className="login-form-row">
                <div className="login-form-group">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control-input"
                    placeholder="Enter your email"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              </div>
              <div className="login-form-row">
                <div className="login-form-group">
                  <div className="back-to-link-div">
                    <Link to="/" className="back-to-login-link">
                      Back to Login
                    </Link>
                  </div>
                </div>
              </div>
              <div className="login-form-button">
                <div className="login-form-group">
                  <button
                    type="submit"
                    className="button-primary-sw-100 btn-center"
                  >
                    Send Verification Code
                  </button>
                </div>
              </div>
              {errorMessage && (
                <div className="login-form-row">
                  <div className="login-form-group">
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  </div>
                </div>
              )}
              {successMessage && (
                <div className="login-form-row">
                  <div className="login-form-group">
                    <p style={{ color: "green" }}>{successMessage}</p>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;




import React from 'react'

const RightArrowIcon = ({ size, stroke }) => {
  return (
    <svg width={size || "20"} height={size || "21"} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 15.3323L12.5 10.3323L7.5 5.33228" stroke={stroke || "#344054"} stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export default RightArrowIcon
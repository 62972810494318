import React from 'react'

function FlagIcon() {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.0133 7.09722H20.967C21.4392 7.09722 21.6752 7.09722 21.8133 7.19649C21.9337 7.2831 22.0122 7.41641 22.0294 7.56377C22.0491 7.73267 21.9345 7.93903 21.7052 8.35177L20.2843 10.9093C20.2011 11.059 20.1596 11.1339 20.1433 11.2131C20.1288 11.2833 20.1288 11.3556 20.1433 11.4258C20.1596 11.505 20.2011 11.5799 20.2843 11.7295L21.7052 14.2871C21.9345 14.6998 22.0491 14.9062 22.0294 15.0751C22.0122 15.2225 21.9337 15.3558 21.8133 15.4424C21.6752 15.5417 21.4392 15.5417 20.967 15.5417H13.5355C12.9443 15.5417 12.6487 15.5417 12.4229 15.4266C12.2243 15.3254 12.0628 15.1639 11.9616 14.9653C11.8466 14.7395 11.8466 14.4439 11.8466 13.8528V11.3194M8.15215 21.875L3.92993 4.98611M5.51331 11.3194H13.3244C13.9155 11.3194 14.2111 11.3194 14.4369 11.2044C14.6355 11.1032 14.797 10.9417 14.8982 10.7431C15.0133 10.5173 15.0133 10.2217 15.0133 9.63056V4.56389C15.0133 3.97272 15.0133 3.67714 14.8982 3.45134C14.797 3.25273 14.6355 3.09125 14.4369 2.99005C14.2111 2.875 13.9155 2.875 13.3244 2.875H5.56522C4.82789 2.875 4.45923 2.875 4.20708 3.02778C3.98607 3.16168 3.82187 3.37199 3.74557 3.61887C3.65851 3.90055 3.74793 4.25821 3.92676 4.97352L5.51331 11.3194Z" stroke="#344054" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default FlagIcon
import React from "react";
import "./Login.css";

import BrandLogin from "../../../components/auth/BrandLogin";

function Login() {
  return (
    <BrandLogin />
  )
}

export default Login;

import React, { useContext, useState } from 'react';
import BorderLessButton from './buttons/BorderLessButton';
import DeleteIcon from '../icons/Delete';
import EyeOff from '../icons/EyeOff';
import CustomImage from './CustomImage/CustomImage';
import CreatorGalleryCardActions from './CreatorGalleryCardActions/CreatorGalleryCardActions';
import { deleteCollectionImages, hideUnhideCollectionImages } from '../services/creatorService';
import DeletePopup from './popups/DeletePopup';
import useToast from '../hooks/useToast';
import VideoPlayer from '../components/video/player/VideoPlayer'
import { SidebarContext } from '../App';
import { revalidateCreatorCollection } from '../redux/reducerFiles/creatorCollectionReducer';
import { useDispatch, useSelector } from 'react-redux';

const CollectionGallery = ({ columns, collectionData, refresh, setRefresh }) => {
    const showToast = useToast()
    const { setGlobalLoader } = useContext(SidebarContext)
    const dispatch = useDispatch()
    const [selectedImages, setSelectedImages] = useState([]);
    const [openDeleteModel, setOpenDeleteModel] = useState(false)
    const {userId} = useSelector(state=>state.user)
    const [deleteLoading, setDeleteLoading] = useState(false); //
    const [hideLoading, setHideLoading] = useState(false)



    const getGalleryActionButtonDetails = () => {
        return selectedImages?.length ? { color: '#7F56D9' } : { color: '#98A2B3' };
    };

    const handleSelectAllChange = (event) => {
        if (event.target.checked) {
            const images = collectionData?.images || [];
            const ids = images.filter((item) => !item?.isLicenced).map((item) => item?.gallery_content_id);
            setSelectedImages(ids);
        } else {
            setSelectedImages([]);
        }
    };


    const handleDeleteImages = async () => {
        try {
            setDeleteLoading(true)
            const resp = await deleteCollectionImages(selectedImages);
            dispatch(revalidateCreatorCollection({ creatorId: userId }));
            setRefresh(!refresh)
            if (resp?.status === 200) {
                setSelectedImages([]); // Clear selected images after successful deletion
            }
            showToast("Images Deleted successfully", 'success')

        } catch (error) {
            console.log(error)
        } finally {
            setDeleteLoading(false)

        }

    };

    const handleHideFromStore = async (type = "hide") => {
        try {
            setHideLoading(true)
            const payload = {
                gallery_content_ids: selectedImages,
                type
            };
            await hideUnhideCollectionImages(payload);
            dispatch(revalidateCreatorCollection({ creatorId: userId }));

            showToast(`Images ${type} from store  successfully`, 'success')


            setSelectedImages([]); // Clear selected images after successful hide/unhide

            setRefresh(!refresh)
        } catch (error) {
            console.log(error)
        } finally {
            setHideLoading(false)
        }


    };


    const handleDeleteModel = (state = false, type = 'collection') => {
        setOpenDeleteModel(state)
    }

    const anyImageHidden = selectedImages.some(id => {
        const image = collectionData?.images?.find(img => img.gallery_content_id === id);
        return !image?.isShow;
    });

    return (
        <div>
            <DeletePopup
                isOpen={openDeleteModel}
                title='Delete image?'
                subText='Your images will be permanently removed from our database and/or store.'
                onClose={() => handleDeleteModel(false)}
                onDelete={handleDeleteImages}
                loading={deleteLoading}

            // isDelete={isLicenced ? false : true}        // this use when collection is not deletable
            // type={'collection'}    // this use when collection is not deletable

            />
            <div className="flex justify-between items-center mt-[43px] w-full">
                <div>
                    <div className={`${selectedImages.length ? 'flex' : 'hidden'} gap-[4px] items-center`}>
                        <input onChange={handleSelectAllChange} type="checkbox" className="rounded-sm w-[15px] h-[15px]" />
                        <label className="text-sm text-violet font-normal" htmlFor="">Select all</label>
                    </div>
                </div>
                <div className="flex items-center gap-[2px]">
                    <BorderLessButton
                        onClick={() => setOpenDeleteModel(true)}
                        disabled={deleteLoading || hideLoading || selectedImages.length === 0}
                        style={{ color: getGalleryActionButtonDetails().color }}
                        Icon={<DeleteIcon color={getGalleryActionButtonDetails().color} className="w-[15px]" />}
                        text="Delete"
                    />
                    <BorderLessButton
                        onClick={() => handleHideFromStore(anyImageHidden ? "unhide" : "hide")}
                        disabled={deleteLoading || hideLoading || selectedImages.length === 0}
                        style={{ color: getGalleryActionButtonDetails().color }}
                        Icon={<EyeOff color={getGalleryActionButtonDetails().color} className="w-[15px]" />}
                        text={hideLoading ? "Updating images ..." : anyImageHidden ? "Unhide from store" : "Hide from store"}
                    />
                </div>
            </div>
            <div className="mt-[13px] parent">
                {columns && columns.length > 0 ? columns.map((column, colIndex) => (
                    <div className="column" key={colIndex}>
                        {column.map((obj, index) => (
                            <div
                                className={`image-container1 ${selectedImages.includes(obj.gallery_content_id) ? 'imageSelected' : ''}`}
                                style={{ minHeight: '400px' }}
                                key={index}
                            >
                                {
                                    obj?.media_type === 'video' ?
                                        <VideoPlayer videoUrl={obj.url} src={obj.thumbnailUrl} cacheKey={obj.thumbnailUrl} alt="image details page " imageContainerClass='max-w-[99%]' >
                                            <CreatorGalleryCardActions
                                                data={obj}
                                                // isVideo={true}
                                                setSelectedImages={setSelectedImages}
                                                selectedImages={selectedImages}
                                            />
                                        </VideoPlayer>
                                        :
                                        <CustomImage
                                            key={index}
                                            src={obj.url}
                                            alt={obj.alt || "Image"}
                                            imageClass="card-image"
                                            imageContainerClass="girdImage"
                                        >
                                            <CreatorGalleryCardActions
                                                data={obj}
                                                setSelectedImages={setSelectedImages}
                                                selectedImages={selectedImages}
                                            />
                                        </CustomImage>
                                }
                            </div>
                        ))}
                    </div>
                ))
                    :
                    <h2 className='text-center w-full mt-5'>No Images</h2>}
            </div>
        </div>
    );
};

export default CollectionGallery;

import React from 'react'

function Badge({
    text = '',
    className = '',
    type='default',
    width ='auto',

}) {

    if(!text.length) return null
  return (
    <p className={`px-3 p-1 rounded-full items-center font-medium border-solid border-2 ${type==='default' && 'text-slate-600 bg-slate-50'} ${type==='info' && 'text-blue-600 bg-blue-50 border-[#B2DDFF]'} ${type==='success' && 'text-green-600 bg-green-50 border-[#b3febe]'} ${type==='error' && 'bg-[#FEF6EE] text-[#B93815]  border-[#F9DBAF]'} ${className}`}
    style={{ width, minWidth: width, display: 'inline-flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center',  }}>
        {text}
    </p>
  )
}

export default Badge
export const StyledTableBodyCell = ({children, className=""}) => {
    return (
        <td className={`h-[70px] ${className}`} style={{paddingLeft: '30px'}}>{children}</td>
    )
}

export const StyledTableHeadCell = ({children, className=""}) => {
    return (
        <th className={`min-w-[200px] h-[70px] ${className}`} style={{ fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: 500,
            lineHeight: '18px',
            textAlign: 'left',
            color: '#475467', 
            paddingLeft: '30px',
        backgroundColor:'white' }}>{children}</th>
    )
}
import React from 'react'
import CrossIcon from '../../icons/CrossIcon';
import SecondaryButton from '../buttons/SecondaryButton';
import deleteIconSvg from '../../icons/deleteIcon.svg'
import DeleteRingIcon from '../../icons/DeleteRingIcon';

const DeletePopup = ({ isOpen, onClose, onDelete, title, subText, isDelete = true, type, loading }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[9999]">
            <div className="bg-white rounded-lg px-[10px] py-2 w-full min-h-[200px] max-w-lg">
                <div className="text-start relative overflow-hidden">
                    <div className='absolute top-[-130px] left-[-136px] w-[150px] h-[150px] '>
                        <DeleteRingIcon />
                    </div>
                    <div className="flex justify-between mb-4 cursor-pointer w-full p-3 ">
                        {/* <img src={deleteIconSvg} alt="delete-icon" className='w-12 h-12' /> */}
                        <div></div>
                        <div className='w-[30px]' onClick={() => !loading && onClose()}>
                            <CrossIcon />
                        </div>
                    </div>
                    <div className='px-[10px] py-2'>

                        <p className="text-lg font-bold text-gray-800 mb-2 ">
                            {isDelete ? (title || " Delete data?") : `Cannot delete ${type || 'collection'} `}
                        </p>
                        <p className="text-gray-600 mb-6">
                            {isDelete ? (subText || " Your data will be permanently removed from our database and server.") : `Your ${type || 'collection'} cannot be removed as it is currently licensed. `}
                        </p>

                        <div className="flex gap-4 mt-5 mb-1">
                            {isDelete ? <>
                                <SecondaryButton text={'Cancel'} onClick={onClose} disabled={loading ? true : false} className='border-1 border-silver font-semibold shadow-sm rounded-lg px-3 w-2/5' />
                                <button
                                    onClick={onDelete}
                                    className="font-semibold bg-red-600 text-white rounded-lg px-4 py-2 w-2/5"
                                    disabled={loading ? true : false}
                                > {loading ? "Deleting..." : 'Delete'}
                                </button>
                            </> :
                                <SecondaryButton text={'Cancel'} disabled={loading ? true : false} onClick={onClose} className='border-1 border-silver font-semibold shadow-sm rounded-lg px-3 w-full' />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeletePopup
import React from 'react'

const CheckCircle = () => {
    return (
        <div>
            <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.0001 16.9727L14.0001 20.9727L22.0001 12.9727M29.3334 16.9727C29.3334 24.3365 23.3639 30.3061 16.0001 30.3061C8.63628 30.3061 2.66675 24.3365 2.66675 16.9727C2.66675 9.60894 8.63628 3.6394 16.0001 3.6394C23.3639 3.6394 29.3334 9.60894 29.3334 16.9727Z" stroke="#079455" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

        </div>
    )
}

export default CheckCircle
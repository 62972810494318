import { useState } from "react";
import TargetLocationIcon from "../../icons/TargetLocation";
import FileIcon from "../../icons/Files";
import moment from "moment";
import CustomImage from "../CustomImage/CustomImage";
import MediaTypeIcon from "../../icons/Mediatype";
import TimelineIcon from "../../icons/Timeline";
import { constants } from '../../constants/constants'
import RightArrowIcon from "../../icons/RightArrowIcon";
import { Link } from "react-router-dom";
import PlayIcon from "../video/PlayIcon";
import ParticipantsTable from "../../pages/school/project-details/ParticipantsTable";
import CommissionMedia from "../CustomImage/CommissionMedia";

const { commissionStatus } = constants;

const SchoolTabView = ({ commissionToDisplay, fromContentPage, participants, setParticipants }) => {
    const [activeTab, setActiveTab] = useState("basicdetails");
    const [expanded, setExpanded] = useState(true); // State for toggle feature

    // Function to handle the toggle action
    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    if (!commissionToDisplay) {
        return <div>No commission data available.</div>; // Message when there's no data
    }

    return (
        <>
            <div className="latest-commission mt-5 " style={{ minHeight: expanded ? "480px" : "80px" }}>
                <div className="commission-details">
                    <div className="form-row">
                        <div className="form-group" style={{ paddingLeft: "10px" }}>
                            <div className="latest-commission-name">
                                {commissionToDisplay.name} Your project details
                            </div>
                            {(
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>

                                    <button
                                        onClick={toggleExpanded}
                                        className="
                                        w-8 h-8 
                                        flex items-center justify-center 
                                        text-gray-400 
                                        bg-transparent 
                                        transition-colors 
                                        focus:outline-none"
                                    >
                                        {expanded ? (
                                            // If expanded, show chevron-up icon
                                            <svg
                                                width="24"
                                                height="24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                flex items-center justify-center
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19 14l-7-7-7 7"></path>
                                            </svg>
                                        ) : (
                                            // If not expanded, show chevron-down icon
                                            <svg
                                                width="24"
                                                height="24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7"></path>
                                            </svg>
                                        )}
                                    </button>
                                </div>
                            )}
                            <div className="latest-commission-name-footer ">
                                <span className="flex items-center">
                                    {/* Add the SVG Icon */}
                                    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.13378 11.5C5.49205 11.5 3.14278 12.7755 1.64711 14.755C1.3252 15.181 1.16425 15.394 1.16951 15.6819C1.17358 15.9043 1.31325 16.1849 1.48825 16.3222C1.71477 16.5 2.02867 16.5 2.65647 16.5H13.6111C14.2389 16.5 14.5528 16.5 14.7793 16.3222C14.9543 16.1849 15.094 15.9043 15.0981 15.6819C15.1033 15.394 14.9424 15.181 14.6205 14.755C13.1248 12.7755 10.7755 11.5 8.13378 11.5Z" stroke="#98A2B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M8.13378 9C10.2048 9 11.8838 7.32107 11.8838 5.25C11.8838 3.17893 10.2048 1.5 8.13378 1.5C6.06271 1.5 4.38378 3.17893 4.38378 5.25C4.38378 7.32107 6.06271 9 8.13378 9Z" stroke="#98A2B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    {/* Add the participant s */}
                                    <span className="ml-1">
                                        {participants?.length || 0} participants
                                    </span>

                                </span>

                                {/* <ParticipantsTable participants={participants} className={'p-0'} /> */}

                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            {expanded && (
                                <div className="tabs">
                                    {/* Tab Buttons */}
                                    <button
                                        className={activeTab === "basicdetails" ? "active" : ""}
                                        onClick={() => setActiveTab("basicdetails")}
                                    >
                                        Basic Details
                                    </button>
                                    <button
                                        className={activeTab === "goals" ? "active" : ""}
                                        onClick={() => setActiveTab("goals")}
                                    >
                                        Goals
                                    </button>
                                    <button
                                        className={activeTab === "format" ? "active" : ""}
                                        onClick={() => setActiveTab("format")}
                                    >
                                        Format
                                    </button>
                                    <button
                                        className={activeTab === "creativedesign" ? "active" : ""}
                                        onClick={() => setActiveTab("creativedesign")}
                                    >
                                        Creative Design
                                    </button>
                                    <button
                                        className={activeTab === "themesprompts" ? "active" : ""}
                                        onClick={() => setActiveTab("themesprompts")}
                                    >
                                        Themes & Prompts
                                    </button>
                                    <button
                                        className={activeTab === "participants" ? "active" : ""}
                                        onClick={() => setActiveTab("participants")}
                                    >
                                        Participants
                                    </button>
                                </div>
                            )}




                            {/* Conditional content rendering based on active tab and toggle state */}
                            {activeTab === "basicdetails" && expanded && (
                                <div className="tab-content">
                                    <div className="details-row">
                                        <div className="fields">
                                            <TargetLocationIcon />
                                            <div className="details-right">
                                                <div><strong>Target Location</strong></div>
                                                <div>{commissionToDisplay.target_location}</div>
                                            </div>
                                        </div>
                                        <div className="fields">
                                            <FileIcon />
                                            <div className="details-right">
                                                <div><strong>Links And Files</strong></div>
                                                <div>{commissionToDisplay.links}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="details-row">
                                        <div className="fields">
                                            <TimelineIcon />
                                            <div className="details-right">
                                                <div><strong>Timeline</strong></div>
                                                <div>
                                                    {moment(commissionToDisplay.start_date).format('MMMM DD, YYYY')} - {moment(commissionToDisplay.end_date).format('MMMM DD, YYYY')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="fields overflow-y-auto no-scrollbar">
                                            <div className="details-right flex flex-row gap-2">
                                                {commissionToDisplay?.images?.map((item) => {
                                                    return (
                                                        <div className="div relative" key={item.key}>
                                                            {/* {commissionToDisplay.media_type === 'video' && <PlayIcon center={true} />}
                                                            <CustomImage src={item.thumbnailUrl || item.url} alt={item.key} imageContainerClass="thump-image" imageClass="w-full h-full object-cover" />
                                                             */}

                                                            <CommissionMedia
                                                                mediaData={item}
                                                                width={"103px"}
                                                                imageContainerClass="thump-image"
                                                                imageClass="w-full h-full object-cover"
                                                            />

                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="details-row">
                                        <div className="fields">
                                            <MediaTypeIcon />
                                            <div className="details-right">
                                                <div><strong>Media Type</strong></div>
                                                <div style={{ whiteSpace: "pre-wrap" }}>
                                                    {commissionToDisplay.media_type?.replace(/,/g, ",\n")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {activeTab === "goals" && expanded && (
                                <div className="tab-content">
                                    <p><strong>Content Usage:</strong> {commissionToDisplay.content_usage}</p>
                                    <p><strong>Target Audience:</strong> {commissionToDisplay.target_audience}</p>
                                    <p><strong>Tags:</strong> {commissionToDisplay.tags}</p>
                                    <p><strong>Campaign Goal:</strong> {commissionToDisplay.campaign_goal}</p>
                                </div>
                            )}
                            {activeTab === "format" && expanded && (
                                <div className="tab-content">
                                    <p>
                                        <strong>Media Type:</strong>{" "}
                                        {commissionToDisplay.media_type?.replace(/,/g, ",\n")}
                                    </p>
                                    <p>
                                        <strong>Files:</strong>
                                        <div className="details-right flex flex-row gap-2 max-w-[70%] flex-wrap">
                                            {
                                                commissionToDisplay?.images?.map((item) => {
                                                    return (
                                                        <div className="div relative">
                                                            {/* <PlayIcon center={true} />
                                                            <CustomImage src={item.thumbnailUrl || item.url} alt={item.key} imageContainerClass="thump-image" imageClass="w-full h-full object-cover" />
                                                             */}

                                                            <CommissionMedia
                                                                mediaData={item}
                                                                width={"103px"}
                                                                imageContainerClass="thump-image"
                                                                imageClass="w-full h-full object-cover"
                                                            />

                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </p>
                                </div>
                            )}
                            {activeTab === "creativedesign" && (
                                <div className="tab-content">
                                    <p>
                                        <strong>Creative Design:</strong>{" "}
                                        {commissionToDisplay.creative_design}
                                    </p>
                                </div>
                            )}
                            {activeTab === "themesprompts" && (
                                <div className="tab-content">
                                    <p>
                                        <strong>Themes Prompts:</strong>{" "}
                                        {commissionToDisplay.themes_prompts}
                                    </p>
                                </div>
                            )}

                            {activeTab === "participants" && (
                                // Show participants directly without the toggle
                                <ParticipantsTable participants={participants} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SchoolTabView;

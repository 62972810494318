import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import peopleImage from '../../../images/my-work/creatorWelcomGroupImage.svg';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import Search from '../../../components/searchGroup/Search';
import MediaGridCard from '../../../components/cards/MediaGridCard';
import LoadingWrapper from '../../../components/loadingWrapper/LoadingWrapper';
import useDebounce from '../../../utils/debounce';
import ProfileCompleteAlert from '../../Creator/my-work/show-creator-content/ProfileCompleteAlert';
import NoResultScreen from '../../../components/no-result/NoResultScreen';
import OutllinedPrimaryBtn from '../../../components/buttons/OutllinedPrimaryBtn';
import no_result_Lscreen_icon_2 from '../../../components/no-result/no-result-iscreen-icon-2.svg'
import { getAllSchoolCollections } from '../../../services/schoolServices';
import { getLocalStorage, setLocalStorage } from '../../../lib/session';
import moment from 'moment';
import { getMimeTypeFromFileName } from '../../../components/cards/helper';


export const WelcomeScreen = ({ onUploadClick }) => (
    <NoResultScreen
        btn={<OutllinedPrimaryBtn onClick={onUploadClick} label='Upload work' className='px-4' />}
        heading='My Gallery'
        text1='You haven’t uploaded any content yet'
        text2='Upload your existing student work to our Swiirl gallery for brands to view and license'
        img={no_result_Lscreen_icon_2}
    />
);

const WorkScreen = ({ searchText, setSearchText, collections, setCollections }) => {
    const navigate = useNavigate()
    const { isNew } = useSelector(state => state.user);


    return (
        <>
            <div className='mt-4'>
                <h1 className='text-[30px] font-bold'>My collections</h1>
            </div>
            <div className='flex justify-end gap-3 mt-4'>
                <Search className='w-[391px]' value={searchText} onChange={(value) => setSearchText(value)} />
                <Link to='/school/gallery/upload' className='no-underline		'>
                    <PrimaryButton text="Upload more work" className='button-primary-sw' />
                </Link>
            </div>
            {/* {
                isNew &&
                <div className="py-8 pt-10">
                    <ProfileCompleteAlert creator_id={collections?.[0]?.creator_id} />
                </div>
            } */}
            <div className='pt-[50px]'>
                {
                    collections && collections.length > 0 ?
                        <div className='card-container mx-0 px-0 grid grid-cols-3 '>
                            {
                                [...collections].map((card, index) => (
                                    <div key={card?.name} className='position-relative'>
                                        <MediaGridCard
                                        containerClass="h-[300px] w-full"
                                        mediaCount={card.image_count + card.video_count}
                                        mediaItems={card.images}
                                        onCardClick={() => {
                                            navigate(`/school/gallery/${card?.school_collection_id || ""}`)
                                        }}
                                        >
                                            <div>
                                                <h3 className="text-[20px] font-[600] text-primary-black mt-3">{card.name}</h3>
                                                <p className="font-[400] text-[#475467] mb-0">
                                                    {`${card.image_count || 'No'} ${card.image_count > 1 ? "images" : "image"} and ${card.video_count || 'No'} ${card.video_count > 1 ? "videos" : "video"} last uploaded ${moment((card?.last_published || card?.updated_at))?.format('MMMM DD, YYYY')}` || card.campaign_goal}
                                                </p>
                                            </div>
                                        </MediaGridCard>
                                        <div className={`min-w-[100px]  p-3 py-2 text-center font-[500]  rounded-pill  position-absolute top-4 right-4  border-[1px] ${card?.isPublished ? "publish-btn" : "unpublish-btn"}`}>
                                            {card?.isPublished ? " Published" : "Unpublished"}
                                        </div>

                                    </div>
                                ))
                            }
                        </div>
                        : <h3 className=' flex justify-center text-center col-12 mt-5'>
                            <span className='text-2xl text-bold  '>
                                No Collection
                            </span>
                        </h3>
                }
            </div>

        </>
    )
};

const GalleryCollectionPreviewForSchool = () => {
    const LIMIT = 6
    const navigate = useNavigate()
    const [startScreen, setStartScreen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { schoolId, userId } = useSelector(state => state.user);
    const [searchText, setSearchText] = useState('')
    const [collections, setCollections] = useState([])
    const [offset, setOffset] = useState(0)
    const [fetchMore, setFetchMore] = useState(true)
    const [showStartScreen, setShowStartScreen] = useState(true)
    const {isNew} = useSelector(state=>state.user)


    const debounceSearchText = useDebounce(searchText, 500);

    const fetchCollection = async ({ limit, offset, school_id, searchText, pageChange=false }) => {

        try {
            setIsLoading(true)
            const collectionData = await getAllSchoolCollections({ limit, offset, school_id, searchText })// getCollectionById('01J4RJH225DDVFB1AP9SZNKMGN')
            collectionData.data.forEach(item => {
                item.images = item.images.map(elm => ({
                    ...elm,
                    mediaType: getMimeTypeFromFileName(elm.filename)
                }));
            });
            setStartScreen(collectionData?.data.length === 0);
            setOffset(prev => prev + LIMIT)
            setFetchMore(!((collectionData?.data.length < LIMIT) || (collectionData?.data.length === 0)));
            let data = [...collectionData.data]
            if(pageChange) {
                data = [...collections,...data]
            }
            // if (data?.length > 0 && showStartScreen) {
            //     setLocalStorage("isNew", 0)
            //     setShowStartScreen(false)
            // }
            setCollections(data)
            setIsLoading(false);
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }

    }

    useEffect(() => {
        const offset = 0
        setOffset(0)
        fetchCollection({ limit: LIMIT, offset, school_id: schoolId, searchText: debounceSearchText })
    }, [debounceSearchText, userId])

    async function handleLoadMore() {
        if (!fetchMore) return
        fetchCollection({ limit: LIMIT, offset, school_id: schoolId, searchText: debounceSearchText, pageChange:true })
    }

    return (
        <div className='grid-container'>
            <div className='col-lg-12 mb-5'>
                <div className='row align-items-center w-full custom-max-width'>
                    <LoadingWrapper loading={isLoading}>
                        {
                            (collections.length === 0 && !searchText.length) ?
                                <WelcomeScreen onUploadClick={() => navigate('/school/gallery/upload')} /> :
                                <WorkScreen searchText={searchText} setSearchText={setSearchText} collections={collections} setCollections={setCollections} />
                        }
                    </LoadingWrapper>
                </div>
            </div>
            {!isLoading && fetchMore && <div className="w-full flex justify-center">
                {
                    collections.length > 0 ? 
                    <OutllinedPrimaryBtn onClick={handleLoadMore} style={{ width: '100px' }} disabled={!fetchMore} label='Load more' />
                    : null
                }
            </div>}
        </div>
    );
};

export default GalleryCollectionPreviewForSchool;
import { useEffect, useState } from "react";
import CheckMark from "../../icons/CheckMark";
import Tooltip from "../Tooltip/Tooltip";

const CreatorGalleryCardActions = ({ handleClick = () => null, data, setSelectedImages = () => null, selectedImages = [], isVideo = false, showDownLable = true }) => {
    const [checked, setChecked] = useState(false)
    const handleChecked = () => {
        if (data?.isLicenced) return

        if (!selectedImages.includes(data.gallery_content_id)) {
            setSelectedImages([...selectedImages, data.gallery_content_id])
        }
        else {
            const filterd = selectedImages.filter((obj) => obj !== data.gallery_content_id)
            setSelectedImages(filterd)
        }
    }
    useEffect(() => {
        if (selectedImages.includes(data.gallery_content_id)) setChecked(true)
        else setChecked(false)
    }, [selectedImages])
    return (
        <div onClick={handleClick} className={`hidden group-hover:flex absolute w-full ${isVideo ? "h-[100px]" : "h-full"} px-[19px] py-[15px] left-0 top-0 ${isVideo ? "flex-row" : "flex-col"} justify-between items-start`} >

            <Tooltip text={data?.isLicenced ? "Can't select licenced image!" : ""} position={'right'}>

                <span onClick={handleChecked} className={`${checked ? 'bg-violet' : 'bg-white'} rounded w-[20px] h-[20px] flex justify-center items-center`} >
                    <CheckMark color={checked ? 'white' : "#D0D5DD"} />
                </span>
            </Tooltip>
            {showDownLable && <span className="px-[10px] rounded-full text-[14px] font-[500] bg-white text-black-text " >{data?.isLicenced ? 'Licensed' : !data?.isShow ? <span className="text-[#B54708]">Hidden from store</span> : null}</span>}        </div>
    )
}

export default CreatorGalleryCardActions;
import React from "react";

const AnalyticIcon = () => {
  return (
    <svg
      className="bx bx-pie-chart-alt icon"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.284 2.3645C14.6575 2.3645 16.0175 2.63502 17.2864 3.16062C18.5553 3.68621 19.7082 4.45658 20.6794 5.42775C21.6505 6.39892 22.4209 7.55187 22.9465 8.82077C23.4721 10.0897 23.7426 11.4497 23.7426 12.8231M13.284 2.3645V12.8231M13.284 2.3645C7.50791 2.3645 2.82544 7.04697 2.82544 12.8231C2.82544 18.5992 7.50791 23.2817 13.284 23.2817C19.0601 23.2817 23.7426 18.5992 23.7426 12.8231M13.284 2.3645C19.0601 2.3645 23.7426 7.04698 23.7426 12.8231M23.7426 12.8231L13.284 12.8231M23.7426 12.8231C23.7426 14.4736 23.352 16.1006 22.6027 17.5712C21.8534 19.0418 20.7667 20.3141 19.4314 21.2843L13.284 12.8231"
        stroke="#667085"
        stroke-width="2.09172"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default AnalyticIcon;

export function validateQuestion(message) {
    const regex = /^(?!\s*$).+/;

    // Trim whitespace from both ends
    const trimmedMessage = message.trimStart();
    // Check against the regex
    return {
        data:trimmedMessage,
        error:!regex.test(trimmedMessage)
    };
}
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import InputField from '../../../components/form-elements/InputField';

function AdminLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); 
  const navigate = useNavigate(); 

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleLogin = () => {
    
    if (email && password) {
      navigate('/adminRoutes');
      window.location.reload(); 
    } else {
      alert("Please enter valid email and password.");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className='w-full min-h-screen position-relative '>
      <div className="flex flex-col sm:py-8 sm:px-12 w-full position-absolute">
        <img alt="swiirl-logo" className="swiirl-logo cursor-pointer" src="/Login/swiirl-logo.png" />
      </div>
      <div className="loginform">
        <div className="login-form-container">
          <div className="w-full flex items-center flex-col">
            <div className="mt-28s flex flex-col gap-4 w-[400px] items-center">
              <div className="flex flex-col items-center pr-3">
                <div className="flex flex-row gap-2 items-center abs">
                  <p className="font-bold text-3xl flex flex-row items-center gap-3 pr-2">
                    <button>
                      <img src="/static/media/left-arrow-icon.1436a2b34a819c339517ac53858c2da1.svg" alt="left-arrow-icon" className="my-auto" />
                    </button>Admin Portal
                  </p>
                </div>
                <p className="text-slate-500 text-[16px] -mt-2 pl-6">To get started, please sign in</p>
              </div>
              <div className="flex flex-col gap-2 w-full mt-2">
                <div className="relative w-full">
                  <InputField name="email" labelClass='mb-2' label="Email*" placeholder='Enter your work email' type='email' value={email} onChange={handleEmailChange} />
                </div>
              </div>
              <div className="flex flex-col gap-2 w-full mt-2">
                <div className="relative w-full">
                  <InputField value={password} onChange={handlePasswordChange} name="password" label="Password*" placeholder='✷✷✷✷✷✷✷✷✷✷'  type={showPassword ? 'text' : 'password'} />
                  <button type="button" className="absolute inset-y-0 right-0 top-8 flex items-center px-2" tabIndex="-1" onClick={togglePasswordVisibility}>
                    {showPassword ? (
                      <svg className="" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.5 3.75C13.22 3.75 15.94 7.02 16.74 8.17C17 8.53 17 9.47 16.74 9.83C15.94 10.98 13.22 14.25 9.5 14.25C5.78 14.25 3.06 10.98 2.26 9.83C2 9.47 2 8.53 2.26 8.17C3.06 7.02 5.78 3.75 9.5 3.75ZM9.5 12.5C10.99 12.5 12.25 11.24 12.25 9.75C12.25 8.26 10.99 7 9.5 7C8.01 7 6.75 8.26 6.75 9.75C6.75 11.24 8.01 12.5 9.5 12.5ZM9.5 11.25C8.53 11.25 7.75 10.47 7.75 9.5C7.75 8.53 8.53 7.75 9.5 7.75C10.47 7.75 11.25 8.53 11.25 9.5C11.25 10.47 10.47 11.25 9.5 11.25Z" fill="#98A2B3"/>
                      </svg>
                    ) : (
                      <svg className="" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.46 8.77c.03-.14.1-.23.21-.41C2.22 7.47 3.44 5.78 5.25 4.55m11.24 8.56a9.579 9.579 0 01-6.85 2.68c-4.25 0-7.05-3.75-8-5.23a1.73 1.73 0 01-.21-.41m16.17-1.09a1.64 1.64 0 01-.21.41c-.94 1.47-3.74 5.23-8 5.23a9.576 9.576 0 01-6.85-2.68" 
                        stroke="#98A2B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    )}
                  </button>
                </div>
                <div className="flex justify-between items-center">
                  <a href="/">
                    <p className="text-slate-600">Forgot your password?</p>
                  </a>
                </div>
                <button type="button" className="mt-top button-primary-sw-100 w-full btn-center font-bold false" onClick={handleLogin}>
                  Log in
                </button>
              </div>
            </div>
          </div>
        </div>   
      </div>
    </div>
  );
}

export default AdminLogin;

import React from "react";

const SupportIcon = () => {
  return (
    <svg
      className="bx bx-pie-chart-alt icon"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.74331 9.94035L5.34303 5.54008M5.34303 20.3308L9.77647 15.8973M15.7306 15.9305L20.1309 20.3308M20.1309 5.54008L15.6968 9.97417M23.197 12.9354C23.197 18.7115 18.5145 23.394 12.7384 23.394C6.96225 23.394 2.27979 18.7115 2.27979 12.9354C2.27979 7.15928 6.96225 2.47681 12.7384 2.47681C18.5145 2.47681 23.197 7.15928 23.197 12.9354ZM16.9218 12.9354C16.9218 15.2458 15.0488 17.1188 12.7384 17.1188C10.4279 17.1188 8.55494 15.2458 8.55494 12.9354C8.55494 10.6249 10.4279 8.75196 12.7384 8.75196C15.0488 8.75196 16.9218 10.6249 16.9218 12.9354Z"
        stroke="#667085"
        stroke-width="2.09172"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SupportIcon;

export function formatName (firstName, lastName) {
    let name = ''

    if(firstName) {
        name += firstName
    }

    if(firstName && lastName) {
        name += ' '+lastName
    }

    return name?.length ? name : null

}  
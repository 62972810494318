import { useState } from "react"
import AiIcon from "../../icons/Ai"
import DownloadIcon from "../../icons/Download"
import HeartIcon from "../../icons/Heart"
import { saveFavorites } from "../../services/brandServices"
import { useSelector } from 'react-redux'
import { downloadImageUtil } from "../../utils/downloadImage"
import PurchasePopupContent from "../popups/PurchasePopupContent"
import Spinner from "react-bootstrap/esm/Spinner"

const ImageCardActions = ({ onAiClick = () => null, cardData = {}, downLoadImage = false, onPurchaseSuccess = () => null, type }) => {
    const [isFavorite, setIsFavorite] = useState(cardData.isFavorite || false)
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [addingToFavourite, setAddingToFavourite] = useState(false); //adding to favourite loader state
    const { userId, brandId } = useSelector((state) => state.user);
    const [itemPurchased, setItemPurchased] = useState(false)

    const togglePopup = () => {
        if (itemPurchased) onPurchaseSuccess()
        setIsOpen(!isOpen);
    };
    const onPurchase = () => {
        setItemPurchased(true)
    }
    const handleFavoritesClick = async () => {
        try {
            if (addingToFavourite) return
            setAddingToFavourite(true)
            const payload = {
                brand_id: brandId,
                // gallery_content_id: cardData?.id,
                // content_id: cardData?.id,
                content_key: cardData?.content_key || cardData?.s3_key, //from galleries it will be s3_key because the cardData is from tb_gallery_contents.
            }
            if (cardData?.s3_key) {
                payload.gallery_content_id = cardData?.id
                payload.type = 'gallery'
            } else if (cardData?.content_key) {
                payload.content_id = cardData?.id
                payload.type = 'commission'
            }
            const response = await saveFavorites(payload)

            if (response?.message?.includes('added')) {
                setIsFavorite(true)
            }
            else {
                setIsFavorite(false)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setAddingToFavourite(false)
        }
    }

    const handleAiClick = () => {
        onAiClick()
    }

    const handleDownloadClick = async () => {
        downLoadImage ? downLoad() : togglePopup();
        // if downLoadImage ?  here we directly download the image. : Open the purchase modal 
    }

    const downLoad = async () => {
        let payload = {};
        if(cardData?.media_type==='video') {
          payload = { imageUrl: cardData?.url, imageName: `${cardData?.id}.mp4`, setLoading }
        } else {
          payload = { imageUrl: cardData?.url, imageName: `${cardData?.id}.jpg`, setLoading }
        }
        await downloadImageUtil({ ...payload })
    }

    const ActionButton = ({ onClick, children, additionalClasses = "", isLoading = false }) => (
        <div
          onClick={onClick}
          className={`bg-white rounded-[8px] border-[1px] w-[44px] h-[44px] flex items-center justify-center border-[#D6BBFB] ${additionalClasses} ${isLoading ? 'cursor-progress' : 'cursor-pointer'}`}
        >
          {children}
        </div>
      );
      
      return (
        <>
          {/* <div className="text-gray-500 hover:text-blue-500 hidden group-hover:flex absolute top-[1rem] right-[1rem] gap-1">
            <ActionButton onClick={handleFavoritesClick} additionalClasses={addingToFavourite ? 'cursor-progress' : ''}>
              <HeartIcon isFavorite={isFavorite} />
            </ActionButton>
            <ActionButton onClick={handleAiClick}>
              <AiIcon />
            </ActionButton>
          </div> */}
      
          <div className="text-gray-500  hover:text-blue-500 hidden  group-hover:flex flex-row absolute bottom-[1rem] right-[1rem] gap-1">
            <ActionButton onClick={handleDownloadClick} isLoading={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : <DownloadIcon />}
            </ActionButton>
            <ActionButton onClick={handleFavoritesClick} additionalClasses={addingToFavourite ? 'cursor-progress' : ''}>
              <HeartIcon isFavorite={isFavorite} />
            </ActionButton>
          </div>
      
          <PurchasePopupContent isOpen={isOpen} togglePopup={togglePopup} onPurchase={onPurchase} contentData={cardData} />
        </>
      );
      
}

export default ImageCardActions;
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ImageDetails.css";
import FacebookIcon from '../../../icons/Facebook.jsx';
import InstagramIcon from '../../../icons/Instagram.jsx';
import TwitterIcon from '../../../icons/Twitter.jsx';
import LinkedinIcon from '../../../icons/Linkedin.jsx'
import useAsticaNLPAPI from "../../../services/fetch_asticaGPT.js";
import GroupNftCard from "../../../components/cards/GroupNftCard.jsx";
import PurchasePopup from "../../../components/popups/PurchasePopup.js";
import ChatSection from "../../../components/chats/ChatSection.jsx";
import { useDispatch, useSelector } from "react-redux";

import Tooltip from "../../../components/Tooltip/Tooltip.js";
import CustomImage from "../../../components/CustomImage/CustomImage.jsx";
import LocationIcon from "../../../icons/LocationIcon.jsx";
import AccountRoundedIcon from "../../../icons/AccountRoundedIcon.jsx";
import { galleryDataFilterPurchased, galleryDataSuccess } from "../../../redux/actions.js";
import { generateAiData } from "../../../services/brandServices.js";
import VideoPlayer from "../../../components/video/player/VideoPlayer.jsx";
import MultiSelectDropDown from "../../../components/dropDown/MultiSelectDropdown.jsx";
import MessageInfoCard from "../../../components/MessageInfoCard.jsx";
import InputField from "../../../components/form-elements/InputField.jsx";
import LeftArrowIcon from "../../../icons/LeftArrowIcon.jsx";
import PrimaryButton from "../../../components/buttons/PrimaryButton.jsx";


const getModeration = (moderate) => {
  // Implement your moderation logic here
  return moderate ? moderate : "Not Moderated";
};

function ImageDetail() {
  const location = useLocation();
  const { image: imgData, dataType, commissionname, hidePurchasButton } = location.state;
  const [showPurchaseButton, setShowPurchaseButton] = useState(!hidePurchasButton)
  const { imageData } = useSelector((state) => state.galleryData);


  const [aiRecipeType, setAiRecipeType] = useState('')


  const [image, setImage] = useState(imgData)


  // const [copied, setCopied] = useState(false);
  // const [chatprompt, setChatprompt] = useState("");
  const dispatch = useDispatch();
  const { nlpResult, nlpError, nlpLoading, callAsticaNLPAPI } = useAsticaNLPAPI();

  // const copyTags = () => {
  //   navigator.clipboard.writeText(image.tags.join(", ")).then(() => {
  //     setCopied(true);
  //     setTimeout(() => setCopied(false), 2000);
  //   });
  // };





  const genAiData = async (bool=false) => {
    if (!image) return
    try {

      // if (bool) {
      if ((!image?.caption || !image?.tags || !image?.description) && image?.id) {
        let payload;
        if (dataType == 'content') {
          payload = {
            content_id: image?.id,
            type: "content"
          }
        }
        else {
          payload = {
            gallery_content_id: image?.id,
            type: "gallery"
          }
        }

        const res = await generateAiData({ ...payload})
        setImage({ ...image, ...res?.data?.data, })

        if (dataType === 'content') {
          // do some content operations
        } else { // gallery operations
          const newData = imageData.map((item) => {
            if (image?.id === item?.id) {
              return { ...image, ...res?.data?.data }
            }
            return item
          })
          dispatch(galleryDataSuccess([...newData]))
        }                                                                            
      }
    } catch (error) {
      console.log(error)
    } 
  }


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' })
    let purchasedContentid = localStorage.getItem('purchasedContent');
    if (purchasedContentid === image.id) {
      setShowPurchaseButton(false)
    }
    setImage(imgData)
    genAiData()

  }, [imgData])

  const onCloseAfterPurchase = () => {
    setShowPurchaseButton(false)
    dispatch(galleryDataFilterPurchased(image)) // filter the purchased image from the list
  }
  // const handleProcessNLP = () => {
  //   if (chatprompt) {
  //     if (image && image.caption) {
  //       callAsticaNLPAPI(image.caption + " " + chatprompt);
  //     }
  //   }
  // };

  // const creator_code = image.filename? image.filename.split('-')[0] : 'Not available';

  const initAiChatOptions = [
    { value: "no creator name", label: "Creator Name" },
    { value: "no location", label: "Creator Location" },
    { value: "no bio ", label: "Creator Bio" },
    { value: "no inspiration", label: "Inspiration" },
    { value: "no captions", label: "Caption" },
    { value: "no tags", label: "Main Tags" },
    { value: "no description", label: "Description" },
    { value: "no object", label: "Object Detection" },

  ]

  const [aiChatOptions, setAiChatOptions] = useState(initAiChatOptions);
  const [aiChatOption, setAiChatOption] = useState([]);
  const [selectedAiRecipeFields, setSelectedAiRecipeFields] = useState([]);
  const [aiRecipeInput, setAiRecipeInput] = useState([]);

  const [refreshType, setRefreshType] = useState(false)



  useEffect(() => {
    setAiChatOptions([
      { value: image?.name || image?.school_name || "no name", name: "name", label: "Creator Name" },
      { value: image?.location || image?.school_location || "no location", name: "location", label: "Creator Location" },
      { value: image?.creator_bio || image.bio || image.school_creator_bio || "no bio", name: "creator_bio", label: "Creator Bio" },
      { value: image?.inspiration || image.school_inspiration || "no inspiration", name: "inspiration", label: "Inspiration" },
      { value: image?.caption || "no caption", name: "caption", label: "Caption" },
      { value: image?.tags || "no tags", name: "tags", label: "Main Tags" },
      { value: image?.description || "no description", name: "description", label: "Description" },
      { value: image?.object_detection || "no object ", name: "object_detection", label: "Object Detection" },

    ])
  }, [image])

  const handleAiChatOptionChange = (e) => {
    setAiChatOption(e.target.value);
    const result = e.data.reduce((acc, item) => {
      acc[item?.name] = item?.value;
      return acc;
    }, {});

    setSelectedAiRecipeFields(result)   //{name:"",inspiration:"".....}
  };

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/my-content');
  };


  const handleAircipeType = (type) => {
    setAiRecipeType(type || "")
    setRefreshType(!refreshType)
  }
  return (
    <div className="container-gallery-item custom-max-width">
      <div className="row">
        <button className="flex flex-row gap-2 items-center w-fit mb-5 mt-4" onClick={handleBackClick}>
          <LeftArrowIcon />
          <span className="text-violet text-lg font-semibold">Back to all content</span>
        </button>
        <div className="">
          <div className="text-[30px] font-semibold">Create your media</div>
          <div className="header-title-tagline">
            Use the power of swiirl AI to streamline your work
          </div>
        </div>
      </div>

      {/* <PrimaryButton text="genData" onClick={()=>genAiData(true)} /> */}

      <div className="row pt-[15px] gap-[28px]">
        <div className=" w-auto min-h-[500px]   min-w-[40%] " style={{ maxWidth: "47%", paddingRight: "0", paddingBottom: '0' }}>
          {/* <img src={image.url} alt='image-detail'/> */}

          {
            image.media_type === 'video' ?
              <VideoPlayer videoUrl={image.url} src={image.thumbnailUrl || image.thumbnail_url} cacheKey={image.thumbnailUrl || image.thumbnail_url} alt="image details page " imageContainerClass='max-w-[99%]  border-2 border-slate-300 rounded-sm py-4' imageClass='w-full h-full' />
              :
              <CustomImage src={image.url} cacheKey={image.s3_key} alt="image details page " imageContainerClass='max-w-[99%]' />
          }



        </div>
        <div className="p-0 flex-1">
          <div className="flex flex-col gap-4 ">
            <div className="flex gap-4">
              <div className="flex items-center gap-2">
                <span className="creator-icons " ><AccountRoundedIcon /></span>
                <span>{image?.alias || image?.school_name }</span>
              </div>
              <div className="flex items-center gap-2">
                <span className="creator-icons" ><LocationIcon /></span>
                <span>{image?.location || image?.school_location}</span>
              </div>
            </div>
            <div className="flex gap-4">
              <GroupNftCard title="Caption" className="w-[40%]" content={image.caption} alt="No captions available" />
              <GroupNftCard title="Main Tags" className="w-[60%]" content={image?.tags} alt="No tags available" />
            </div>
            {(image.media_type !== 'video') && <div className="flex gap-4">
              <GroupNftCard title="Color Data" className="w-[60%]" content={image.color_data} alt="No color data available" />
              <GroupNftCard title="Moderation" className="w-[40%]" content={image && getModeration(image.moderation)} alt="Moderation details not available" />
            </div>}
            <div className="flex gap-4">
              {(image.media_type !== 'video') ? <GroupNftCard title="Facial Recognition" className="w-1/3" content={
                image?.facial_recognition ||

                (
                  <div>None</div>
                )} alt="No faces detected" /> :
                <GroupNftCard title="Moderation" className="w-[40%]" content={image && getModeration(image.moderation)} alt="Moderation details not available" />

              }
              {/* //   image.faces && image.faces.length > 0 ? (
              //   <div>
              //     {image.face_recognition.map((face, index) => (
              //       <div key={index}>
              //         Gender: {face.gender} Age: {face.age}
              //       </div>
              //     ))}
              //   </div>
              // ) */}
              <GroupNftCard title="Known Personas" className="w-1/3" content={image.known_persona} alt="No known personas available" />
              <GroupNftCard title="Object Detection" className="w-1/3" content={

                // image.objects && image.objects.length > 0 ? (
                // <div>
                //   {image.object_detection.map((obj, index) => (
                //     <div key={index}>
                //       {obj.name}: {Math.round(obj.confidence * 100)}%
                //     </div>
                //   ))}
                // </div>
                // ) : 
                image?.object_detection ||
                (
                  <div>None</div>
                )} alt="No objects detected" />
            </div>
          </div>
        </div>
      </div>

      <div className="grid gap-4 mt-[28px] md:grid-cols-2 lg:grid-cols-3">
        <GroupNftCard title="Creator bio" className="w-full" content={image.creator_bio || image.bio || image.school_creator_bio} alt="Creator Bio is not available" />

        <GroupNftCard title="Inspiration" className="w-full" content={image.inspiration || image.school_inspiration} alt="Insipiration is not available" />
        <GroupNftCard title="Description" className="w-full" content={image?.description} alt="Description is not available" />
      </div>

      {
        (!commissionname && showPurchaseButton) &&
        <div className="flex justify-content-end mt-4">

          <PurchasePopup onCloseAfterPurchase={onCloseAfterPurchase} className=" " name="Purchase exclusive licence" contentData={image} />

        </div>
      }

      <div className="row">
        <div className="col-md-12">
          <div className="group-nft">
            <div className="prompt-container">
              <div className="mt-5">
                <div>
                  <p className="header-description place-self-start">swiirl AI co-pilot</p>
                  <div className="flex gap-4 justify-between mt-4">
                    <MessageInfoCard className="col-9" heading={'Build AI Recipes '} subHeading={'Customize your AI Co-Pilot post by entering your campaign goal, and select additional elements create authentic, personalized content.'} />
                    {/* <div className="flex col-3 justify-center">
                      <CrossIcon size={"30px"} />
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="flex justify-between  min-h-[55px] mt-5">
                <div className="place-self-center flex gap-5 col-11">
                  <div className="min-width-[200px] flex col-9 justify-between" >
                    <div className="col-6">
                      <label className="mb-2">Enter the goal or theme for your post / content </label>
                      <InputField type="text" className="col-10" value={aiRecipeInput} onChange={(e) => setAiRecipeInput(e.target.value)} placeholder="eg. Back to School, Earth Day, Etc." />
                    </div>
                    <div className="col-6">
                      <label className="mb-2">Choose additional elements to incorporate into your post </label>
                      <MultiSelectDropDown placeholder="Choose one or more" className={' col-10'} options={aiChatOptions} value={aiChatOption} onChange={(e) => handleAiChatOptionChange(e)} />
                    </div>
                  </div>
                  {/* <div className="px-3 py-2 custom-border-violet bg-[#F9F5FF] rounded-lg text-violet text-[16px] flex items-center">Free form</div> */}
                  <div className="flex justify-between gap-5 mt-3">

                    <Tooltip disable={true} text={'Coming soon'}>
                      <span onClick={() => handleAircipeType('facebook')} className="custom-border-violet h-[47px] w-[47px] flex items-center justify-center rounded-lg"><FacebookIcon className="h-[47px]" /></span>
                    </Tooltip>
                    <Tooltip disable={true} text={'Coming soon'}>
                      <span onClick={() => handleAircipeType('instagram')} className="custom-border-violet h-[47px] w-[47px] flex items-center justify-center rounded-lg"><InstagramIcon /></span>
                    </Tooltip>
                    <Tooltip disable={true} text={'Coming soon'}>
                      <span onClick={() => handleAircipeType('linkedin')} className="custom-border-violet h-[47px] w-[47px] flex items-center justify-center rounded-lg"><LinkedinIcon /></span>
                    </Tooltip>
                    <Tooltip disable={true} text={'Coming soon'}>
                      <span onClick={() => handleAircipeType('twitter')} className="custom-border-violet h-[47px] w-[47px] flex items-center justify-center rounded-lg"><TwitterIcon /></span>
                    </Tooltip>
                  </div>

                </div>

              </div>
              <div className="border mt-[30px] p-5">

                <ChatSection image={image?.thumbnailUrl || image.url} aiRecipeType={aiRecipeType} refresh={refreshType} aiRecipeInput={aiRecipeInput} selectedAiRecipeFields={selectedAiRecipeFields} />
                {/* <div>
                  <div>
                    {
                      [1,2,3,4].map((item,key)=>{
                      return (
                        <ChatBox text="adsfasd"userType={item%2==0?'AI':"You"}/>
                      )
                    })}
                  </div>
                </div>
                <ChatInput chatprompt={chatprompt} setChatprompt={setChatprompt} handleProcessNLP={handleProcessNLP} /> */}


                {nlpLoading && (
                  <img
                    src="/Loader/Loader.svg"
                    className="loader-middle"
                    alt="Loading"
                  />
                )}
                {nlpError && <div>Error: {nlpError}</div>}
                <div className="nlpdata">
                  {nlpResult && (
                    <div>
                      <div className="header-description">
                        Social Media Post [AI driven based on image]
                      </div>
                      {nlpResult.status === "error" ? (
                        <div className="error-message-nft">
                          {
                            "Apologies for the inconvenience as the service is currently unavailable. Please try again in a few minutes."
                          }
                        </div>
                      ) : (
                        <div className="tag attribute-values">
                          {nlpResult.output}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageDetail;



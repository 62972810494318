



import React, { useEffect, useState, useCallback, useMemo } from 'react';
import ChatInput from './ChatInput';
import ChatBox from './ChatBox';
import { chatAboutImage } from './OpenAi';
import LoaderIcon from '../../icons/Loader';
import { useDispatch, useSelector } from 'react-redux';

const YOUR_ROLE = "You";
const AI_ROLE = 'Swiirl AI';

const ChatSection = ({ image, refresh, aiRecipeType, selectedAiRecipeFields,aiRecipeInput }) => {
    const [chatprompt, setChatprompt] = useState("");
    const [chats, setChats] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { username } = useSelector((state) => state.user);


    // const aiRecipeChatPrompt = useMemo(() => {
    //     if (aiRecipeType) {
    //         return `Write a post for ${aiRecipeType}. ${generateAiTemplate(selectedAiRecipeFields || {})}`;
    //     }
    //     return '';
    // }, [aiRecipeType, selectedAiRecipeFields]);

    const aiRecipeChatPrompt = useMemo(() => {
        if (aiRecipeType) {
            return `Write a post for ${aiRecipeType}. ${generateAiTemplate(selectedAiRecipeFields || {},aiRecipeInput, username)}`;
        }
        return '';
    }, [aiRecipeType, selectedAiRecipeFields]);

    useEffect(() => {
        if (aiRecipeType && image) {
            handleChatMessage(image, aiRecipeChatPrompt);
        }
    }, [aiRecipeType, refresh]);

    const handleChatMessage = useCallback(async (image, chatprompt) => {
        if (!chatprompt.trim()) return;

        setIsLoading(true);
        setChats(prev => [...prev, { role: YOUR_ROLE, content: chatprompt }]);
        setChatprompt('');

        try {
            const response = await chatAboutImage(image, chatprompt);
            const aiResponse = response?.choices?.[0]?.message?.content || 'No response from AI';
            setChats(prev => [...prev, { role: AI_ROLE, content: aiResponse }]);
        } catch (error) {
            console.error("AI chat failed:", error);
        } finally {
            setIsLoading(false);
        }
    }, [chats]);

    return (
        <div>
            <div className='max-h-[500px] overflow-auto px-2 mb-2'>
                {chats.map((item, key) => (
                    <ChatBox key={key} content={item?.content} role={item?.role} />
                ))}
            </div>
            {/* {isLoading && <div className='flex w-[20px]'><LoaderIcon /></div>} */}
            {isLoading && <div className='flex w-[20px]'><span className='typing-loader'></span></div>}
            <ChatInput
                isLoading={isLoading}
                chatprompt={chatprompt}
                setChatprompt={setChatprompt}
                handleChatMessage={() => handleChatMessage(image, chatprompt + generateAiTemplate(selectedAiRecipeFields || {},aiRecipeInput, username))}
            />
        </div>
    );
};

export default ChatSection;

const generateAiTemplate = (fields,aiRecipeInput="", username = '') => {
    const {
        name, location, creator_bio, inspiration,
        caption, tags, description, object_detection
    } = fields;

    if (!(name || location || creator_bio || inspiration ||
        caption || tags || description || object_detection)) {
        return ""
    }

    let orgName = username.split('@')[1]
    orgName = orgName.split('.')[0]

    // Write a post from the perspective of the brand (${orgName}), featuring the creator's work.  if creator details are selected, the creator should be presented as a partner or collaborator, but not as part of the brand itself. Ensure the post highlights the elements selected (ie. creator's name, bio, location, and inspiration, etc. and free form text around objective of the post - ie relating to event, campaign, holiday ) while maintaining the brand's voice
     
    //  Organization name:
    const template = ` 
    Write a post from the perspective of the brand, featuring the creator's work. ${aiRecipeInput}. If any creator details are selected, ensure the creator is presented as a featured creator, while maintaining the distinction between the brand and the creator. Only include the selected elements in the post—if an element is not selected, do not reference or include it in the copy. The post should still maintain the brand's voice and focus on the free-form text objectives if any are entered, and only on selected elements if none are entered.
    Objective : free form text
    Organization name:${orgName}.

    ${name ? `Creator's name: ${name}.` : ''}
    ${location ? `Location of creator: ${location}.` : ''}
    ${creator_bio ? `Creator bio: ${creator_bio}.` : ''}
    ${inspiration ? `Inspiration: ${inspiration} used by the creator while drawing this image.` : ''}
    ${caption ? `Caption: ${caption}.` : ''}
    ${tags ? `Tags: ${tags}.` : ''}
    ${description ? `Description: ${description}.` : ''}
    ${object_detection ? `Object Detection data: ${object_detection}.` : ''}

    
    
    `.trim();
    // Example output: "🇺🇸✨ Happy 4th of July from Swiirl! Today, we celebrate freedom, unity, and the pursuit of happiness that inspires our journey..."

    return template;
};























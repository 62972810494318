import React from 'react';
import loaderImage from '../../icons/ImageLoader.svg'

function LoadingWrapper({ children, loading = false }) {
    return (
        loading ? <div className=" w-full min-h-[300px] flex justify-center items-center">
            <div className='' >
                <img
                    src={loaderImage}
                    alt="Loading"
                />
            </div>
        </div> : children
    )
}

export default LoadingWrapper
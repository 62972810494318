import React from 'react'

const DeleteRingIcon = () => {
  return (
    <svg width="336" height="336" viewBox="0 0 336 336" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_71_6932" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="336" height="336">
<rect width="336" height="336" fill="url(#paint0_radial_71_6932)"/>
</mask>
<g mask="url(#mask0_71_6932)">
<circle cx="168" cy="168" r="47.5" stroke="#EAECF0"/>
<circle cx="168" cy="168" r="47.5" stroke="#EAECF0"/>
<circle cx="168" cy="168" r="71.5" stroke="#EAECF0"/>
<circle cx="168" cy="168" r="95.5" stroke="#EAECF0"/>
<circle cx="168" cy="168" r="119.5" stroke="#EAECF0"/>
<circle cx="168" cy="168" r="143.5" stroke="#EAECF0"/>
<circle cx="168" cy="168" r="167.5" stroke="#EAECF0"/>
</g>
<path d="M145 167C145 153.745 155.745 143 169 143V143C182.255 143 193 153.745 193 167V167C193 180.255 182.255 191 169 191V191C155.745 191 145 180.255 145 167V167Z" fill="#FEE4E2"/>
<path d="M173 161V160.2C173 159.08 173 158.52 172.782 158.092C172.59 157.716 172.284 157.41 171.908 157.218C171.48 157 170.92 157 169.8 157H168.2C167.08 157 166.52 157 166.092 157.218C165.716 157.41 165.41 157.716 165.218 158.092C165 158.52 165 159.08 165 160.2V161M167 166.5V171.5M171 166.5V171.5M160 161H178M176 161V172.2C176 173.88 176 174.72 175.673 175.362C175.385 175.926 174.926 176.385 174.362 176.673C173.72 177 172.88 177 171.2 177H166.8C165.12 177 164.28 177 163.638 176.673C163.074 176.385 162.615 175.926 162.327 175.362C162 174.72 162 173.88 162 172.2V161" stroke="#D92D20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<radialGradient id="paint0_radial_71_6932" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(168 168) rotate(90) scale(168 168)">
<stop/>
<stop offset="1" stop-opacity="0"/>
</radialGradient>
</defs>
</svg>
  )
}

export default DeleteRingIcon
import React from 'react';
import './RadioButton.css';

const RadioButton = ({ name, value, checked, onChange, label, isDisabled = false }) => {
    return (
        <label className="radio-container">
            <input
                type="radio"
                name={name}
                value={value}
                checked={checked?true:false}
                onClick={onChange}
                onChange={onChange}
                disabled={isDisabled}
            />
          
                <div className="radio-container custom-radio "></div>
            
            <span className='lable-text w-[97%]'>
                <span>{label}</span>
                {isDisabled && <span className='ms-2 coming-soon'>{"(coming soon) "}</span>}
            </span>

        </label>
    );
};

export default RadioButton;

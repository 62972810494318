import React from 'react';
import ArtIcon from '../../icons/Art';
import HeartIcon from '../../icons/Heart';
import SelectDropDown from '../dropDown/SelectDropDown';
import Search from './Search';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import CamaraIcon from '../../icons/CamaraIcon';
import VideoIcon from '../../icons/VideoIcon';
import MusicIcon from '../../icons/MusicIcon';
import GridIcon from '../../icons/GridIcon';


function SearchGroup({className='',setSearchText,searchText,favFilterState,handleFilterChange,imageTypeFilter,handleImageTypeChange,searchLoading}) {
    
    return (
        <div className={`${className} flex flex-col gap-3 `}>
            <div className="flex justify-end gap-3">
                {/* <SelectDropDown
                    options={[
                        { value: 'all', label: 'All', icon: <GridIcon    /> },
                        { value: 'art', label: 'Art Only', icon: <ArtIcon /> },
                        { value: 'photo', label: 'Photo', icon: <CamaraIcon /> },
                        { value: 'video', label: 'Video', icon: <VideoIcon /> },
                        { value: 'audio', label: 'Audio', icon: <MusicIcon /> }
                    ]}
                    onChange={handleImageTypeChange}
                    value={imageTypeFilter}
                /> */}
                <SelectDropDown
                    options={[
                        { value: 'favorites', label: 'Favorites', icon: <HeartIcon /> },
                        { value: 'all', label: 'All', icon: <GridIcon    /> },
                    ]}
                    onChange={handleFilterChange}
                    value={favFilterState}
                />
            </div>
            <div className="flex justify-end gap-3">
                <Search loading={searchLoading} onChange={(text) => setSearchText(text)} value={searchText} className="w-[491px] box-control" />
                <SecondaryButton text="Clear" className='box-control' onClick={()=>setSearchText("")} />
                {/* <PrimaryButton text="swiirl AI search" /> */}
            </div>
        </div>
    )
}

export default SearchGroup
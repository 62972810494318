import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRangePicker } from 'react-date-range';
import { useEffect, useState } from 'react';

const DatePicker = ({ open = false, setOPen = null, setDateRange = null, onClose = () => null, selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
} }) => {

    const [count, setCount] = useState(0)
    function handleSelect(ranges) {
        selectionRange = ranges.range1
        if (selectionRange) setDateRange(selectionRange)
        setCount(count + 1)
    }

    function handleClose() {
        if (!open) return
        setOPen(false)
        onClose()
    }

    useEffect(() => {
        if (count % 2 === 0) handleClose()
    }, [count])

    return (
        <>
            {open ? <div className='flex z-2 absolute right-10 mt-10 border border-blue-500 shadow-lg bg-white'>
                < DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleSelect}

                />
                <div onClick={handleClose} className='text-end text-blue-500 px-3 pt-4 cursor-pointer'>OK</div>
            </div> : null}
        </>
    )

}

export default DatePicker;
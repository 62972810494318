import LinkedinIcon from "../icons/Linkedin"
import TwitterIcon from "../icons/Twitter"
import InstagramIcon from "../icons/Instagram"
import websiteIcon from '../icons/websiteIcon.svg'
import DribbbleIcon from "../icons/DribbbleIcon"
import YouTubeIcon from "../icons/YoutubeIcon"
import WebIcon from "../icons/WebIcon"

const icons = {
    linkedin: <LinkedinIcon/>,
    instagram: <InstagramIcon/>,
    youtube: <YouTubeIcon/>,
    dribbble: <DribbbleIcon/>,
    twitter: <TwitterIcon/>
}

export const getSocialIcons = (socailMediaName) => {
    if(icons[socailMediaName]) {
        return icons[socailMediaName]
    }
    else return <WebIcon/>
}
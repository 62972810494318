import React from 'react'

function VideoIcon() {
    return <>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 8.92868C22 8.32287 22 8.01996 21.8802 7.8797C21.7763 7.75799 21.6203 7.69341 21.4608 7.70596C21.2769 7.72044 21.0627 7.93462 20.6343 8.363L17 11.9973L20.6343 15.6316C21.0627 16.06 21.2769 16.2742 21.4608 16.2887C21.6203 16.3012 21.7763 16.2366 21.8802 16.1149C22 15.9747 22 15.6718 22 15.0659V8.92868Z" stroke="#6941C6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2 9.79732C2 8.11716 2 7.27708 2.32698 6.63534C2.6146 6.07086 3.07354 5.61191 3.63803 5.32429C4.27976 4.99731 5.11984 4.99731 6.8 4.99731H12.2C13.8802 4.99731 14.7202 4.99731 15.362 5.32429C15.9265 5.61191 16.3854 6.07086 16.673 6.63534C17 7.27708 17 8.11716 17 9.79731V14.1973C17 15.8775 17 16.7176 16.673 17.3593C16.3854 17.9238 15.9265 18.3827 15.362 18.6703C14.7202 18.9973 13.8802 18.9973 12.2 18.9973H6.8C5.11984 18.9973 4.27976 18.9973 3.63803 18.6703C3.07354 18.3827 2.6146 17.9238 2.32698 17.3593C2 16.7176 2 15.8775 2 14.1973V9.79732Z" stroke="#6941C6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    </>
}

export default VideoIcon
import React from 'react'

const ChatBox = ({ content, role }) => {
    return (
        <div className={` flex mb-4  ${role=="You"?"justify-end":"justify-start"}`}>
            <div  className={`max-w-[70%]  min-w-1/3  `}>
                <div>
                    {role}
                </div>
                <div className={`overflow-auto	w-full border-[1px] border-slate-300 border-[1px] border-slate-300 rounded-lg p-2 max-h-[450px] ${role=="You"?'bg-[#7F56D9] text-white':"bg-[#EAECF0]"}`}>
                <span>

                    {content}
                </span>
                </div>
            </div>
        </div>
    )
}

export default ChatBox
import React from 'react'

function DeleteIcon({ className, color="#7F56D9" }) {
    return (
        <svg className={className} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.51099 1.36731H13.511M1.51099 4.36731H19.511M17.511 4.36731L16.8097 14.8866C16.7045 16.4648 16.6519 17.254 16.311 17.8523C16.0109 18.3791 15.5582 18.8026 15.0126 19.067C14.393 19.3673 13.6021 19.3673 12.0203 19.3673H9.00164C7.41989 19.3673 6.62902 19.3673 6.00932 19.067C5.46374 18.8026 5.01107 18.3791 4.71097 17.8523C4.3701 17.254 4.31749 16.4648 4.21227 14.8866L3.51099 4.36731M8.51099 8.86731V13.8673M12.511 8.86731V13.8673"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>

    )
}

export default DeleteIcon
import React, { useEffect, useState } from 'react';
import PlayIcon from '../video/PlayIcon';
import CustomImage from './CustomImage';
import DownloadIcon from '../../icons/Download';
import LoadingWrapper from '../loadingWrapper/LoadingWrapper';
import { getFileMimeType, getIcon } from '../../pages/school/upload-project-content/helper';

const CommissionMedia = ({ mediaData,download=true,width,imageContainerClass='',imageClass='' }) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [mediaType, setMediaType] = useState('');

    // Fetch media type (image/video) based on URL
    const getMediaType = async (url) => {
        if (url) {
            const type = await getFileMimeType(url);
            setMediaType(type);
        }
    };

    useEffect(() => {
        getMediaType(mediaData?.url);
    }, [mediaData]);

    // Handle download functionality
    const handleDownload = async (downloadUrl) => {
        try {
            setIsDownloading(true);
            const response = await fetch(downloadUrl);
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = mediaData?.key || 'download'; // default name if not provided
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href); // Clean up
            setIsDownloading(false);
        } catch (error) {
            console.error("Failed to download the file:", error);
            setIsDownloading(false);
        }
    };

    return (
        <div className="relative group h-full w-full">
            {/* Download Icon Overlay - Visible on Hover */}
           {download&& <div
                className="absolute inset-0 z-[51] flex justify-center items-center hidden group-hover:flex bg-black bg-opacity-50"
                onClick={() => handleDownload(mediaData.url)}
            >
                <LoadingWrapper loading={isDownloading}>
                    <DownloadIcon stroke={'#fff'} className="text-white cursor-pointer w-10 h-10" />
                </LoadingWrapper>
            </div>
}
            {/* Play Icon if thumbnail is available */}
            {mediaData.thumbnailUrl && <PlayIcon center={true} />}

            {/* Image or Video Rendering */}
            <div className={`bg-slate-300 flex justify-center  ${width?`w-${width}`: "w-full h-full"}`}>

                {mediaType && (mediaType.includes('image') || mediaType.includes('video')) ?
                    <CustomImage
                        src={mediaData.thumbnailUrl || mediaData.url}
                        alt={mediaData.key}
                        imageClass={imageClass||"card-image"}
                        imageContainerClass={imageContainerClass||` h-full`}
                        // imageContainerClass="thump-image"
                        // imageClass="w-full h-full object-cover"
                    />

                    :
                    <div className={`${imageContainerClass||"h-full"}  flex justify-center h-[53px] icons-files`}>

                        {getIcon(mediaData?.file_name,mediaType)}
                    </div>

                }
            </div>
        </div>
    );
};

export default CommissionMedia;

import React from 'react'

function BoyWithCamaraIcon() {
    return (
        <svg width="363" height="154" viewBox="0 0 363 154" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1156_8629)">
                <path d="M47.1427 47.3828L40.1926 35.3216L59.0072 23.6152L68.5773 41.2443L61.45 50.9844L47.1427 47.3828Z" fill="#EB8E5C" />
                <path d="M61.4404 28.0997C61.4404 28.0997 56.8517 30.0276 54.476 32.5501C54.2077 32.8331 53.9347 33.1064 53.6473 33.3702C53.4126 33.61 53.1635 33.8258 52.9192 34.0368C51.6835 35.1302 50.3279 36.0558 48.8383 36.7464C46.2757 37.9357 43.3635 39.1826 42.3911 39.1346C40.5279 39.0435 35.8194 33.9505 35.8194 33.9505C35.8194 33.9505 7.83227 29.9508 5.39423 27.7448C3.95728 26.4548 3.95249 23.3951 4.19198 21.098C4.28299 20.2539 56.2195 13.8996 56.7655 15.1561C58.7102 19.5441 61.4404 28.1045 61.4404 28.1045V28.0997Z" fill="#20203D" />
                <path d="M53.6474 33.3728C53.4127 33.6126 53.1636 33.8284 52.9193 34.0394C50.8022 32.8549 48.3833 32.3897 45.9501 32.03C42.4679 31.5121 38.9713 31.0469 35.489 30.5481C34.8855 30.4666 35.0915 29.5362 35.6854 29.6274C39.637 30.1885 43.6222 30.6393 47.5595 31.3346C49.7245 31.7135 51.7985 32.2794 53.6474 33.3776V33.3728Z" fill="#0B051F" />
                <path d="M56.7654 15.1558L4.19189 21.0977C4.2829 20.2537 4.40744 19.5103 4.50324 19.002C4.58945 18.5512 4.9439 18.2011 5.39894 18.1435C11.5779 17.3043 53.9586 11.5782 55.3812 12.71C55.7212 12.9786 56.2146 13.8993 56.7654 15.1558Z" fill="#D9CABA" />
                <path d="M36.3033 30.6412C36.3033 30.6412 36.2745 30.6412 36.2601 30.6412L23.2844 28.9196C23.0258 28.886 22.8437 28.6462 22.8773 28.3872C22.9108 28.1283 23.1503 27.9412 23.4089 27.9796L36.3847 29.7013C36.6433 29.7348 36.8254 29.9746 36.7918 30.2336C36.7583 30.4782 36.5475 30.6556 36.3033 30.646V30.6412Z" fill="white" />
                <path d="M221.484 107.076C212.331 107.81 203.158 108.534 193.976 108.975C186.753 109.32 179.53 109.493 172.307 109.344C164.906 109.186 157.496 108.687 150.154 107.718C143.055 106.769 136.076 105.071 128.887 106.016C123.072 106.788 117.036 108.716 111.145 107.507C105.895 106.438 102.466 102.63 99.7834 98.223C87.2483 77.6733 78.7032 53.455 59.2563 38.4588C59.5294 38.2718 59.8024 38.0896 60.0754 37.9121C68.405 44.4295 75.0868 52.8603 80.7245 61.8187C88.0195 73.4339 93.4751 86.0802 100.617 97.7866C102.327 100.597 104.343 103.364 107.241 105.052C110.106 106.721 113.434 107.071 116.692 106.884C123.072 106.505 129.203 104.448 135.65 104.793C142.681 105.162 149.617 106.841 156.629 107.512C163.699 108.188 170.803 108.457 177.906 108.447C183.4 108.447 188.899 108.289 194.393 108.021C203.45 107.575 212.489 106.855 221.518 106.136C222.126 106.093 222.078 107.042 221.479 107.085L221.484 107.076Z" fill="#0B051F" />
                <path d="M117.022 87.6202C116.811 87.8984 116.682 88.0806 116.672 88.119C115.959 90.5504 115.427 93.0202 115.082 95.5331C114.991 96.1278 114.043 96.099 114.134 95.4948C114.412 93.4614 114.814 91.4664 115.317 89.4905C112.932 92.7708 111.092 96.4012 109.856 100.343C109.674 100.924 108.774 100.636 108.946 100.051C110.46 95.2742 112.831 90.9053 115.978 87.0879C116.112 87.3133 116.256 87.5339 116.399 87.7497C116.61 87.7113 116.811 87.6634 117.022 87.6202Z" fill="#0B051F" />
                <path d="M200.567 86.3051C203.043 83.1399 205.524 78.4737 201.889 77.1741C198.627 76.0087 194.493 75.8744 191.078 75.7162C168.642 74.6899 146.341 80.9531 124.652 86.0222L124.595 86.0365C122.569 86.5113 120.562 87.0532 118.531 87.528C117.951 87.6623 117.343 87.7966 116.763 87.6575C115.446 87.341 114.507 84.5834 113.952 83.49C113.018 81.6341 112.194 79.7254 111.375 77.8167C109.703 73.9274 108.055 69.9805 105.584 66.518C102.571 62.2978 98.4803 58.9648 94.2221 55.9962C92.4403 54.7589 90.6106 53.5552 89.1066 51.987C86.5105 49.263 86.6733 46.5439 86.0363 43.1101C85.1501 38.372 83.6126 30.8379 77.8552 29.8932C74.1622 29.2841 66.7044 33.4756 60.0704 37.9068C59.7974 38.0842 59.5244 38.2665 59.2513 38.4535C52.694 42.8895 47.1426 47.3831 47.1426 47.3831C47.1426 47.3831 74.8902 94.0646 82.6881 108.912C90.2657 123.342 102.753 125.366 112.227 125.735C121.702 126.105 185.469 128.598 185.469 128.598L193.976 108.974L194.393 108.01L203.618 86.7127C202.602 86.5785 201.582 86.4394 200.567 86.3003V86.3051Z" fill="#20203D" />
                <path d="M333.504 62.9005C333.504 62.9005 336.761 59.3421 339.75 56.9682C342.739 54.5943 345.508 53.6448 345.508 53.6448L338.242 51.4531L334.807 53.6448L333.504 62.8957V62.9005Z" fill="#2A2A4F" />
                <path d="M327.431 42.1558L325.697 40.4198L324.313 39.0242L323.355 38.0651L322.541 31.0729C322.541 31.0729 322.804 30.9962 323.249 30.9003C323.355 30.8715 323.46 30.8523 323.57 30.8331C323.762 30.7948 323.968 30.7564 324.198 30.7276C325.846 30.4926 328.37 30.4255 329.941 31.8115C330.195 32.0321 330.425 32.2862 330.616 32.5884H330.626C330.77 32.809 330.899 33.0536 331.004 33.3269C332.719 37.5999 327.431 42.151 327.431 42.151V42.1558Z" fill="#FD9963" />
                <path d="M313.406 53.7465C313.406 53.7465 307.007 43.7522 307.601 37.2876C308.195 30.823 307.246 26.4397 308.904 24.4782C310.561 22.5216 313.526 24.7756 313.406 29.2835C313.287 33.7915 313.406 53.7417 313.406 53.7417V53.7465Z" fill="#FD9963" />
                <path d="M325.922 39.9249C325.922 39.9249 325.846 40.1263 325.697 40.4189C325.252 41.3301 324.15 43.1716 322.54 43.1716C320.931 43.1716 314.69 37.8963 311.945 35.4937C311.672 35.2491 311.442 35.0381 311.246 34.8559C310.848 34.5058 310.609 34.2948 310.609 34.2948C310.609 34.2948 309.929 29.5038 311.926 28.631C312.42 28.4104 312.942 28.4104 313.464 28.5255C313.775 28.5927 314.082 28.6982 314.374 28.8372C315.586 29.3887 316.563 30.3575 316.563 30.3575C316.563 30.3575 319.293 27.8253 322.138 29.2449C322.421 29.3887 322.689 29.6046 322.924 29.8827C323.158 30.1465 323.379 30.4726 323.58 30.8323C324.15 31.8298 324.595 33.139 324.935 34.4482C325.644 37.1722 325.932 39.9249 325.932 39.9249H325.922Z" fill="#FD9963" />
                <path d="M314.369 28.8371C314.187 29.9545 313.904 31.0383 313.478 32.1318C313.052 33.206 312.635 34.4865 311.941 35.4936C311.759 35.7669 311.553 36.0259 311.323 36.2513C310.877 36.6685 310.211 36.0067 310.647 35.5751C310.868 35.3641 311.064 35.1291 311.237 34.8557C311.945 33.8007 312.391 32.3763 312.784 31.2973C313.114 30.3765 313.325 29.4653 313.459 28.5254C313.77 28.5925 314.077 28.698 314.369 28.8371Z" fill="#2A2A4F" />
                <path d="M330.616 32.5875C327.517 33.489 325.889 36.2322 324.313 39.0233C323.8 39.9153 323.307 40.8073 322.775 41.6513C322.454 42.1645 321.631 41.6897 321.961 41.1766C322.55 40.2558 323.154 39.3254 323.638 38.3471C325.142 35.3162 326.535 32.4724 329.941 31.8105C330.195 32.0311 330.425 32.2853 330.616 32.5875Z" fill="#2A2A4F" />
                <path d="M344.21 109.725L327.694 103.98L323.307 97.9374L322.617 96.9783L316.218 88.1781L316.625 62.6602L334.357 65.1204L334.367 65.1779L334.654 66.4296L338.625 84.4231L340.641 93.5781L344.21 109.725Z" fill="#FD9963" />
                <path d="M340.641 93.5788L340.186 94.2358L330.214 96.4371L325.965 96.0774L324.212 92.8691L325.664 87.8959C325.664 87.8959 334.146 84.9993 338.62 84.4238L340.641 93.5836V93.5788Z" fill="white" />
                <path d="M349.78 129.533C347.649 142.582 337.715 148.749 322.54 142.817C320.902 142.169 319.159 141.248 317.339 140.083C317.066 139.91 316.788 139.733 316.505 139.541C316.496 139.541 316.496 139.532 316.486 139.522C301.537 129.624 282.43 105.803 282.43 105.803L275.379 78.204L279.532 72.8808L283.455 67.8597L283.666 67.5959L284.15 66.9677L287.306 62.9249L287.344 62.877C287.344 62.877 295.195 64.565 304.358 77.6429C304.559 77.9163 304.746 78.204 304.947 78.4966C304.976 78.535 305.005 78.5829 305.033 78.6213C314.474 92.5912 318.57 94.4135 318.57 94.4135C318.57 94.4135 341.858 86.4527 345.235 87.9393C348.607 89.4308 345.833 94.8883 346.504 99.6313C347.184 104.374 351.926 116.479 349.795 129.528L349.78 129.533Z" fill="#D7BBFA" />
                <path d="M334.654 66.4284L332.355 70.3033C332.091 70.6917 331.574 70.9891 331.445 71.4351L322.21 100.727L321.429 99.9024L330.549 71.1521C330.712 70.6198 330.956 70.1163 331.268 69.6607L334.376 65.1719L334.659 66.4236L334.654 66.4284Z" fill="#2A2A4F" />
                <path d="M325.127 109.201C324.624 112.399 323.259 115.104 321.942 117.732C320.711 120.173 319.542 122.485 319.327 124.83C319.073 127.65 318.445 134.378 317.334 140.08C317.061 139.907 316.783 139.73 316.501 139.538C316.491 139.538 316.491 139.529 316.481 139.519C317.19 135.711 317.827 130.776 318.388 124.734C318.618 122.221 319.82 119.828 321.089 117.305C322.378 114.744 323.714 112.107 324.198 109.047C325.118 103.22 318.397 98.3478 318.34 98.2999C318.129 98.1272 318.096 97.8347 318.254 97.6237C318.417 97.4223 318.718 97.3887 318.919 97.5469C319.202 97.7675 326.143 102.832 325.132 109.196L325.127 109.201Z" fill="#2A2A4F" />
                <path d="M358.235 38.9551V56.2484L347.769 56.0038L345.221 55.9415L322.541 55.3996V39.8039L345.221 39.262L347.769 39.1997L358.235 38.9551Z" fill="#E8E6ED" />
                <path d="M318.55 24.4844H314.556V32.4692H318.55V24.4844Z" fill="#B1ACBF" />
                <path d="M323.393 32.4688H311.026C310.573 32.4688 310.207 32.8359 310.207 33.2888V61.9144C310.207 62.3673 310.573 62.7345 311.026 62.7345H323.393C323.845 62.7345 324.212 62.3673 324.212 61.9144V33.2888C324.212 32.8359 323.845 32.4688 323.393 32.4688Z" fill="#2A2A4F" />
                <path d="M321.19 1.22691V24.5149C321.19 25.0664 320.74 25.5076 320.189 25.5076H312.922C312.372 25.5076 311.921 25.0664 311.921 24.5149V1.22691C311.921 0.675406 312.372 0.224609 312.922 0.224609H320.189C320.74 0.224609 321.19 0.675406 321.19 1.22691Z" fill="#B1ACBF" />
                <path d="M321.19 1.22691V10.1421H311.921V1.22691C311.921 0.675406 312.372 0.224609 312.922 0.224609H320.189C320.74 0.224609 321.19 0.675406 321.19 1.22691Z" fill="#E8E6ED" />
                <path d="M318.464 0.224609H317.516V10.1421H318.464V0.224609Z" fill="#B1ACBF" />
                <path d="M361.578 57.9107L355.844 57.2489C355.437 57.201 355.126 56.8557 355.126 56.4432V38.7662C355.126 38.3538 355.433 38.0085 355.844 37.9606L361.578 37.2988C362.062 37.2412 362.483 37.6201 362.483 38.1044V57.105C362.483 57.5894 362.062 57.9683 361.578 57.9107Z" fill="#B1ACBF" />
                <path d="M347.769 39.1973V56.0014L345.22 55.9391V39.2596L347.769 39.1973Z" fill="#B1ACBF" />
                <path d="M331.004 69.966L322.9 95.297L320.74 102.035L317.08 113.468L305.704 100.184L305.11 94.2611L303.736 80.7564L306.245 76.5889L306.255 76.5793L316.917 58.9023L331.004 69.966Z" fill="#FD9963" />
                <path d="M322.9 95.2958L320.74 102.034C320.021 102.556 319.509 102.916 319.509 102.916L310.863 100.456L305.11 94.2599L303.736 80.7552L306.245 76.5877L306.255 76.5781C306.255 76.5781 317.353 86.582 320.859 92.1594C321.664 93.4398 322.339 94.4661 322.895 95.291L322.9 95.2958Z" fill="white" />
                <path d="M287.335 25.762C287.335 27.1192 286.238 28.2174 284.883 28.2174C283.527 28.2174 282.43 27.1192 282.43 25.762C282.43 24.4049 283.527 23.3066 284.883 23.3066C286.238 23.3066 287.335 24.4049 287.335 25.762Z" fill="#2A2A4F" />
                <path d="M260.622 51.4577C260.325 51.4577 260.042 51.2899 259.913 51.0021C259.736 50.6089 259.908 50.1485 260.301 49.971L278.1 42.226C278.488 42.0485 278.953 42.2212 279.13 42.6144C279.307 43.0077 279.135 43.4681 278.742 43.6455L260.943 51.3906C260.838 51.4385 260.727 51.4625 260.622 51.4625V51.4577Z" fill="#A85928" />
                <path d="M296.766 59.8122L283.728 73.3218C283.728 73.3218 262.902 72.0509 262.481 71.7536C262.05 71.4802 268.851 58.4263 268.851 58.4263L283.163 52.4316L296.766 59.8074V59.8122Z" fill="#FD9963" />
                <path d="M307.769 57.4467C307.409 59.9117 305.019 62.391 299.827 61.969C291.972 61.3264 287.733 61.0099 285.036 58.1085C280.318 53.025 278.095 37.6068 285.941 31.6841C293.797 25.7566 303.089 29.4829 305.661 34.1683C305.661 34.1683 306.063 36.6525 306.394 38.7098C306.552 39.6066 306.71 40.6617 306.883 41.8222C307.108 43.3665 307.338 45.1025 307.529 46.8961C307.721 48.6561 307.951 50.4737 308.051 52.2433C308.075 52.6174 308.094 52.9915 308.094 53.3655C308.128 54.7946 307.955 56.1614 307.764 57.4515L307.769 57.4467Z" fill="#FD9963" />
                <path d="M280.84 53.4041C280.84 53.4041 291.153 50.7856 292.063 48.9393C292.973 47.0929 292.096 40.9304 295.617 37.9379C299.137 34.9454 305.666 34.1637 305.666 34.1637C305.666 34.1637 304.454 27.488 297.83 27.138C287.929 26.62 277.866 26.5912 276.716 38.1969C275.902 46.4359 280.845 53.4041 280.845 53.4041H280.84Z" fill="#2A2A4F" />
                <path d="M306.183 43.166C306.183 43.166 309.799 46.2305 310.01 47.9905C310.365 50.9686 307.39 51.4578 307.39 51.4578L306.188 43.166H306.183Z" fill="#FD9963" />
                <path d="M307.151 44.3871C307.213 45.2503 306.792 45.9889 306.202 46.032C305.613 46.0752 305.086 45.4086 305.024 44.5453C304.962 43.6821 305.383 42.9436 305.973 42.9004C306.557 42.8573 307.089 43.5239 307.151 44.3871Z" fill="#0B051F" />
                <path d="M308.094 53.36C307.041 53.5087 305.987 53.3025 305.182 52.4392C304.67 51.9069 305.479 51.1012 305.977 51.6336C306.557 52.2474 307.304 52.3577 308.051 52.233C308.075 52.6071 308.094 52.9812 308.094 53.3552V53.36Z" fill="#0B051F" />
                <path d="M306.883 41.8205C306.634 41.7534 306.394 41.6862 306.159 41.6718C305.355 41.6143 304.492 42.1274 303.712 41.8541C303.156 41.6623 302.826 41.082 303.041 40.5161C303.281 39.8687 304.195 39.437 304.775 39.1781C305.297 38.9527 305.838 38.7944 306.394 38.7129C306.552 39.6097 306.71 40.6647 306.883 41.8253V41.8205Z" fill="#2A2A4F" />
                <path d="M290.204 53.3924C293.387 53.3924 295.967 50.6699 295.967 47.3114C295.967 43.953 293.387 41.2305 290.204 41.2305C287.022 41.2305 284.442 43.953 284.442 47.3114C284.442 50.6699 287.022 53.3924 290.204 53.3924Z" fill="#FD9963" />
                <path d="M291.124 48.1477C290.702 47.2845 290.674 46.3013 291.071 45.4237C291.344 44.8195 290.449 44.2871 290.176 44.8962C289.615 46.1383 289.625 47.4475 290.228 48.6704C290.525 49.2699 291.421 48.7423 291.124 48.1429V48.1477Z" fill="#0B051F" />
                <path d="M304.928 32.0285C304.928 32.0285 277.147 44.622 277.133 44.6268C277.133 44.6268 276.611 42.1187 276.587 41.9652C276.29 39.9079 276.227 37.7929 276.524 35.7308C277.339 30.0719 281.228 26.101 286.727 24.6719C291.129 23.5257 296.101 23.6168 300.033 26.1154C302.107 27.4294 304.267 29.5779 304.928 32.0285Z" fill="#A85928" />
                <path d="M44.0819 58.2839L36.6672 46.5008L55.0124 34.0703L65.2627 51.3109L58.5186 61.3243L44.0819 58.2839Z" fill="#FD9963" />
                <path d="M57.618 38.4573C57.618 38.4573 53.1059 40.5626 50.8308 43.1763C50.5769 43.4688 50.3087 43.7566 50.0356 44.0299C49.8105 44.2745 49.571 44.5047 49.3363 44.7205C48.1437 45.8571 46.8264 46.8354 45.3655 47.5884C42.8556 48.8784 39.9913 50.2356 39.0142 50.226C37.1461 50.2068 32.2461 45.3008 32.2461 45.3008C32.2461 45.3008 4.12482 42.3946 1.60056 40.2893C0.110917 39.0568 -0.00883008 35.9971 0.139656 33.6952C0.197134 32.8511 51.8462 24.473 52.445 25.7055C54.5573 30.0121 57.618 38.4621 57.618 38.4621V38.4573Z" fill="#2A2A4F" />
                <path d="M50.0356 44.032C49.8105 44.2766 49.571 44.5068 49.3363 44.7226C47.1761 43.6196 44.738 43.2503 42.2952 42.9866C38.7986 42.6077 35.2828 42.2768 31.7814 41.9123C31.1731 41.8548 31.3456 40.9148 31.9443 40.9819C35.9151 41.3896 39.9146 41.6821 43.8758 42.224C46.0552 42.5166 48.1484 43.0009 50.0356 44.0272V44.032Z" fill="#0B051F" />
                <path d="M52.4449 25.703L0.139648 33.6974C0.197127 32.8534 0.292924 32.1052 0.364772 31.5921C0.43183 31.1365 0.77191 30.7768 1.22695 30.7001C7.36755 29.6163 49.4944 22.2453 50.9601 23.3147C51.3097 23.5689 51.8414 24.4705 52.4402 25.7078L52.4449 25.703Z" fill="#F0D8BB" />
                <path d="M32.6006 41.9812C32.6006 41.9812 32.5718 41.9812 32.5575 41.9812L19.5243 40.7679C19.2656 40.7439 19.074 40.5138 19.098 40.25C19.1219 39.9862 19.3566 39.7944 19.6153 39.8232L32.6485 41.0365C32.9071 41.0605 33.0987 41.2907 33.0748 41.5544C33.0508 41.799 32.8449 41.986 32.6054 41.986L32.6006 41.9812Z" fill="white" />
                <path d="M206.358 82.5858L204.739 84.3554L204.351 85.3529L193.583 113.12L193.205 114.098L185.469 134.039H112.17C102.691 134.039 90.1315 132.5 81.9983 118.381C73.6304 103.85 44.0818 58.2908 44.0818 58.2908C44.0818 58.2908 49.456 53.5814 55.8361 48.896C56.0996 48.6945 56.3678 48.5075 56.6312 48.3157C63.088 43.6255 70.3733 39.1463 74.0903 39.6115C79.8812 40.3308 81.7109 47.8025 82.7839 52.4975C83.5503 55.9025 83.4928 58.6265 86.1943 61.2497C87.7605 62.7604 89.6334 63.8873 91.4631 65.0575C95.8315 67.8582 100.051 71.0282 103.227 75.1285C107.931 81.2095 110.033 88.9641 114.411 95.2753C114.555 95.4959 114.704 95.7117 114.857 95.9227C115.068 95.8747 115.264 95.8172 115.475 95.7692C132.934 91.4243 150.441 87.1418 167.732 82.1206C167.799 82.1015 167.876 82.0823 167.943 82.0631C168.245 81.9672 168.542 81.8904 168.834 81.8089C196.179 74.083 206.358 82.5906 206.358 82.5906V82.5858Z" fill="#2A2A4F" />
                <path d="M220.617 111.128C211.497 112.221 202.363 113.3 193.205 114.096C186.001 114.724 178.787 115.18 171.564 115.309C164.159 115.444 156.74 115.233 149.363 114.552C142.236 113.88 135.195 112.456 128.044 113.679C122.262 114.676 116.308 116.839 110.374 115.861C105.086 114.998 101.512 111.324 98.6577 107.027C85.3323 86.986 75.8532 63.1177 55.8315 48.8889C56.095 48.6875 56.3632 48.5004 56.6267 48.3086C65.2053 54.4951 72.2081 62.6574 78.1906 71.3903C85.9358 82.713 91.88 95.1339 99.472 106.552C101.292 109.296 103.414 111.981 106.374 113.554C109.301 115.113 112.639 115.329 115.892 115.017C122.253 114.389 128.297 112.092 134.754 112.187C141.795 112.283 148.793 113.689 155.825 114.087C162.914 114.485 170.022 114.475 177.125 114.192C182.614 113.971 188.104 113.602 193.578 113.118C202.612 112.322 211.617 111.248 220.612 110.178C221.221 110.111 221.211 111.06 220.612 111.128H220.617Z" fill="#0B051F" />
                <path d="M208.049 84.8116C206.818 84.9938 205.582 85.1809 204.351 85.3535C195.801 86.6052 187.213 87.7466 178.567 87.924C175.07 88.0007 171.703 88.0199 169.409 84.9842C168.729 84.0731 168.254 83.0947 167.938 82.0589C168.24 81.9629 168.537 81.8862 168.829 81.8047C169.198 83.0468 169.825 84.2073 170.822 85.1809C173.744 88.0871 179.429 87.0128 183.175 86.7922C190.398 86.375 197.583 85.3967 204.739 84.3512C205.764 84.1977 206.774 84.0491 207.799 83.8956C208.398 83.8093 208.652 84.7205 208.044 84.8068L208.049 84.8116Z" fill="#2A2A4F" />
                <path d="M196.553 82.1328C196.553 82.1328 175.056 106.039 174.27 113.473C173.48 120.906 188.17 132.915 188.17 132.915L206.645 95.2683L196.553 82.1328Z" fill="white" />
                <path d="M318.34 123.464C317.267 126.586 317.818 133.295 316.5 139.539C315.059 146.267 311.438 152.463 301.278 153.034C290.463 153.653 279.345 148.411 271.572 143.611C267.021 140.8 263.62 138.134 262.073 136.873C262.054 136.873 262.044 136.863 262.025 136.834C261.455 136.369 261.154 136.105 261.154 136.105H261.087C260.904 136.115 260.406 136.125 259.616 136.144C249.05 136.398 187.418 137.765 184.535 135.165C181.369 132.317 185.958 103.533 191.796 90.4022C197.645 77.2763 205.701 73.3199 209.969 73.3199C213.844 73.3199 233.248 66.6586 236.754 65.4453C237.104 65.3206 237.295 65.2535 237.295 65.2535C237.295 65.2535 237.827 64.8075 238.775 64.0881C242.909 60.9469 254.888 52.5592 265.005 52.7655C268.008 52.8326 270.57 53.4033 272.769 54.3864C272.817 54.396 272.865 54.4152 272.913 54.444C278.191 56.8562 281.376 61.6663 283.661 67.5986C284.327 69.3155 284.911 71.1378 285.453 73.0082C285.654 73.7083 285.85 74.4229 286.042 75.1422C286.07 75.2286 286.099 75.3149 286.118 75.3868C287.445 80.3312 288.619 85.8175 289.5 90.2871C289.51 90.3159 289.51 90.3351 289.519 90.3638C290.563 95.5816 291.196 99.4181 291.196 99.4181L300.191 86.0381L303.735 80.7628C303.735 80.7628 321.659 99.4229 323.556 105.278C325.453 111.134 320.562 116.98 318.344 123.473L318.34 123.464Z" fill="#D7BBFA" />
                <path d="M290.377 66.4336L289.682 67.153C290.731 70.5915 289.146 74.8117 285.884 76.706C285.357 77.0129 285.831 77.833 286.363 77.5261C290.013 75.4064 292.053 70.3949 290.377 66.4336Z" fill="#2A2A4F" />
                <path d="M283.197 124.666C283.197 124.666 283.153 124.666 283.134 124.666C282.876 124.633 282.694 124.393 282.727 124.134C284.14 113.507 290.702 99.364 290.769 99.2201C290.879 98.9803 291.167 98.8796 291.402 98.9899C291.636 99.1002 291.742 99.3832 291.631 99.623C291.564 99.762 285.065 113.78 283.671 124.259C283.637 124.498 283.436 124.671 283.201 124.671L283.197 124.666Z" fill="#2A2A4F" />
                <path d="M115.48 95.7644C115.278 96.0473 115.159 96.2392 115.149 96.2775C114.531 98.7377 114.095 101.222 113.851 103.744C113.784 104.344 112.836 104.354 112.903 103.744C113.104 101.706 113.425 99.6921 113.851 97.6971C111.595 101.064 109.899 104.766 108.817 108.751C108.654 109.341 107.744 109.082 107.897 108.497C109.224 103.668 111.423 99.2077 114.416 95.2656C114.56 95.4862 114.708 95.702 114.862 95.913C115.072 95.8651 115.269 95.8075 115.48 95.7596V95.7644Z" fill="#0B051F" />
                <path d="M338.644 39.4379L334.558 39.5147L334.036 39.5242L333.505 39.155L332.168 38.2438L330.156 50.0125L321.19 68.0443L316.213 60.5774C316.213 60.5774 320.395 47.2262 323.154 41.2747C325.913 35.3232 328.509 32.441 330.899 32.4026C333.184 32.3738 335.363 34.6422 336.834 36.6085C337.025 36.8722 337.212 37.1312 337.384 37.3758C338.17 38.5411 338.644 39.4427 338.644 39.4427V39.4379Z" fill="#FD9963" />
                <path d="M337.384 37.3708C336.407 38.0182 335.478 38.7472 334.558 39.5145L334.036 39.5241L333.504 39.1548C334.577 38.2436 335.674 37.3708 336.833 36.6035C337.025 36.8673 337.212 37.1262 337.384 37.3708Z" fill="#2A2A4F" />
                <path d="M338.534 46.8936C338.931 46.1983 339.329 45.4981 339.727 44.8027C340.455 43.5318 341.092 42.0739 341.959 40.8942C343.008 39.4699 345.996 39.4267 345.872 39.1965C344.013 35.7676 341.144 34.8756 338.352 36.6308C334.683 38.9328 331.282 42.5823 328.451 45.829C328.102 46.227 328.657 46.8025 329.021 46.4524C327.637 49.5409 326.248 52.6245 324.864 55.7129C323.46 58.8445 321.75 62.0385 321.18 65.4578C320.749 68.0427 321.065 70.8818 322.641 73.0446C324.222 75.2123 325.836 75.3274 327.872 73.7688C333.136 69.7356 334.735 63.5971 337.921 58.0868C339.851 54.7442 342.581 51.9963 345.201 49.2052C346.523 47.7952 347.86 46.3949 349.172 44.9754C349.833 44.2608 350.916 43.2873 350.777 42.1747C350.326 38.5683 342.49 43.8915 338.524 46.8936H338.534Z" fill="#FD9963" />
                <path d="M286.047 75.1246C286.047 75.1246 286.047 75.1438 286.037 75.1438C285.774 75.4555 285.515 75.7433 285.28 76.0166C284.107 77.3163 283.187 78.2083 283.187 78.2083L279.528 72.885L268.104 56.2487C268.104 56.2487 270.331 55.1936 272.87 54.4647C272.879 54.4551 272.889 54.4551 272.908 54.4455C273.286 54.34 273.674 54.2345 274.062 54.1434C274.062 54.1434 274.072 54.1338 274.072 54.1434C275.638 53.7741 277.209 53.6015 278.354 53.9228C280.533 54.5318 284.61 59.011 287.292 62.9291C288.724 65.0057 289.759 66.9335 289.917 68.0461C290.171 69.8493 287.991 72.8562 286.042 75.1246H286.047Z" fill="#D7BBFA" />
                <path d="M261.091 136.109C260.909 136.118 260.411 136.128 259.621 136.147C244.169 123.63 234.795 108.538 234.68 108.351C234.546 108.126 234.613 107.838 234.843 107.694C235.063 107.56 235.355 107.627 235.499 107.847C235.614 108.039 245.261 123.582 261.091 136.109Z" fill="#2A2A4F" />
                <path d="M304.938 78.498L300.191 86.0321L291.593 99.6711C291.507 99.8054 291.358 99.8917 291.196 99.8917C291.167 99.8917 291.148 99.8917 291.119 99.8821C290.927 99.8533 290.779 99.7095 290.741 99.532C290.702 99.3786 287.613 86.7754 285.28 76.0139C284.806 73.851 284.36 71.7697 283.982 69.8754C283.838 69.1896 283.671 68.5182 283.45 67.8611C281.458 61.7418 276.448 57.1188 272.874 54.4619C272.836 54.4332 272.807 54.4044 272.769 54.3852C272.817 54.3948 272.865 54.414 272.913 54.4428C273.291 54.3372 273.679 54.2317 274.067 54.1406H274.077C277.573 56.8358 282.085 61.1999 284.145 66.9691C284.466 67.842 284.725 68.7436 284.911 69.6835C285.251 71.3716 285.639 73.2132 286.047 75.1219C286.066 75.2082 286.085 75.3041 286.114 75.3856C287.196 80.3971 288.456 85.8547 289.495 90.2859C289.505 90.3147 289.505 90.3339 289.514 90.3626C290.338 93.8731 291.033 96.7409 291.392 98.2132L304.349 77.6492C304.55 77.9226 304.737 78.2103 304.938 78.5028V78.498Z" fill="#2A2A4F" />
                <path d="M289.946 132.258C289.759 132.258 289.577 132.143 289.505 131.956C284.528 119.266 287.833 106.256 287.867 106.126C287.934 105.872 288.188 105.723 288.446 105.786C288.7 105.853 288.853 106.112 288.786 106.366C288.753 106.491 285.529 119.218 290.391 131.611C290.487 131.855 290.367 132.128 290.123 132.224C290.065 132.248 290.008 132.258 289.95 132.258H289.946Z" fill="#2A2A4F" />
                <path d="M238.775 64.0859C235.686 68.5459 233.296 73.4807 231.715 78.7464C231.533 79.3363 230.613 79.0869 230.795 78.5018C232.189 73.8692 234.196 69.4859 236.759 65.4431C237.108 65.3184 237.3 65.2513 237.3 65.2513C237.3 65.2513 237.832 64.8053 238.78 64.0859H238.775Z" fill="#2A2A4F" />
                <path d="M323.886 33.5544C323.829 33.7078 323.848 33.8757 323.934 34.01C324.107 35.3863 324.078 36.7819 323.752 38.2062C323.733 38.3021 323.733 38.3885 323.752 38.4604C323.762 38.4892 323.781 38.5083 323.795 38.5323C324.006 38.2925 324.198 38.0336 324.356 37.7458C324.48 37.5252 324.662 37.4341 324.854 37.4149C324.998 36.4126 325.017 35.4247 324.926 34.4464C324.83 33.1947 324.567 31.9526 324.198 30.7249C323.973 30.7537 323.762 30.792 323.57 30.8304C323.369 30.4707 323.154 30.1494 322.914 29.8809C323.039 30.2214 323.144 30.5571 323.245 30.8975C323.518 31.7704 323.738 32.6624 323.882 33.5544H323.886Z" fill="#2A2A4F" />
                <path d="M299.544 74.2952C299.233 74.2952 298.917 74.2856 298.596 74.2664C284.399 73.3888 279.173 54.4457 278.957 53.64C278.823 53.1317 279.12 52.6138 279.628 52.4795C280.136 52.3452 280.653 52.6425 280.787 53.1509C280.835 53.3331 285.879 71.5808 298.711 72.3769C311.438 73.1634 315.514 54.7862 315.552 54.5992C315.662 54.086 316.16 53.7551 316.678 53.8702C317.19 53.9805 317.516 54.4841 317.406 54.9972C317.233 55.8029 313.109 74.3 299.539 74.3L299.544 74.2952Z" fill="#2A2A4F" />
                <path d="M339.487 88.5439C339.305 88.5439 339.138 88.4192 339.085 88.237L338.151 84.7505C338.093 84.5299 338.223 84.2997 338.443 84.2422C338.663 84.1846 338.893 84.3141 338.951 84.5347L339.885 88.0212C339.942 88.2418 339.813 88.472 339.593 88.5295C339.554 88.5391 339.521 88.5439 339.482 88.5439H339.487Z" fill="#2A2A4F" />
                <path d="M303.424 81.1286C303.343 81.1286 303.261 81.1047 303.194 81.0567C303.003 80.9272 302.955 80.6683 303.084 80.4764L305.891 76.3138C306.02 76.1219 306.279 76.074 306.466 76.2034C306.657 76.3329 306.705 76.5919 306.576 76.7837L303.769 80.9464C303.688 81.0663 303.558 81.1286 303.424 81.1286Z" fill="#2A2A4F" />
            </g>
            <defs>
                <clipPath id="clip0_1156_8629">
                    <rect width="362.391" height="152.863" fill="white" transform="translate(0.0917969 0.224609)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default BoyWithCamaraIcon
import React, { useEffect, useState } from 'react';
import CustomImage from '../CustomImage/CustomImage';
import PlayIcon from '../video/PlayIcon';
import placeholder1 from './placeholder-images/CardPlaceholder1.svg';
import placeholder2 from './placeholder-images/CardPlaceholder2.svg';
import placeholder3 from './placeholder-images/CardPlaceholder3.svg';
import { getIcon } from '../../pages/school/upload-project-content/helper';

const placeholders = [placeholder1, placeholder2, placeholder3];

// Utility function to determine MIME type from filename


const MediaGridCard = ({ mediaCount, mediaItems, containerClass, onCardClick = () => null, children, displayMore=false }) => {
    const [mediaData, setMediaData] = useState([]);

    useEffect(() => {
        if (mediaCount === 0) {
            setMediaData([null, null, null]); // Show only placeholders
        } else {
            if (mediaCount === 1) {
                setMediaData([mediaItems[0], mediaItems[0], mediaItems[0]]); // Repeat single media item three times
            } else if (mediaCount === 2) {
                setMediaData([mediaItems[0], mediaItems[1], mediaItems[1]]); // Repeat second media item twice
            } else {
                setMediaData(mediaItems); // Use the first three media items
            }
        }
    }, [mediaItems, mediaCount]);

    const renderMediaItem = (mediaItem, placeholderIndex) => {
        const isPlaceholder = !mediaItem; // Check if the media item is a placeholder
        const src = isPlaceholder
            ? placeholders[placeholderIndex]
            : (mediaItem.thumbnailUrl || mediaItem.thumbnail_url || mediaItem.url); // Use thumbnail if present
        const alt = isPlaceholder ? "Placeholder Image" : (mediaItem.alt || "Media Item");
        const isVideo = !isPlaceholder && mediaItem.media_type === "video";
        const mediaClass = isPlaceholder
            ? `w-40 mx-auto my-auto h-full ${placeholderIndex === 0 && 'py-14'}`
            : `${(placeholderIndex === 0 ? "card-image w-full h-full" : "card-image-half")}`;
        const cacheKey = !isPlaceholder ? (mediaItem.thumbnailUrl || mediaItem.url) : `placeholder-${placeholderIndex + 1}`;

        return (
            <div className={`relative ${placeholderIndex === 0 ? 'w-full h-full' : 'h-[49%]'} border border-slate-200`}>
                {
                    // true ?
                    mediaItem?.mediaType?.includes('image') || mediaItem?.mediaType?.includes('video') || mediaCount === 0 ?
                        <>
                            <CustomImage
                                src={src}
                                alt={alt}
                                imageClass={mediaClass}
                                cacheKey={cacheKey}
                            />
                            {isVideo && <PlayIcon center={true} />}
                        </>
                        :
                        <div className="h-full flex items-center justify-center">
                            {
                                mediaItem?.filename ? getIcon(mediaItem?.filename) : null
                            }
                        </div>
                }
            </div>
        );
    };

    return (
        <div onClick={onCardClick}>
            <div className={`h-[300px] w-[400px] flex ${containerClass}`}>
                <div className="flex gap-2 h-full w-full">
                    <div className="flex flex-col h-full w-1/2 relative">
                        {renderMediaItem(mediaData[0], 0)}
                    </div>
                    <div className="flex flex-col h-full gap-2 w-1/2">
                        {renderMediaItem(mediaData[1], 1)}
                        {renderMediaItem(mediaData[2], 2)}
                    </div>
                </div>
                {
                    displayMore && mediaItems.length > 3  &&
                        <div className="flex gap-2 h-full w-full">
                            <div className="flex flex-col h-full w-1/2 relative">
                                {renderMediaItem(mediaData[3], 0)}
                            </div>
                            <div className="flex flex-col h-full gap-2 w-1/2">
                                {renderMediaItem(mediaData[4], 1)}
                                {renderMediaItem(mediaData[5], 2)}
                            </div>
                        </div>
                }
            </div>
            {children}
        </div>
    );
};

export default MediaGridCard;

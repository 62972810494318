import React from 'react'

function AccountRoundedIcon() {
    return (
        <svg width="18px" height="20px" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6.5" cy="2.5" r="2" stroke="#7F56D9" />
            <path d="M12 9V9C9.4098 5.14273 3.75672 5.08135 1.08338 8.88147L1 9" stroke="#7F56D9" />
        </svg>

    )
}

export default AccountRoundedIcon;
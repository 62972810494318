import { toast } from 'react-toastify';

const useToast = () => {
  const showToast = (message, type = 'default', options = {}) => {
    const defaultOptions = { autoClose: 3000, ...options };

    switch (type) {
      case 'success':
        toast.success(message, defaultOptions);
        break;
      case 'error':
        toast.error(message, defaultOptions);
        break;
      case 'info':
        toast.info(message, defaultOptions);
        break;
      case 'warn':
        toast.warn(message, defaultOptions);
        break;
      default:
        toast(message, defaultOptions);
        break;
    }
  };

  return showToast;
};

export default useToast;

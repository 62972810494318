import React from 'react'

function PrimaryFilledBtn({
    label="",
    type="button",
    className="",
    onClick=() => {},
    disabled=false
}) {
  return (
<button disabled={disabled} onClick={onClick} type={type} className={`${className} button-primary-sw-100 w-full btn-center font-bold ${disabled && 'cursor-not-allowed opacity-90'}`} >{label}</button>  )
}

export default PrimaryFilledBtn
import { createContext, useContext, useEffect } from "react";

import AuthLayout from "../../../HOC/AuthLayout";
import SignupMain from "../../../components/auth/SignupMain";
import SignupStartScreen from "../../../components/auth/SignupStartScreen";
import { useAuthProvider } from "../../../contexts/AuthContext";
import { SidebarContext } from "../../../App";
import { validateToken } from "../../../services/brandServices";


export const SignupContext = createContext();

// const registrationOptions = [{ label: "Brand or marketing agency", value: 'brand' }, { label: "Teacher", value: "teacher", isDisabled: true }, { label: 'Independent artist/photographer', value: 'creator', isDisabled: false }]


function Registration() {
  const {signupData,changeSignupData}=useAuthProvider()
  const { setGlobalLoader } = useContext(SidebarContext)
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString);


  async function handleValidateToken(token) {
    try {
      setGlobalLoader(true)
      const {data} = await validateToken({payload:token})
      changeSignupData({
        screenType: "main",
        screenNo: 1,
        ...data.decoded
    })
    }catch(e) {
      console.log(e)
    } finally {
      setGlobalLoader(false)
    }
  }


  useEffect(()=>{
    changeSignupData({
      screenType:"start"
    })
    const token = urlParams.get('token')
    if(token){
      handleValidateToken(token)
    }
  },[])



  return (

      <AuthLayout>
        <div className="login-container max-h-fit">
            <div className={`login-form-wrapper max-h-[100vh] overflow-y-auto `}>
          {
            signupData?.screenType === "start" ?
            <SignupStartScreen />
            :
            <SignupMain />
          }
            </div>
         
        </div>
      </AuthLayout >

  );
}
export default Registration;



import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { resetPassword } from "../../../services/aws/AwsService"; // Import the AWS service

import "./ResetPassword.css";

import AuthLayout from "../../../HOC/AuthLayout";

const ResetPassword = () => {
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showPassword, setShowPassword] = useState(true);

  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const username = queryParams.get("username");
  }, [location.search]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleResetPassword = async () => {
    try {
      const queryParams = new URLSearchParams(location.search);
      const username = queryParams.get("username");
      await resetPassword(username, verificationCode, newPassword);
      setSuccessMessage(
        "Password reset successfully. Redirecting to login page..."
      );
      setError("");
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } catch (error) {
      setError("Error resetting password: " + error.message);
      setSuccessMessage("");
    }
  };

  return (
    <AuthLayout>

      <div className="loginform">
        <div className="login-form-container">
          <div className="login-form-header flex justify-center">
            <div className='text-center'>
              <h1 className='font-[600]'>Reset password</h1>
              <p className='text-[#475467] mb-0'>Please enter your varification code.</p>

            </div>
          </div>
          <div className="login-form-row">
            <div className="login-form-group">
              <label className="form-label">Verification Code</label>
              <input
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                className="form-control-input" />
            </div>
          </div>
          <div className="login-form-row">
            <div className="login-form-group position-relative">
              <label className="form-label">New Password </label>
              <input
                type={showPassword ? "text" : "password"}
                value={newPassword}
                className="form-control-input"
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {/* <img
                  className="icon-olor-instance"
                  alt="Icon olor"
                  src="/Login/icon-olor-1.svg"
                  onClick={handleTogglePassword}
                  style={{ cursor: "pointer" }}
                /> */}
            </div>


          </div>
          <div className="login-form-button">
            <div className="login-form-group">
              <div className="back-to-link-div">
                <Link to="/" className="back-to-login-link">
                  Back to Login
                </Link>
              </div>
            </div>
          </div>
          <div className="login-form-button">
            <div className="login-form-group">
              <button
                onClick={handleResetPassword}
                className="button-primary-sw-100 btn-center"
              >
                Reset Password
              </button>
            </div>
          </div>

          {error && (
            <div className="login-form-row">
              <div className="login-form-group">
                <p style={{ color: "red" }}>{error}</p>
              </div>
            </div>
          )}
          {successMessage && (
            <div className="login-form-row">
              <div className="login-form-group">
                <p style={{ color: "green" }}>{successMessage}</p>
              </div>
            </div>
          )}

        </div>
      </div>
    </AuthLayout>


  );
};

export default ResetPassword;


import React from "react";
import Select from "react-select";
import "./selectDropDown.css"
import CheckMark from "../../icons/CheckMark";


function SelectDropDown({ options, value, onChange, name = '', dropDownLabel,className="", onMenuScrollToBottom = () => null, onInputChange = () => null, inputValue = '', isSearchable=false, isLoading=false }) {
    const handleChange = (e) => {
        onChange({ target: { value: e.value, name: name } })
    }

    const modifiedOptions = options.map((option) => {
        return {
            ...option,
            labelValue:option.label,
            label: <div className="flex gap-3 justify-between items-center w-full">
                <div className="flex gap-3 ">

                   {option.icon&& <span className="">{option.icon}</span>}
                    <span className="text-[13px]">{option.label}</span>
                </div>
                {option.value === value && <span className="optionSelected "><CheckMark /></span>}
            </div>
        }
    })

    const customFilter = (option, inputValue='') => {
        // option.data.labelValue.toLowerCase()
        inputValue?.toLowerCase()
        return (option.data.labelValue || '').toLowerCase().includes(inputValue?.toLowerCase());
      };


    const defaultValue = modifiedOptions.find((option) => option.value === value);

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: 16,
            color: '#101828',
            backgroundColor: state.isSelected ? '#d9d7d7' : 'white', // Change background color for selected options
            cursor: 'pointer'
        }),
        control: (provided, state) => ({
            ...provided,
            border:"1px solid  #D0D5DD !important", 
            boxShadow:"0px 1px 2px 0px rgba(16, 24, 40, 0.15)",

            borderRadius:"8px",
            borderColor: state.isFocused ? '#D6BBFB' : provided.borderColor,
            // boxShadow: state.isFocused ? '0 0 0 1    px blue' : provided.boxShadow,
            '&:hover': {
                borderColor: state.isFocused ? '#D6BBFB' : provided.borderColor,
            },
        }),
    };

    return (
        <div>
            {
                dropDownLabel && <label className='text-black mb-2 text-[14px]' htmlFor="">{dropDownLabel}</label>
            }
            <Select filterOption={customFilter} isLoading={isLoading} isSearchable={isSearchable} inputValue={inputValue} onInputChange={onInputChange} onMenuScrollToBottom={onMenuScrollToBottom} name={name} styles={customStyles} className={`${className} w-[200px] rounded-[8px]`} onChange={handleChange} defaultValue={defaultValue} options={modifiedOptions} />
        </div>

    );
}

export default SelectDropDown


import React from 'react'
import AlertRounded from '../icons/AlertRounded'

const MessageInfoCard = ({ heading, subHeading ,className}) => {
    return (
        <div className={`unpublishalert flex gap-[12px] p-[12px]  items-start border border-[silver] rounded-xl alertClass ${className}`}>
            <AlertRounded />
            <div className="flex flex-col pt-2" >
                <p className="text-sm font-semibold text-[14px] leading-5 mb-1">{heading || "Publish this collection to start selling your art"}</p>
                <p className="text-sm text-[14px] leading-5 mb-1 ">{subHeading || "Publishing a collection makes it visible in our store. You can un-publish it at any time. "}</p>
            </div>
        </div>)
}

export default MessageInfoCard
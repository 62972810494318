import React from 'react'

function FacebookIcon() {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_612_9187)">
                <path d="M24.1738 12.5C24.1738 5.87258 18.8013 0.5 12.1738 0.5C5.54641 0.5 0.173828 5.87258 0.173828 12.5C0.173828 18.4895 4.56203 23.454 10.2988 24.3542V15.9688H7.25195V12.5H10.2988V9.85625C10.2988 6.84875 12.0904 5.1875 14.8314 5.1875C16.1439 5.1875 17.5176 5.42188 17.5176 5.42188V8.375H16.0045C14.5138 8.375 14.0488 9.30008 14.0488 10.25V12.5H17.377L16.8449 15.9688H14.0488V24.3542C19.7856 23.454 24.1738 18.4895 24.1738 12.5Z" fill="#1877F2" />
                <path d="M16.8449 15.9688L17.377 12.5H14.0488V10.25C14.0488 9.30102 14.5138 8.375 16.0045 8.375H17.5176V5.42188C17.5176 5.42188 16.1444 5.1875 14.8314 5.1875C12.0904 5.1875 10.2988 6.84875 10.2988 9.85625V12.5H7.25195V15.9688H10.2988V24.3542C11.5413 24.5486 12.8064 24.5486 14.0488 24.3542V15.9688H16.8449Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_612_9187">
                    <rect width="24" height="24" fill="white" transform="translate(0.173828 0.5)" />
                </clipPath>
            </defs>
        </svg>


    )
}

export default FacebookIcon
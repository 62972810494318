import React, { useEffect, useState } from 'react';
import { StyledTableBodyCell, StyledTableHeadCell } from '../../components/StyledTableCell/StyledTableCell';
import Pagination from '../../components/Pagenation/Pagenation';
import moment from 'moment';
import LoadingWrapper from '../../components/loadingWrapper/LoadingWrapper';
import { getAllSchoolUsers } from '../../services/schoolServices';

const SchoolList = () => {
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const handlePageChange = (pageNo) => {
        setCurrentPage(pageNo);
    };

    const handleGetUserData = async (currentPage) => {
        setLoading(true);
        try {
            const payload = {
                limit,
                page: currentPage,  
                search: '',         
            };
            const resp = await getAllSchoolUsers(payload); 
            setTotalPages(Math.ceil(resp.totalUsers / limit));
            setUserData(resp.users || []);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    

    useEffect(() => {
        handleGetUserData(currentPage);
    }, [currentPage]);

    const makeTableData = (field) => {
        return field ? field : '-';
    };

    return (
        <div>
            <p className="text-[36px] font-semibold text-center py-3">User List</p>

            <LoadingWrapper loading={loading}>
                <div className="w-full overflow-auto">
                    <table>
                        <thead>
                            <tr className="font-style-grid">
                                <StyledTableHeadCell><span className="font-bold">ID</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">School ID</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">School Name</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">Role</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">Full Name</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">Email</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">Published Pieces</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">Total Pieces</span></StyledTableHeadCell>
                                {/* <StyledTableHeadCell><span className="font-bold">Created At</span></StyledTableHeadCell>
                                <StyledTableHeadCell><span className="font-bold">Updated At</span></StyledTableHeadCell>*/}
                            </tr>
                        </thead>
                        <tbody>
                            {userData.map((user, index) => (
                                <tr
                                    key={user.id}
                                    className={index % 2 === 0 ? 'even-row font-style-grid' : 'odd-row font-style-grid'}
                                >
                                    <StyledTableBodyCell>{makeTableData(user.id)}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData(user.school_id)}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData(user.school_name)}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData(user.role)}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData(user.full_name)}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData(user.email)}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData((user.published_count) ? user.published_count : "0")}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData((user.published_count + user.unPublished_count) ? user.published_count + user.unPublished_count : "0")}</StyledTableBodyCell>
                                {/* <StyledTableBodyCell>{makeTableData(moment(user.created_at).format('MMMM DD, YYYY'))}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{makeTableData(moment(user.updated_at).format('MMMM DD, YYYY'))}</StyledTableBodyCell> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </LoadingWrapper>

            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
        </div>
    );
};

export default SchoolList;

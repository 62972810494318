import React, { memo } from "react";


function BorderLessButton({ text = '', style={}, className = 'text-violet', onClick = ()=>null, Icon, disabled=false }) {
    return (
        <button style={{...style}} disabled={disabled} onClick={onClick} className={`${className} bg-white flex gap-[3px] py-[14px] px-[10px] items-center text-[14.6px]`}>
            {Icon}
            <span>{text}</span>
        </button>
    )
}

export default memo(BorderLessButton);
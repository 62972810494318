import React from "react";

import aep from "../icons/fileIcons/aep.svg";
import ai from "../icons/fileIcons/ai.svg";
import avi from "../icons/fileIcons/avi.svg";
import css from "../icons/fileIcons/css.svg";
import csv from "../icons/fileIcons/csv.svg";
import dmg from "../icons/fileIcons/dmg.svg";
import doc from "../icons/fileIcons/doc.svg";
import docx from "../icons/fileIcons/docx.svg";
import eps from "../icons/fileIcons/eps.svg";
import exe from "../icons/fileIcons/exe.svg";
import fig from "../icons/fileIcons/fig.svg";
import gif from "../icons/fileIcons/gif.svg";
import html from "../icons/fileIcons/html.svg";
import img from "../icons/fileIcons/img.svg";
import indd from "../icons/fileIcons/indd.svg";
import java from "../icons/fileIcons/java.svg";
import jpeg from "../icons/fileIcons/jpeg.svg";
import jpg from "../icons/fileIcons/jpg.svg";
import js from "../icons/fileIcons/js.svg";
import json from "../icons/fileIcons/json.svg";
import mkv from "../icons/fileIcons/mkv.svg";
import mp3 from "../icons/fileIcons/mp3.svg";
import mp4 from "../icons/fileIcons/mp4.svg";
import mpeg from "../icons/fileIcons/png.svg";
import pdf from "../icons/fileIcons/pdf.svg";
import png from "../icons/fileIcons/png.svg";
import ppt from "../icons/fileIcons/ppt.svg";
import pptx from "../icons/fileIcons/pptx.svg";
import psd from "../icons/fileIcons/psd.svg";
import rar from "../icons/fileIcons/rar.svg";
import rss from "../icons/fileIcons/rss.svg";
import sql from "../icons/fileIcons/sql.svg";
import svg from "../icons/fileIcons/svg.svg";
import tiff from "../icons/fileIcons/tiff.svg";
import txt from "../icons/fileIcons/txt.svg";
import wav from "../icons/fileIcons/wav.svg";
import webp from "../icons/fileIcons/webp.svg";
import xls from "../icons/fileIcons/xls.svg";
import xlsx from "../icons/fileIcons/xlsx.svg";
import xml from "../icons/fileIcons/xml.svg";
import zip from "../icons/fileIcons/zip.svg";

const fileIconsData = {
  aep,
  ai,
  avi,
  css,
  csv,
  dmg,
  doc,
  docx,
  eps,
  exe,
  fig,
  gif,
  html,
  img,
  indd,
  java,
  jpeg,
  jpg,
  js,
  json,
  mkv,
  mp3,
  mp4,
  mpeg,
  pdf,
  png,
  ppt,
  pptx,
  psd,
  rar,
  rss,
  sql,
  svg,
  tiff,
  txt,
  wav,
  webp,
  xls,
  xlsx,
  xml,
  zip,
};

function CommonFile({ extName }) {
  return fileIconsData[extName] ? (
    <img src={fileIconsData[extName]} alt="Document Icon" />
  ) : (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 4.33228C4 2.12314 5.79086 0.332275 8 0.332275H24L36 12.3323V36.3323C36 38.5414 34.2091 40.3323 32 40.3323H8C5.79086 40.3323 4 38.5414 4 36.3323V4.33228Z"
        fill="#7F56D9"
      />
      <path
        opacity="0.3"
        d="M24 0.332275L36 12.3323H28C25.7909 12.3323 24 10.5414 24 8.33228V0.332275Z"
        fill="white"
      />
    </svg>
  );
}

export default CommonFile;

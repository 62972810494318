import React from 'react';

function NoCommission({ Icon, mainText, supportingText, className }) {
    return (
        <div className={`Cta ${className}`}>
            <div className="FeaturedIcon">
                <div className="Edit04">
                    {Icon && <Icon />} {/* Render the passed icon */}
                </div>
            </div>
            <div className="TextAndSupportingText">
                <div className="Text">
                    {mainText} {/* Render the passed main text */}
                </div>
                <div className="SupportingText">
                    {supportingText} {/* Render the passed supporting text */}
                </div>
            </div>
        </div>
    );
}

export default NoCommission;

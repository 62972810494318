import axios from "axios";
import { Get, GetWithToken, Post, PostWithToken } from "../lib/axios";
const baseUrl = process.env.REACT_APP_API_URL+"/brand/admin";



export const getAllSchools = async ({page,limit, searchText=''}) => {
    try {
        const { data } = await GetWithToken({baseUrl:'http://localhost:4300',endpoint:`/brand/admin/get-all-schools?page=${page}&limit=${limit}&searchText=${searchText}`})
        return data;
    } catch (error) {
        throw error;
    }
}

export const fetchAllCommissionsForAdmin = async ({page, limit, searchText=''}) => {
    try {
        const { data } = await GetWithToken({baseUrl:'http://localhost:4300',endpoint:`/brand/admin/get-all-commission-for-admin?page=${page}&limit=${limit}&searchText=${searchText}`})
        return data;
    } catch (error) {
        throw error;
    }
}

export const createSchoolCommissionXref = async(payload) => {
    try {
        const { data } = await Post({endpoint:`/brand/admin/create-commission-school-xref`, data:{...payload}})
        return data;
    } catch (error) {
        throw error   
    }
}

{/*export const getCommissionSchoolXref = async (limit = 5, page = 2) => {
    try {
        const { data } = await Get({endpoint:`/brand/admin/get-all-commission-school-xref?limit=${limit}&page=${page}`});
        return data;
    } catch (error) {
        throw error;
    }
} */}

export const getCommissionSchoolXref = async (payload) => {
    const { limit, page, searchText = '' } = payload;
    try {
        const response = await GetWithToken({baseUrl:'http://localhost:4300',endpoint:`/brand/admin/get-all-commission-school-xref?limit=${limit}&page=${page}&searchText=${searchText}`})
        // const response = await axios.get('https://l26dkbzcng.execute-api.us-east-2.amazonaws.com/brand/admin/get-all-commission-school-xref?limit=5&page=2', {
        //     params: {
        //         search, 
        //         limit,  
        //         page,   
        //     },
        // });
        return response.data; 
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};
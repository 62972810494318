import React from 'react'

const DollerIcon = () => {
  return (

    <svg  className="bx bx-pie-chart-alt icon"   width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.6145 6.02775H10.9995C10.133 6.02775 9.4305 6.73021 9.4305 7.59675C9.4305 8.46328 10.133 9.16575 10.9995 9.16575H12.0455C12.912 9.16575 13.6145 9.86822 13.6145 10.7348C13.6145 11.6013 12.912 12.3038 12.0455 12.3038H9.4305M11.5225 4.98175V6.02775M11.5225 12.3038V13.3498M17.7985 9.16575H17.809M5.2465 9.16575H5.25696M1.0625 5.19095L1.0625 13.1405C1.0625 14.3122 1.0625 14.898 1.29051 15.3455C1.49108 15.7391 1.81112 16.0592 2.20475 16.2597C2.65226 16.4877 3.23807 16.4877 4.4097 16.4877L18.6353 16.4878C19.8069 16.4878 20.3927 16.4877 20.8402 16.2597C21.2339 16.0592 21.5539 15.7391 21.7545 15.3455C21.9825 14.898 21.9825 14.3122 21.9825 13.1406V5.19095C21.9825 4.01932 21.9825 3.43351 21.7545 2.986C21.5539 2.59237 21.2339 2.27233 20.8402 2.07177C20.3927 1.84375 19.8069 1.84375 18.6353 1.84375L4.4097 1.84375C3.23807 1.84375 2.65226 1.84375 2.20475 2.07176C1.81112 2.27233 1.49108 2.59237 1.29051 2.986C1.0625 3.4335 1.0625 4.01932 1.0625 5.19095ZM18.3215 9.16575C18.3215 9.45459 18.0873 9.68875 17.7985 9.68875C17.5097 9.68875 17.2755 9.45459 17.2755 9.16575C17.2755 8.8769 17.5097 8.64275 17.7985 8.64275C18.0873 8.64275 18.3215 8.8769 18.3215 9.16575ZM5.7695 9.16575C5.7695 9.45459 5.53534 9.68875 5.2465 9.68875C4.95766 9.68875 4.7235 9.45459 4.7235 9.16575C4.7235 8.8769 4.95766 8.64275 5.2465 8.64275C5.53534 8.64275 5.7695 8.8769 5.7695 9.16575Z" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>


  )
}

export default DollerIcon


// <svg  className="bx bx-pie-chart-alt icon"  width="19" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
//// <path d="M13.8586 5.93848H11.3586C10.5302 5.93848 9.85864 6.61005 9.85864 7.43848C9.85864 8.2669 10.5302 8.93848 11.3586 8.93848H12.3586C13.1871 8.93848 13.8586 9.61005 13.8586 10.4385C13.8586 11.2669 13.1871 11.9385 12.3586 11.9385H9.85864M11.8586 4.93848V5.93848M11.8586 11.9385V12.9385M17.8586 8.93848H17.8686M5.85864 8.93848H5.86864M1.85864 5.13848L1.85864 12.7385C1.85864 13.8586 1.85864 14.4186 2.07663 14.8465C2.26838 15.2228 2.57434 15.5287 2.95066 15.7205C3.37848 15.9385 3.93854 15.9385 5.05864 15.9385L18.6586 15.9385C19.7787 15.9385 20.3388 15.9385 20.7666 15.7205C21.1429 15.5287 21.4489 15.2228 21.6407 14.8465C21.8586 14.4186 21.8586 13.8586 21.8586 12.7385V5.13848C21.8586 4.01837 21.8586 3.45832 21.6407 3.0305C21.4489 2.65417 21.1429 2.34821 20.7666 2.15646C20.3388 1.93848 19.7787 1.93848 18.6586 1.93848L5.05864 1.93848C3.93854 1.93848 3.37849 1.93848 2.95066 2.15646C2.57434 2.34821 2.26838 2.65417 2.07663 3.0305C1.85864 3.45832 1.85864 4.01837 1.85864 5.13848ZM18.3586 8.93848C18.3586 9.21462 18.1348 9.43848 17.8586 9.43848C17.5825 9.43848 17.3586 9.21462 17.3586 8.93848C17.3586 8.66233 17.5825 8.43848 17.8586 8.43848C18.1348 8.43848 18.3586 8.66233 18.3586 8.93848ZM6.35864 8.93848C6.35864 9.21462 6.13478 9.43848 5.85864 9.43848C5.5825 9.43848 5.35864 9.21462 5.35864 8.93848C5.35864 8.66233 5.5825 8.43848 5.85864 8.43848C6.13478 8.43848 6.35864 8.66233 6.35864 8.93848Z" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
// </svg>
export function isValidUrl(url) {
    // Regular expression to check if the input is a valid domain or URL-like structure
    const urlPattern = new RegExp(
        '^' +
        // Protocol identifier (optional)
        '(?:(?:https?:)?\\/\\/)?' +
        // User: pass authentication (optional)
        '(?:\\S+(?::\\S*)?@)?' +
        '(?:' +
        // IP address or domain name
        '(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])\\.|(?:[a-z\\d](?:[a-z\\d-]*[a-z\\d])?\\.)+[a-z]{2,})' +
        // Port (optional)
        '(?::\\d{2,5})?' +
        ')' +
        // Path, query string, or fragment identifier (optional)
        '(?:[/?#]\\S*)?$',
        'i'
    );

    // First, check if the input matches the pattern of a domain or URL structure
    if (!urlPattern.test(url)) {
        return false;
    }

    // Add 'http://' if the URL does not start with a protocol
    if (!/^(https?:\/\/)/i.test(url)) {
        url = 'http://' + url;
    }

    // Now try to create a URL object to further validate
    try {
        new URL(url);
        return true;
    } catch (_) {
        return false;
    }
}


export function removeQueryStringIfValidUrl(url) {
    if (isValidUrl(url)) {
        // Ensure the URL is complete with protocol for splitting
        const formattedUrl = url.startsWith('http') ? url : 'http://' + url;
        return formattedUrl.split('?')[0];
    } else {
        throw new Error("Invalid URL");
    }
}

export  function extractUsernameFromXCom(url) {
    try {
        const parsedUrl = new URL(url);

        // Check if the hostname is x.com
        if (parsedUrl.hostname === 'x.com') {
            // Extract the username from the pathname (everything after the first '/')
            const username = parsedUrl.pathname.split('/')[1];
            return username || null; // Return null if no username is found
        } else {
            return null; // Do nothing if the domain is not x.com
        }
    } catch (_) {
        return null; // Return null if the URL is invalid
    }
}

export function isXCom(url) {
    try {
        const parsedUrl = new URL(url);

        // Check if the hostname is 'x.com' or ends with '.x.com'
        return parsedUrl.hostname === 'x.com' || parsedUrl.hostname.endsWith('.x.com');
    } catch (_) {
        return false; // Return false if the URL is invalid
    }
}

import CommonFile from "../../../icons/CommonFile";

export  const INITIAL_FORM_DATA = {
    creatorName:{
        name:'creatorName',
        required:true,
        value:""
    },
    contentTitle:{
        name:'contentTitle',
        required:true,
        value:""
    },
    inspiration:{
        name:'inspiration',
        required:true,
        value:""
    },
    bio:{
        name:'bio',
        required:true,
        value:""
    },
    links:{
        name:'links',
        required:false,
        value:""
    },
    files:{
        name:'files',
        required:true,
        value:[]
    },
    isChecked:{
        name:'isChecked',
        required:true,
        value:false
    }
}

export function renderPreview({file, previewObject}) {
    console.log({file,previewObject})
    if(file?.type.includes('image')) {
        return <img src={previewObject} alt={file.name} className='w-96 h-96 mx-auto object-cover' />
    }
    if(file?.type.includes('video')) {
        return <video controls src={previewObject} alt={file.name} className='w-96 h-96 mx-auto object-cover' />
    }

    return null
}


export const getIcon = (fileName,mediaType) => {
    // if(!mediaType){
    //     let split = fileName.split("/");
    //     let extension = split[split.length - 1].toLowerCase();;
    //     return <CommonFile extName={extension} />;
    // }
    console.log({fileName,mediaType})
    let split =fileName? fileName.split("."):mediaType.split("/");
    let extension = split[split.length - 1].toLowerCase();;
    return <CommonFile extName={extension} />;
};

export function formatFileSize(size) {
    const units = ["bytes", "KB", "MB", "GB"];
    if (size < 1024) return `${(size||0).toFixed(2)}${units[0]}`;
    let i = 0;
    let formattedSize = size;

    while (formattedSize >= 1024 && i < units.length - 1) {
        formattedSize /= 1024;
        i++;
    }

    return `${formattedSize.toFixed(2)}${units[i]}`;
}


export const validate = ({formData}) => {
    // Validate form state whenever formData changes
    let valid = true;

    for (let key in formData) {
        const val = formData[key];

        if (val.required) {
            // Check for empty value for text fields
            if (typeof val.value === 'string' && val.value.trim() === '') {
                valid = false;
                break; // Exit loop early if an invalid field is found
            }

            // For checkbox, check if it is required and if it is not checked
            if (val.name === INITIAL_FORM_DATA.isChecked.name && !val.value) {
                valid = false;
                break; // Exit loop early if checkbox is required and not checked
            }

            // For arrays, check if they are empty
            if (Array.isArray(val.value) && val.value.length === 0) {
                valid = false;
                break; // Exit loop early if array is required and empty
            }
        }
    }
    return valid;
    
}



export async function getFileSize(url) {
    const response = await fetch(url, { method: 'HEAD' });
    const contentLength = response.headers.get('content-length');

    if (contentLength) {
        const fileSizeInBytes = parseInt(contentLength, 10);
        console.log(`File Size: ${fileSizeInBytes} bytes`);
        return fileSizeInBytes
    } else {
        console.log('File size could not be determined.');
        return 0
    }
}

export async function getFileMimeType(url) {
    try {
        const response = await fetch(url, { method: 'HEAD' });
        const mimeType = response.headers.get('content-type');
        console.log({mimeType})
        if (mimeType) {
            
            return mimeType;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error fetching file type:', error);
        return false;
    }
}
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./CommissionListing.css";
import LoadingWrapper from "./loadingWrapper/LoadingWrapper";

import CardContainer from "./CardContainer";
import { getAllCommissionWithImages } from "../services/brandServices";
import MediaGridCard from "./cards/MediaGridCard";

function CommissionListing({displayMore=false, commissions, isLatestCommission = false }) {
  const [cards, setCards] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // State for loader
  const { username, userId, brandId } = useSelector((state) => state.user);


  useEffect(() => {
    const fetchAllCommission = async () => {
      const data = await getAllCommissionWithImages(brandId);
      setCards(data.data);
      setIsLoading(false);
    }
    if (commissions) {
      setCards(commissions)
      setIsLoading(false)
    } else fetchAllCommission()
  }, [])

  return (
    <LoadingWrapper loading={isLoading}>
      {isLatestCommission && <div className="intro-commission">
        <div className="new-symbol">NEW</div>
        <div className="new-commission-text">
          Your latest commission is here!
        </div>
      </div>}
      <CardContainer displayMore={displayMore} cards={cards} />
    </LoadingWrapper>
  );
}

export default CommissionListing;

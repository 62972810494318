import React from "react";

const CommissionIcon = () => {
  return (
    <svg
      className="bx bx-pie-chart-alt icon"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00887 15.9428L9.10059 18.0346L13.807 13.3282M9.10059 8.62183V5.69343C9.10059 4.52196 9.10059 3.93622 9.32858 3.48878C9.52912 3.0952 9.84911 2.7752 10.2427 2.57466C10.6901 2.34668 11.2759 2.34668 12.4473 2.34668H20.3959C21.5673 2.34668 22.1531 2.34668 22.6005 2.57466C22.9941 2.7752 23.3141 3.0952 23.5146 3.48878C23.7426 3.93622 23.7426 4.52196 23.7426 5.69343V13.642C23.7426 14.8134 23.7426 15.3992 23.5146 15.8466C23.3141 16.2402 22.9941 16.5602 22.6005 16.7607C22.1531 16.9887 21.5673 16.9887 20.3959 16.9887H17.4675M6.17219 23.2639H14.1207C15.2922 23.2639 15.8779 23.2639 16.3254 23.0359C16.7189 22.8353 17.0389 22.5153 17.2395 22.1218C17.4675 21.6743 17.4675 21.0886 17.4675 19.9171V11.9686C17.4675 10.7971 17.4675 10.2114 17.2395 9.76393C17.0389 9.37035 16.7189 9.05036 16.3254 8.84982C15.8779 8.62183 15.2922 8.62183 14.1207 8.62183H6.17219C5.00072 8.62183 4.41498 8.62183 3.96754 8.84982C3.57396 9.05036 3.25396 9.37035 3.05342 9.76393C2.82544 10.2114 2.82544 10.7971 2.82544 11.9686V19.9171C2.82544 21.0886 2.82544 21.6743 3.05342 22.1218C3.25396 22.5153 3.57396 22.8353 3.96754 23.0359C4.41498 23.2639 5.00072 23.2639 6.17219 23.2639Z"
        stroke="#667085"
        strokeWidth="2.09172"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CommissionIcon;

import { useLocation, useNavigate } from 'react-router-dom';

/***
 * -----how to use in component -----------
 * const { getQueryParam, setQueryParam } = useQueryParam();
 * const screen = getQueryParam('screen');
 * setQueryParam('screen', '2'); // Example of changing the screen parameter
 */


const useQueryParam = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Function to get the query parameter
    const getQueryParam = (param) => {
        const params = new URLSearchParams(location.search);
        return params.get(param);
    };

    // Function to change the query parameters
    const setQueryParams = (paramsObj) => {
        const params = new URLSearchParams(location.search);
        
        Object.entries(paramsObj).forEach(([key, value]) => {
            if (value !== undefined) {
                params.set(key, value); // Set or update the parameter
            } else {
                params.delete(key); // Remove the parameter if value is undefined
            }
        });

        // Update the URL with the new query parameters
        navigate({
            pathname: location.pathname,
            search: params.toString(),
        });
    };

    return { getQueryParam, setQueryParams };
};

export default useQueryParam;


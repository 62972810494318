import React, { useState } from 'react';

const ParticipantsTable = ({participants=[],className}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  // const participants = [
  //   { firstName: 'John', lastName: 'Doe' },
  //   { firstName: 'Jessica', lastName: 'Smith' },
  //   { firstName: 'Sarah', lastName: 'Parsley' },
  //   { firstName: 'Diana', lastName: 'West' },
  //   { firstName: 'James', lastName: 'Taylor' },
  //   { firstName: 'Emily', lastName: 'Clark' },
  //   { firstName: 'Michael', lastName: 'Johnson' },
  //   { firstName: 'Anna', lastName: 'Davis' },
  //   { firstName: 'Robert', lastName: 'Wilson' },
  //   { firstName: 'John', lastName: 'Doe' },
  //   { firstName: 'Jessica', lastName: 'Smith' },
  //   { firstName: 'Sarah', lastName: 'Parsley' },
  //   { firstName: 'Diana', lastName: 'West' },
  //   { firstName: 'James', lastName: 'Taylor' },
  //   { firstName: 'Emily', lastName: 'Clark' },
  //   { firstName: 'Michael', lastName: 'Johnson' },
  //   { firstName: 'Anna', lastName: 'Davis' },
  //   { firstName: 'Robert', lastName: 'Wilson' },
  //   { firstName: 'John', lastName: 'Doe' },
  //   { firstName: 'Jessica', lastName: 'Smith' },
  //   { firstName: 'Sarah', lastName: 'Parsley' },
  //   { firstName: 'Diana', lastName: 'West' },
  //   { firstName: 'James', lastName: 'Taylor' },
  //   { firstName: 'Emily', lastName: 'Clark' },
  //   { firstName: 'Michael', lastName: 'Johnson' },
  //   { firstName: 'Anna', lastName: 'Davis' },
  //   { firstName: 'Robert', lastName: 'Wilson' },
  // ];

  return (
    <div className={` ${className} bg-[#F9FAFB] p-4 mt-6 w-full`}>
  {/* Header with "Participants" and Chevron icon */}
 {/*} <div className="flex justify-between items-center">
    <h2 className="flex flex-row gap-2">
      <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.13378 11.5C5.49205 11.5 3.14278 12.7755 1.64711 14.755C1.3252 15.181 1.16425 15.394 1.16951 15.6819C1.17358 15.9043 1.31325 16.1849 1.48825 16.3222C1.71477 16.5 2.02867 16.5 2.65647 16.5H13.6111C14.2389 16.5 14.5528 16.5 14.7793 16.3222C14.9543 16.1849 15.094 15.9043 15.0981 15.6819C15.1033 15.394 14.9424 15.181 14.6205 14.755C13.1248 12.7755 10.7755 11.5 8.13378 11.5Z" stroke="#98A2B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.13378 9C10.2048 9 11.8838 7.32107 11.8838 5.25C11.8838 3.17893 10.2048 1.5 8.13378 1.5C6.06271 1.5 4.38378 3.17893 4.38378 5.25C4.38378 7.32107 6.06271 9 8.13378 9Z" stroke="#98A2B3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
      <span className="font-inter text-base font-medium leading-6 text-left">14 Participants</span>
    </h2> */}
    <div className="grid grid-cols-3 gap-4 ">
          {participants.map((participant, index) => (
            <div key={index} className="text-left">
              {/* {participant.lastName}, {participant.firstName} */}
              {participant}
            </div>
          ))}
        </div>
   {/*} <button 
      onClick={toggleExpanded} 
      className="
        w-8 h-8 
        flex items-center justify-center 
        text-gray-400 
        bg-transparent 
        transition-colors 
        focus:outline-none
      ">
      {expanded ? (
        // If expanded, show a chevron-up icon
        <svg width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19 14l-7-7-7 7"></path>
        </svg>
      ) : (
        // If not expanded, show a chevron-down icon
        <svg width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7"></path>
        </svg>
      )}
    </button>
  </div>



      {/* Expandable participant list in 3 columns 
      {expanded && (
        <div className="grid grid-cols-3 gap-4 ">
          {participants.map((participant, index) => (
            <div key={index} className="text-left">
              {participant.lastName}, {participant.firstName}
            </div>
          ))}
        </div>
      )} */}
      
    </div>

  );
};

export default ParticipantsTable;

import React from 'react';
import './Checkbox.css';

const Checkbox = ({checkboxError, name, value, checked, onChange, label, isDisabled = false, className=''}) => {
    return (
        <label className="checkbox-container flex justify-between items-center gap-4 ">
            <div className='w-[6px] mt-1 h-12px position-relative checkbox-box'>
                <input
                    type="checkbox"
                    name={name}
                    value={value}
                    checked={checked}
                    onChange={onChange}
                    disabled={isDisabled}
                />

                <div style={{borderColor:checkboxError && '#D92D20'}} className={` custom-checkbox ${checked ? 'checked' : 'unchecked'}`}></div>
            </div>
            <span className={`label-text w-[97%] ${className}`}>
                <span className={` ${checkboxError && !checked ? "text-[#D92D20]" : ""}`}>{label}</span>
                {isDisabled && <span className={`ms-2 coming-soon`}>{"(coming soon)"}</span>}
            </span>
        </label>
    );
};

export default Checkbox;

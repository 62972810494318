import React, { useState } from 'react';
import Modal from './modal/Modal';
import FlagIcon from '../../icons/FlagIcon';
import CrossIcon from '../../icons/CrossIcon';
import Checkbox from '../../components/Checkbox/Checkbox'
import SecondaryButton from '../../components/buttons/SecondaryButton'
import PrimaryButton from '../../components/buttons/PrimaryButton'


const Disclaimer = ({ open, setOpen, toggle, onClick, disable, primaryButtonLabel=null }) => {

  const [checkboxError, setCheckboxError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [checked, setchecked] = useState(false)

  return (
    <div className="p-4">
      <Modal onClose={()=>setOpen(false)} open={open}>
        <div className="flex flex-col gap-8  xl:w-[35vw] lg:w-[40vw] md:w-[60vw] sm:w-[70vw] w-[90vw] max-h-[90vh]">
          <div className="flex flex-row justify-between">
            <FlagIcon />
            <button onClick={() => setOpen(false)}>
              <CrossIcon />
            </button>
          </div>
          <div className="flex flex-col gap-4 h-full overflow-y-auto overflow-x-hidden">
            <p className="font-semibold text-2xl">Disclaimer</p>
            <div className="flex flex-col gap-2">
              <p className="text-slate-600 text-lg">
                I confirm that all students whose creative works are being submitted have obtained the necessary consents through signed waivers by their parents or guardians, permitting their participation and the use of their creative works as outlined by swiirl’s terms and conditions. <br /><br />
                Originality Validation: I certify that the submitted creative works are original, created by the students listed, and have not been plagiarized from any other sources. Each piece of work accurately reflects the effort and creativity of the student(s) named in the submission.<br /><br />
                Compliance with Guidelines: I ensure that all submitted content complies with the ethical standards and guidelines provided by Swiirl, including respect for intellectual property and adherence to the thematic directions provided for the project.<br /><br />
                By checking this box and proceeding with the submission, I acknowledge my responsibility for the accuracy of the information provided and the originality of the creative works. I understand that this validation is crucial for maintaining the integrity of the projects and the trust of the brands involved.              </p>
              {/* <p className="text-slate-600 text-lg">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem commodi perferendis aperiam voluptas aliquid? Aut sed est cupiditate. Est incidunt placeat animi laudantium magni unde adipisci ducimus voluptatibus id reiciendis?
              </p>
              <p className="text-slate-600 text-lg">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem commodi perferendis aperiam voluptas aliquid? Aut sed est cupiditate. Est incidunt placeat animi laudantium magni unde adipisci ducimus voluptatibus id reiciendis?
              </p> */}
            </div>
            <div className="flex items-center">
              <Checkbox
                name={'disclaimer'} checkboxError={checkboxError} isSubmitting={loading} checked={checked} onChange={() => setchecked(!checked)} className={`${!checked ? "text-black" : "text-black"} mb-1`} label={'I agree with the above'} />
            </div>
            <div className="flex flex-row gap-4">
              <SecondaryButton text={'Cancel'} disabled={loading ? true : false} onClick={toggle} className='border-1 border-silver font-semibold shadow-sm rounded-lg px-3 w-full' />
              <PrimaryButton onClick={onClick} disabled={!checked || disable} text={`${primaryButtonLabel || 'Submit' || 'Add to project gallery'}`} className='w-full font-semibold' />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Disclaimer;

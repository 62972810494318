import React from 'react'

const GrayCameraIcon = ({size}) => {
    return (
        <svg width={size||"60"} height={size||"60"} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="30.293" cy="30.2827" r="29.5645" fill="#F2F4F7" />
            <path d="M17.793 25.7542C17.793 25.3163 17.793 25.0974 17.8112 24.913C17.9875 23.1343 19.3946 21.7272 21.1732 21.551C21.3576 21.5327 21.5884 21.5327 22.0499 21.5327C22.2278 21.5327 22.3167 21.5327 22.3922 21.5281C23.3562 21.4698 24.2004 20.8613 24.5606 19.9652C24.5889 19.895 24.6152 19.8159 24.668 19.6577C24.7207 19.4995 24.7471 19.4204 24.7753 19.3502C25.1355 18.4541 25.9797 17.8457 26.9437 17.7873C27.0192 17.7827 27.1026 17.7827 27.2694 17.7827H33.3165C33.4833 17.7827 33.5667 17.7827 33.6422 17.7873C34.6062 17.8457 35.4504 18.4541 35.8106 19.3502C35.8389 19.4204 35.8652 19.4995 35.918 19.6577C35.9707 19.8159 35.9971 19.895 36.0253 19.9652C36.3855 20.8613 37.2297 21.4698 38.1937 21.5281C38.2692 21.5327 38.3582 21.5327 38.536 21.5327C38.9975 21.5327 39.2283 21.5327 39.4127 21.551C41.1914 21.7272 42.5985 23.1343 42.7747 24.913C42.793 25.0974 42.793 25.3163 42.793 25.7542V35.5327C42.793 37.6329 42.793 38.683 42.3842 39.4852C42.0247 40.1908 41.451 40.7645 40.7454 41.124C39.9433 41.5327 38.8932 41.5327 36.793 41.5327H23.793C21.6928 41.5327 20.6427 41.5327 19.8405 41.124C19.1349 40.7645 18.5612 40.1908 18.2017 39.4852C17.793 38.683 17.793 37.6329 17.793 35.5327V25.7542Z" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M30.293 35.9077C33.0544 35.9077 35.293 33.6691 35.293 30.9077C35.293 28.1463 33.0544 25.9077 30.293 25.9077C27.5315 25.9077 25.293 28.1463 25.293 30.9077C25.293 33.6691 27.5315 35.9077 30.293 35.9077Z" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default GrayCameraIcon
import React, { useContext, useState } from "react"
import CrossIcon from "../../icons/CrossIcon";
import SelectDropDown from "../../components/dropDown/SelectDropDown";
import LeftArrowIcon from "../../icons/LeftArrowIcon1";
import { useNavigate } from "react-router-dom";
import { getLocalStorage } from "../../lib/session";
import useToast from "../../hooks/useToast";
import { SidebarContext } from "../../App";
import { sendInvitationMailToBrandUsers } from "../../services/brandServices";
import { useSelector } from "react-redux";
import { filterEmailsByDomain } from '../../utils/filterEmailByDomain'
import { sendInvitationMailToSchoolUsers } from "../../services/schoolServices";
import { validateEmail } from "../../utils/validateEmail";
function InviteTeammates() {
    const [emails, setEmails] = useState([])
    const [emailValue, setEmailValue] = useState('')
    const navigate = useNavigate()
    const [disableCopySection, setDisableCopySection] = useState(true)
    const { setGlobalLoader } = useContext(SidebarContext)
    const showToast = useToast()
    const [links, setLinks] = useState([
        // { value: "invitationLink", label: "email" }
    ])
    const [copyText, setCopyText] = useState('')
    const {username:authMail,userType} = useSelector(state => state.user)
    const OrgName = `@${authMail?.split('@')[1]}`

    const handleDropdownChange = (e) => {
        const value = e.target.value;
        setCopyText(value)
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(copyText).then(() => {
            showToast("Link copied successfully", "success")
        }).catch(err => {
            console.error('Failed to copy value to clipboard: ', err);
            showToast("Error in copying link", "error")
        });
    }

    const handleSendInvitation = async () => {
        if (!authMail?.length || !emails.length) {
            showToast("Organization email and invite mails are required")
            return
        }

        try {
            setGlobalLoader(true)
            const payload = {
                "emails": emails,
                "invitedBy": authMail
            }
            const invalidMails = filterEmailsByDomain(payload)
            if (invalidMails?.length) {
                showToast(`The following emails does not belong to the ${OrgName} organization: ${(invalidMails||[]).join(', ')} `, 'error')

                return
            }
            
            let res
            if(userType === 'brand'){
                res = await sendInvitationMailToBrandUsers({ payload })
            } else if (userType === 'school') {
                res = await sendInvitationMailToSchoolUsers({ payload })
            }
            const invitedMails = res.map(item=>item.label)
            const failedInviteMails = emails.filter(item=>!invitedMails.includes(item))
            setEmails(prev=>prev.filter(item=>failedInviteMails.includes(item)))
            showToast("Invitation link sent", "success")
            setLinks(res)
            setDisableCopySection(false)
            if(!failedInviteMails.length){
                return true
            } 
            showToast(`Invitation failed for: ${failedInviteMails.join(', ')}`, "error")
            return false
        } catch (e) {
            console.log(e, "err in inviteTeammates")
            showToast('Something went wrong', 'error')
        } finally {
            setGlobalLoader(false)
        }
    }

    return (
        <div className="w-[512px] ms-8 flex flex-col gap-6 mt-10">
            <div className="w-full flex flex-col">
                <div className="flex flex-col gap-1">
                    <p className="text-2xl font-bold flex flex-row gap-3 items-center">
                        <button onClick={() => navigate(-1)} >
                            <LeftArrowIcon />
                        </button>
                        Invite people
                    </p>
                    <p className="text-slate-600 text-lg">Invite your team members to {<span className="font-bold" >{OrgName}</span> || 'your workspace'} </p>
                </div>
            </div>
            <div className='flex gap-4 w-full flex-col '>
                <div className="flex flex-col">
                    <p className="mb-1 text-slate-700">Invite with email</p>
                    <div className='border-1 min-h-[20vh] p-3 '>
                        <div className='flex flex-wrap gap-3'>

                            {
                                emails && emails.map((item, index) => {
                                    return (
                                        item &&
                                        <div className='rounded-lg border-1 p-2 flex items-center' key={index}>
                                            <span>
                                                {item}
                                            </span>
                                            <span className='w-[20px] ms-2 cursor-pointer  ' onClick={() => {
                                                setEmails(emails.filter((itesm, ind) => ind !== index));
                                                setLinks(emails.filter((itesm, ind) => ind !== index));

                                            }}>
                                                <CrossIcon />
                                            </span>
                                        </div>
                                    )
                                })
                            }
                            <input className='outline-none	 border-b-2 '
                                onChange={(e) => setEmailValue(e.target.value)}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter' && event.target.value) {
                                        const emailValue = event.target.value?.trim()
                                        if (validateEmail(emailValue)) {
                                            setEmails([...emails, emailValue || ""])
                                            setEmailValue('')
                                        }
                                        else {
                                            showToast("Enter Valid email.", 'error')
                                        }

                                    }
                                }}

                                value={emailValue || ""}

                                placeholder='Enter email' />
                        </div>
                    </div>
                </div>
                <button onClick={handleSendInvitation} className="rounded-md text-violet border-purple-400 border-1 font-semibold px-4 py-2 w-fit ms-auto">
                    Send
                </button>

            </div>
            {/* <div className={`w-full h-full ${disableCopySection && 'cursor-not-allowed'}`}>
                <div className={`flex flex-col gap-4 ${disableCopySection && ' pointer-events-none'}`}>
                    <div className="flex flex-col w-full">
                        <p className="mb-1 text-slate-700">Invite with link</p>
                        <SelectDropDown
                            className="w-full"
                            options={[...links]}
                            onChange={handleDropdownChange}
                        />
                        <p className="text-slate-600 text-sm mt-1">Only people within your organization can join with this link</p>
                    </div>
                    <button onClick={handleCopyLink} className="button-secondary-sw text-primary-black w-fit ms-auto">
                        Copy link
                    </button>
                </div>
            </div> */}
        </div>
    );
}

export default InviteTeammates;

import PrivateRoute from "../HOC/PrivateRouteLayout";
import AdminHomePage from "../pages/admin";
import AdminPage from "../pages/admin/Admin";
import CommissionDetailPage from "../pages/admin/Admin-comm-details";
import BrandList from "../pages/admin/BrandList";
import CreatorList from "../pages/admin/CreatorList";
import SchoolList from "../pages/admin/SchoolList";
import RootLayout from "../pages/Root";

const adminRoutes = [

    {
      path: "/admin",
      element: (
        <PrivateRoute>
          <RootLayout>
            <AdminHomePage />
          </RootLayout>
        </PrivateRoute>
      ),
    },
    {
      path: "/admin/school-commission",
      element: (
        <PrivateRoute>
          <RootLayout>
            <AdminPage />
          </RootLayout>
        </PrivateRoute>
      ),
    },
    {
      path: "/admin/commission/:id",
      element: (
        <PrivateRoute>
          <RootLayout>
            <CommissionDetailPage />
          </RootLayout>
        </PrivateRoute>
      ),
    },
    {
      path: "/admin/BrandList",
      element: (
        <PrivateRoute>
          <RootLayout>
            <BrandList />
          </RootLayout>
        </PrivateRoute>
      ),
    },
    {
      path: "/admin/SchoolList",
      element: (
        <PrivateRoute>
          <RootLayout>
            <SchoolList />
          </RootLayout>
        </PrivateRoute>
      ),
    },
    {
      path: "/admin/CreatorList",
      element: (
        <PrivateRoute>
          <RootLayout>
            <CreatorList />
          </RootLayout>
        </PrivateRoute>
      ),
    },
  ];

  export default adminRoutes;
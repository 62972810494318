import { combineReducers } from "redux";
import userReducer from "./reducerFiles/userreducer";
import galleryReducer from "./reducerFiles/galleryReducer";
import creatorCollectionReducer from "./reducerFiles/creatorCollectionReducer";

const rootReducer = combineReducers({
  user: userReducer,
  galleryData: galleryReducer,
  creatorCollection: creatorCollectionReducer,
});

export default rootReducer;

import axios from "axios"
import { PostWithToken } from "../lib/axios"


export const supportRequest = async (payload) => {
    try {
        let response = await PostWithToken({baseUrl:"http://localhost:4500",endpoint:`/support-request`,data: payload})
        return response.data
    } catch (error) {
        console.error(error)
        throw error

    }
}
import React from "react";

const TimelineIcon = () => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="calendar">
        <path
          id="Icon"
          d="M27.1641 12.6221H4.66406M20.9141 2.62207V7.62207M10.9141 2.62207V7.62207M10.6641 27.6221H21.1641C23.2643 27.6221 24.3144 27.6221 25.1165 27.2133C25.8221 26.8538 26.3958 26.2801 26.7553 25.5745C27.1641 24.7724 27.1641 23.7223 27.1641 21.6221V11.1221C27.1641 9.02187 27.1641 7.97177 26.7553 7.16961C26.3958 6.464 25.8221 5.89032 25.1165 5.5308C24.3144 5.12207 23.2643 5.12207 21.1641 5.12207H10.6641C8.56387 5.12207 7.51377 5.12207 6.7116 5.5308C6.00599 5.89032 5.43231 6.464 5.07279 7.16961C4.66406 7.97177 4.66406 9.02187 4.66406 11.1221V21.6221C4.66406 23.7223 4.66406 24.7724 5.07279 25.5745C5.43231 26.2801 6.00599 26.8538 6.7116 27.2133C7.51377 27.6221 8.56387 27.6221 10.6641 27.6221Z"
          stroke="#7F56D9"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

export default TimelineIcon;

import { useContext, useState } from "react";
import RadioButton from "../../components/RadioButton/RadioButton";
import LoginFooter from "../../components/LoginFooter";
import useQueryParam from "../../hooks/useQuery";
import { useAuthProvider } from "../../contexts/AuthContext";


const registrationOptions = [{ label: "Brand or marketing agency", value: 'brand' }, { label: "School/nonprofit/community-based org", value: "school" }, { label: 'Independent artist/photographer', value: 'creator', isDisabled: false }]


function SignupStartScreen() {
    // const { getQueryParam, setQueryParams } = useQueryParam();
    const { signupData, changeSignupData } = useAuthProvider()

    const [selectedOption, setSelectedOption] = useState("") // user type
    const [referral, setReferral] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false)



    const checkFormValidation = () => {
        if (!selectedOption) {
            setError("Please select an option.");
            return false;
        }

        if (selectedOption === "brand" && referral !== "swiirlbrand1234!") {
            setError("Please enter the correct invitation code.");
            return false;
        }

        if (selectedOption === "creator" && !referral) {
            setError("Please enter the name of your referral, or enter None");
            return false;
        }

        // if (!["brand", "creator"].includes(selectedOption)) {
        //     setError("Please select a correct option.");
        //     return false;
        // }

        return true; // All validations pass
    };

    // Change screen and update query parameters
    const changeScreen = (newScreen) => {
        const isValid = checkFormValidation(); // Validate the form

        if (isValid) { // Only update query parameters if validation passes

            changeSignupData({
                screenType: "main",
                userType: selectedOption,
                code: referral,
                screenNo: 1,
            })
            // setQueryParams({
            //     userType: selectedOption,
            //     code: referral,
            //     screen: 1,
            // });
        };

    }

    const handleChange = (e) => {
        setSelectedOption(e.target.value);
        setError('')
        setReferral('')
    };
    return (

        <div className="loginform">
            <div className="login-form-container">
                <div className="login-form-headers text-center">
                    <div className="login-text">Sign up</div>
                    <div className="login-desc">
                        Welcome! Please select your account type
                    </div>
                </div>

                <div className="min-h-[25vh] pt-[12px]">

                    {
                        registrationOptions.map((opt) => {
                            return (
                                <div className="mt-[20px] ps-2" key={opt.value}>
                                    <RadioButton
                                        name="options"
                                        value={opt.value}
                                        checked={selectedOption === opt.value}
                                        onChange={handleChange}
                                        label={opt.label}
                                        isDisabled={opt?.isDisabled || ""}
                                    />
                                </div>
                            )
                        })
                    }
                    <div className="py-[30px] px-2">
                        {
                            selectedOption !== "school" &&
                            <>
                                {selectedOption !== "creator" ? <div className="login-form-row">
                                    <div className="login-form-group">
                                        <label className="form-label">Invitation Code</label>
                                        <input
                                            type="text"
                                            className="form-control-input"
                                            placeholder="Enter your invitation code"
                                            value={referral}
                                            onChange={(e) => setReferral(e.target.value)}
                                        />
                                    </div>
                                </div> : <div className="login-form-row">
                                    <div className="login-form-group">
                                        <label className="form-label">Referred by:</label>
                                        <input
                                            type="text"
                                            className="form-control-input"
                                            placeholder="Enter the name of your referral, or enter None"
                                            value={referral}
                                            onChange={(e) => setReferral(e.target.value)}
                                        />
                                    </div>
                                </div>}
                            </>
                        }
                        
                    </div>
                    {error && (
                        <div className="login-form-row">
                            <div className="login-form-group">
                                <div class="alert alert-danger">{error}</div>
                            </div>
                        </div>
                    )}

                </div>

                <div className="login-form-button mt-3">
                    <div className="login-form-group">
                        <button
                            onClick={changeScreen}
                            disabled={loading}
                            className={`button-primary-sw-100 btn-center  ${loading ? 'cursor-progress' : ''}`}
                        >
                            Next
                        </button>
                    </div>
                </div>
                <div className="form-row-link">
                    <div className="form-group-register">
                        <LoginFooter />
                    </div>
                </div>
            </div>
        </div>

    );
}


export default SignupStartScreen

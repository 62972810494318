import Spinner from "react-bootstrap/Spinner";
import CrossIcon from "../../../icons/CrossIcon"

const Popup = ({ togglePopup = () => null, loading = false, type = 'alert', children, handleConfirm = () => null, isOpen=false,secondayButtonText='Close',primaryButtonText='Purchase' }) => {
    if(!isOpen) return;
    return (
        <>
            <div
                className="fixed inset-0 bg-black bg-opacity-50 z-40"
                onClick={togglePopup}
            ></div>
            <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="bg-white rounded shadow-lg p-4  max-w-[650px] min-w-[30%]">
                    <div className="flex justify-content-end mb-4">
                        <span className="cursor-pointer w-[30px]" onClick={togglePopup}>

                            <CrossIcon  />
                        </span>
                    </div>
                    {children}
                    <div className="w-full flex gap-4">
                        <button
                            onClick={togglePopup}
                            className={`mt-4 px-4 py-2 w-1/2  border-[1px] rounded ${type !== 'confirm' ? 'bg-violet text-white w-full' : 'text-black bg-white'}`}
                        >
                            {secondayButtonText}
                        </button>
                        {
                            type === 'confirm' &&
                            <button
                                onClick={handleConfirm}
                                className="mt-4 px-4 py-2 w-1/2 bg-violet text-white rounded"
                                // disabled={(loading||primaryButtonText=="Download now")?true:false}
                                disabled={loading}
                            >
                                {loading ? <Spinner animation="border" size="sm" /> : primaryButtonText}
                            </button>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Popup;
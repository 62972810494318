import React from 'react'

const ShopIcon = () => {
    return (
        <svg className="bx bx-pie-chart-alt icon"
        width="26"
        height="26"
        viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1993 6.97656C14.1993 8.03743 13.7779 9.05484 13.0277 9.80499C12.2776 10.5551 11.2602 10.9766 10.1993 10.9766C9.13843 10.9766 8.12101 10.5551 7.37087 9.80499C6.62072 9.05484 6.1993 8.03743 6.1993 6.97656M1.83251 6.37795L1.13251 14.7779C0.982137 16.5825 0.906948 17.4847 1.21198 18.1807C1.47998 18.7923 1.94432 19.2969 2.53148 19.6148C3.19977 19.9766 4.10516 19.9766 5.91593 19.9766H14.4827C16.2934 19.9766 17.1988 19.9766 17.8671 19.6148C18.4543 19.2969 18.9186 18.7923 19.1866 18.1807C19.4916 17.4847 19.4165 16.5825 19.2661 14.7779L18.5661 6.37795C18.4367 4.82531 18.372 4.049 18.0282 3.46142C17.7254 2.944 17.2746 2.52917 16.7338 2.27041C16.1197 1.97656 15.3407 1.97656 13.7827 1.97656L6.61593 1.97656C5.05792 1.97656 4.27891 1.97656 3.66481 2.27041C3.12404 2.52916 2.67319 2.944 2.37042 3.46142C2.02659 4.04899 1.9619 4.82531 1.83251 6.37795Z" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default ShopIcon
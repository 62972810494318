import { Link } from "react-router-dom"

const BackNavigator = ({text="",link}) => {
    return (
        <div className="text-left py-[20px] px-[30px]">
            <Link to={link||"/"} className="flex gap-[6px] items-center text-violet no-underline">
                <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g id="arrow-left">
                        <path
                            id="Icon"
                            d="M16.0436 10.8603H4.37695M4.37695 10.8603L10.2103 16.6936M4.37695 10.8603L10.2103 5.02698"
                            stroke="#6941c6"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </g>
                </svg>
                {text}
            </Link>
        </div>
    )
}

export default BackNavigator;
import React, { useRef } from 'react';
import AddRounded from '../../../../icons/AddRounded';
import { renderPreview } from '../../../school/upload-project-content/helper';

function ManageThumbnail({
    thumbnails, setThumbnails, videoIndex, isVideo, fileName,disabledThumnailChange=false
}) {
    const objectPreviewRef = useRef()
    if (!isVideo) return null;

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        const allowedExtensions = new Set(['png', 'svg', 'gif', 'jpeg', 'jpg']);
        const files = newFiles.filter((file) => {
            let extension = file.name?.split('.').pop().toLowerCase();
            return allowedExtensions.has(extension);
        });

        if (newFiles.length !== files.length) {
            alert("Only png, svg, gif, and jpeg files are allowed");
        }
        if (!files.length) return;
        objectPreviewRef.current = URL.createObjectURL(files[0])
        setThumbnails((prev) => ({
            ...prev,
            [fileName]: files[0] // Set the thumbnail for the specific video index
        }));
    };

    const thumbnail = thumbnails?.[fileName];

    
    return (
        <div className='w-full flex flex-col'>
            <label 
                className="ml-8 flex flex-row items-center gap-2 w-fit cursor-pointer" 
                htmlFor={`upload-thumbnail-${fileName}`}
            >
                <input
                    type="file"
                    id={`upload-thumbnail-${fileName}`} // Unique ID for each input
                    accept="image/*"
                    className='hidden'
                    onChange={handleFileChange}
                    disabled={disabledThumnailChange}
                />
                {thumbnail ? (
                    <>
                        <p>Thumbnail: </p>
                        <p className='text-sm text-slate-800'>{thumbnail?.name}</p>
                    </>
                ) : (
                    <>
                        <p><AddRounded className="w-[15px]" /></p>
                        <p className='text-sm text-slate-800'>Add video thumbnail (png, jpg or jpeg)</p>
                    </>
                )}
            </label>
            {
                thumbnail && (thumbnail?.url||objectPreviewRef.current) &&
                renderPreview({file:thumbnail,previewObject:thumbnail?.url||objectPreviewRef.current})
            }
        </div>
    );
}

export default ManageThumbnail;

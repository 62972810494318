import React, { useRef } from 'react';

const ScrollToTop = ({ children, buttonClassname, scrollBehavior = 'smooth' ,isLoading = false}) => {
    // Create a ref to store the top element
    const topRef = useRef(null);

    // Function to scroll to the top
    const scrollToTop = () => {
        if (topRef.current) {
            topRef.current.scrollIntoView({ behavior: scrollBehavior });
        }
    };

    return (
        <div className='w-full h-full' >
            <div ref={topRef} />

            {/* Content to create a scrollable page */}
            {children}

            {/* Top of the page, referenced by the ref */}
            {/* Button or link to trigger the scroll */}
            {!isLoading && (
            <div className={`${buttonClassname} flex justify-center mt-4`}><button className="border border-slate-600 rounded-lg px-3 py-2" onClick={scrollToTop} >Back to top</button></div>
            )}
            </div>
    );
};

export default ScrollToTop;

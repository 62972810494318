import CreateCommissionForm from "../../../components/CreateCommissionForm";

import image1 from '../../../images/leftSidebar/Image-1.png';
import image2 from '../../../images/leftSidebar/Image-2.png';
import image3 from '../../../images/leftSidebar/Image-3.png';
import image4 from '../../../images/leftSidebar/Image-4.png';

function CommissionPage() {
  return (
    <div className="flex custom-max-width">
      <div className="flex-grow ">
        <CreateCommissionForm />
      </div>
      <div className="max-w-[340px] min-h-[80vh] border-s-[0.5px] border-[rgba(172, 172, 172, 1)] ">
        <div className="">
          <div className="image-container">
            <img
              src="/home/header.png"
              alt="Home Page Banner"
              className="img-banner img-fluid"
            />
            <div className="circle">
              <img src="/home/circle2.png" alt="Circle Page Banner" />
            </div>
          </div>

          <div className="flex flex-col gap-5 mt-[5rem] px-[50px]  w-full items-center">
            {
              [
                image1,
                image2,
                image3,
                image4,
              ].map((item, index) => {
                return <img src={item} key={index + item} alt="item" className="w-[240px] h-[240px]" />
              })
            }
          </div>


        </div>
      </div>
    </div>
  );
}

export default CommissionPage;

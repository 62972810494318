import React from 'react'

function CircularLoader({className=""}) {
    return (
        <div className={`${className}  flex justify-center items-center`}>
            <img
                src="/Loader/ImageLoader.svg"
                className="loader-middle"
                alt="Loading"
            />
        </div>
    )
}

export default CircularLoader
import { useNavigate } from "react-router-dom";
import "./LoginLogo.css";
function LoginLogo() {
  const navigate=useNavigate()
  return (

    <img
      alt="swiirl-logo"
      className="swiirl-logo cursor-pointer"
      src="/Login/swiirl-logo.png"
      onClick={()=>navigate('/')}
    />
  );
}
export default LoginLogo;

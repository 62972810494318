import React, { useContext, useState } from 'react'
import GoogleIcon from '../../icons/GoogleIcon'
import CommonHeading from './CommonHeading'
import InputField from '../form-elements/InputField'
import PrimaryFilledBtn from '../form-elements/PrimaryFilledBtn'
import { Link, useNavigate } from 'react-router-dom'
import CrossIcon from '../../icons/CrossIcon'
import { authenticateUser, checkUserEmail } from '../../services/aws/AwsService'
import { setLocalStorage } from '../../lib/session'
// import { login } from '../../services/brandServices'
import { useDispatch } from 'react-redux'
import useToast from '../../hooks/useToast'
import { loginSuccess, profile } from '../../redux/actions'
import { SidebarContext } from '../../App'
import GoogleAuth from './GoogleAuth'
import AuthLayout from '../../HOC/AuthLayout'
import { login } from '../../services/AuthServices'
// import checkRestrictedEmailDomains from '../../utils/checkRestrictedEmailDomains'
// import ConfirmationCodeForm from '../ConfirmationCodeForm'
// import { creatorLogin } from '../../services/creatorService'








const SCREENS = {
    ENTER_EMAIL: 'ENTER_EMAIL',
    ENTER_PASSWORD: 'ENTER_PASSWORD',
}


function BrandLogin() {
    const [currentState, setCurrentState] = useState({
        screen: SCREENS.ENTER_EMAIL,
        data: {}
    })




    return (
        <AuthLayout>
            <div className="loginform">
                <div className="login-form-container">
                    {
                        SCREENS.ENTER_EMAIL === currentState.screen &&
                        <EnterEmail currentState={currentState} setCurrentState={setCurrentState} />
                    }
                    {
                        SCREENS.ENTER_PASSWORD === currentState.screen &&
                        <EnterPassword currentState={currentState} setCurrentState={setCurrentState} />
                    }
                </div>
            </div>
        </AuthLayout>

    )
}

export default BrandLogin


const EnterEmail = ({ setCurrentState, currentState }) => {
    const showToast = useToast()
    const [email, setEmail] = useState("")
    const navigate = useNavigate()
    const { setGlobalLoader } = useContext(SidebarContext)
    const [loading, setLoading] = useState(false)
    const handleContinue = async (e) => {
        e.preventDefault()
        // important code ---------
        // const emailCheck = await checkRestrictedEmailDomains(email)
        // if (emailCheck) {
        //     showToast("Please provide your work email address.", 'error')
        //     return
        // }
        // ----------
        checkUserExisted()
        // setCurrentState({
        //     screen: SCREENS.ENTER_PASSWORD,
        //     data: {
        //         email
        //     }
        // })
    }
    const handleEmail = (email_) => {
        let newEmail = email_.trim()
        setEmail(newEmail)
    }
    const checkUserExisted = async () => {
        setGlobalLoader(true)
        setGlobalLoader(true)
        setLoading(true)
        const res = await checkUserEmail(email)
        const { emailExists, emailVerified } = res
        if (emailExists && emailVerified) {
            setCurrentState({
                screen: SCREENS.ENTER_PASSWORD,
                data: { email }
            })
        }
        else {
            showToast("User not found.", 'error')
        }
        setGlobalLoader(false)
        setLoading(false)
    }

    return (

        // <LoginLogo />

        // {/* <div className="w-full min-h-screen">

        //     <div className="flex flex-col sm:py-4 sm:px-6 w-full"> */}

        <div className="w-full flex items-center flex-col">
            <div className="mt-28s flex flex-col gap-4 w-[400px] items-center">
                <CommonHeading backBtnClick={() => navigate(-1)} header={'Welcome to Swiirl'} subHeading={'To get started, please sign in'} />
                <form onSubmit={(e) => handleContinue(e)} className="flex flex-col gap-2 w-full mt-2">
                    <InputField value={email} onChange={e => handleEmail(e.target.value)} name="email" labelClass='mb-2' label="Email*" placeholder='Enter your work email' type='email' />
                    <PrimaryFilledBtn type='submit' label={loading ? "Checking..." : 'Continue'} className='mt-3 ' />
                </form>
                <p className="font-semibold text-lg -mb-1">OR</p>
                <GoogleAuth
                    buttonClass='flex flex-row justify-center items-center gap-2 w-full py-2 rounded-md border-1 border-gray-400 cursor-pointer font-bold'
                    text="Continue with your Google work account"
                    icon={<GoogleIcon />}
                    type="login"
                />
                <p className="text-lg mt-2">Don't have an account? <Link to='/registration?screen=start'><span className="text-violet">Register now!</span> </Link> </p>

            </div>
        </div>


    )
}


function EnterPassword({ setCurrentState, currentState }) {
    const [password, setPassword] = useState("");
    const dispatch = useDispatch()
    const showToast = useToast()
    const { refresh, setRefresh, setGlobalLoader } = useContext(SidebarContext)

    const [userConfirmed, setUserConfirmed] = useState(false);


    const [error, setError] = useState();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)




    const handleLogin = async () => {
        // Validation checks
        if (currentState?.data?.email === "") {
            setError("Email cannot be empty.");
            return;
        }
        if (password === "") {
            setError("Password cannot be empty.");
            return;
        }


        try {
            setLoading(true);
            setGlobalLoader(true)
            // Call the AWS Cognito authentication function
            const result = await authenticateUser(currentState.data.email, password);
            // console.log(result?.idToken?.payload?.locale, 'result');

            const userRole = result?.idToken?.payload?.locale || "brand"

            // if (userRole !== "brand" && userRole !== "creator") {
            //     showToast('No brand registor  by this email.', 'error')
            //     setGlobalLoader(false)
            //     return
            // }


            // Handle the authentication result
            if (!result.isConfirmed && !result.isAuthenticated) {
                console.log("User is not confirmed.");
                setError("User confirmation is required to log in.");
                // Handle logic for obtaining the confirmation code
            } else if (result.isConfirmed && result.isAuthenticated) {
                setUserConfirmed(true);
                let userData = {}

                userData = await login({ jwtToken: result.idToken.jwtToken }) // verify and create token from backend
                let dispatchData = {
                    userId: userData.user_id,
                    token: userData.token,
                    userRole,
                    username: currentState.data.email,
                    isOwner: userData?.isOwner,
                    role: userData?.role,
                    userType:userData?.userType
                }

                if (userRole === "brand") {
                    dispatchData = {
                        ...dispatchData,
                        brandId: userData?.brandId
                    }
                }
                else if (userRole === "school") {
                    dispatchData = {
                        ...dispatchData,
                        schoolId: userData?.schoolId,
                        isNew: userData?.isNew,
                        isPolicyAccepted: userData?.isPolicyAccepted,
                    }
                }
                else if (userRole === "creator") {
                    dispatchData = {
                        ...dispatchData,
                        creatorId: userData?.creatorId
                    }
                }
                

                dispatch(loginSuccess({ ...dispatchData }))
                dispatch(profile(userData?.profileImg || ""));
                // dispatch(loginSuccess({ userId: userData.user_id, token: userData.token, userRole, username: currentState.data.email, isOwner: userData?.isOwner, role: userData?.role }));
                setLocalStorage("token", JSON.stringify(userData.token));
                setLocalStorage("userRole", userRole);


                // dispatch(loginSuccess({ userId: userData.user_id, token: userData.token, userRole, username: currentState.data.email, isOwner: userData?.isOwner, role: userData?.role,userType:userData?.userType }));

                // dispatch(profile(userData?.profileImg || ""));

                showToast("Login successful", 'success')
                setGlobalLoader(false)
                setRefresh(!refresh)
                navigate("/");

            } else {
                setUserConfirmed(false);
                setGlobalLoader(false);
                setError("Invalid email or password.");
            }
        } catch (error) {
            setGlobalLoader(false)
            console.error("Error during authentication:", error);
            // Handle error as needed
        } finally {
            setLoading(false);
        }

    }

    const goBack = () => {
        setCurrentState({
            screen: SCREENS.ENTER_EMAIL,
            data: {
                email: currentState.data.email,

            }
        })
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        // <div className="w-full min-h-screen">
        //     <div className="flex flex-col sm:py-4 sm:px-6 w-full">
        //         <LoginLogo />
        <div className="w-full flex items-center flex-col">
            <div className="mt-28s flex flex-col gap-4 w-[400px] items-center">
                <CommonHeading backBtnClick={goBack} />
                <div className="flex flex-col gap-2 w-full mt-2">
                    <div className='flex px-4 flex-row justify-end items-center gap-2 w-full py-2 rounded-md border-1 border-gray-400  font-bold ' >
                        <span className="text-center w-full">{currentState.data.email}</span>
                        <button onClick={() => setCurrentState({ screen: SCREENS.ENTER_EMAIL, data: {} })} className="w-4 h-4 flex items-center justify-center rounded-full bg-slate-200">
                            <CrossIcon />
                        </button>
                    </div>
                </div>

                <div className="flex flex-col gap-2 w-full mt-2">
                    <InputField onKeyDown={handleKeyPress} value={password} onChange={e => setPassword(e.target.value)} name="password" label="Password*" placeholder='✷✷✷✷✷✷✷✷✷✷' type='password' />

                    <div className='flex justify-between items-center'>
                        {/* <Link className="button-links" >
                            <p className='text-slate-600'>I have a confirmation code</p>
                        </Link> */}
                        <Link className=''>
                            <p className="text-slate-600" onClick={() => navigate('/forgot-password')}>Forgot your password?</p>
                        </Link>
                    </div>
                    <PrimaryFilledBtn disabled={loading} label={loading ? 'Logging in ...' : 'Log in'} className='mt-top' onClick={handleLogin} />
                </div>

                {error && (
                    <div className="login-form-row w-full">
                        <div className="login-form-group">
                            <div class="alert alert-danger">{error}</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
        //     </div>

        // </div>
    )
}
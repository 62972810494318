import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { supportRequest } from '../../services/sharedServices';
import useToast from '../../hooks/useToast';
import { validateQuestion } from './helper';
import PrimaryFilledBtn from '../../components/form-elements/PrimaryFilledBtn';


const SchoolSupport = () => {
 // const [email, setEmail] = useState('');
 const showToast=useToast()
  const [question, setQuestion] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [formState, setFormState] = useState({
    isLoading:false,
    isValid:false
  })
  const maxChars = 500;
  const navigate = useNavigate();



  const { username, userId, brandId, userType ,userEmail , ...name} = useSelector((state) => state.user);

  const handleQuestionChagne = (e) => {
    const val = e.target.value
    const form = validateQuestion(val)
    setQuestion(form.data)

    setFormState({
      ...FormData,
      isValid:!form.error
    })
  }

 
  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = validateQuestion(question)

    form.data = form.data.trimEnd();

    if(form.error){
      showToast("Please submit a valid question",'error')
      return 
    }

    setFormState({
      ...formState,
      isLoading:true
    })


    const payload = {
      // userName : username,
      userEmail : username,
      userType,
      userQuestion :form.data     
    };

    try {
      const response = await supportRequest(payload)
      showToast(" Your question/query sent  to  swiil.We will contact you soon.",'success')
       setSubmitted(true);
    } catch (error) {
      console.error('Error:', error);
      showToast('An error occurred. Please try again.','error');
    } finally {
      setFormState({
        isLoading:false,
        isValid:false
      })
    }
    
  };

  // Handler to go back to the previous page
  const handleGoBack = () => {
    navigate( 
      userType === 'brand' ? '/home' : userType === 'creator' ? '/creator' : '/school');
  };

  return (
    <div className="bg-white p-6">
      <h1 className="text-3xl font-bold pt-4">Swiirl Support</h1>

      {submitted ? (
        <div>
          <p className="text-xl font-semibold mt-6">Thank you for your query!</p>
          <button onClick={handleGoBack} className="text-blue-500 underline mt-4">
            ← Go Back
          </button>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="mt-6 space-y-4">
        

          <div>
            <label className="block text-sm font-medium text-gray-700">Question<span className="text-red-500">*</span></label>
            <textarea
              placeholder="Describe your issue in detail"
              value={question}
              onChange={handleQuestionChagne}
              maxLength={maxChars}
              className="w-1/2 border border-gray-300 p-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
              rows={6}
              required
            ></textarea>
            <p className="text-sm text-gray-500 mt-1">
              {question.length}/{maxChars} characters max
            </p>
          </div>

          <div>
            <div className="w-28">
              <PrimaryFilledBtn disabled={!formState.isValid} label={formState.isLoading ? 'Sending...':'Send'} type='submit' />
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default SchoolSupport;

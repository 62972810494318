import React from 'react'

function CheckMark({ className="", color = "#7F56D9" }) {
    return (
        <svg className={className} width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
            d="M11 1.25L4.125 8.125L1 5" 
            stroke={color} 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round" />
        </svg>

    )
}

export default CheckMark;
import React from 'react'

function Calendar() {
    return (
        <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.7358 8.76233H3.39087M14.4733 1.94238V5.35236M7.65334 1.94238V5.35236M7.48284 18.9923H14.6438C16.0761 18.9923 16.7923 18.9923 17.3393 18.7135C17.8206 18.4683 18.2118 18.0771 18.457 17.5958C18.7358 17.0488 18.7358 16.3326 18.7358 14.9003V7.73934C18.7358 6.30702 18.7358 5.59085 18.457 5.04378C18.2118 4.56256 17.8206 4.17131 17.3393 3.92612C16.7923 3.64737 16.0761 3.64737 14.6438 3.64737H7.48284C6.05051 3.64737 5.33435 3.64737 4.78728 3.92612C4.30606 4.17131 3.91481 4.56256 3.66962 5.04378C3.39087 5.59085 3.39087 6.30702 3.39087 7.73934V14.9003C3.39087 16.3326 3.39087 17.0488 3.66962 17.5958C3.91481 18.0771 4.30606 18.4683 4.78728 18.7135C5.33435 18.9923 6.05051 18.9923 7.48284 18.9923Z" stroke="#344054" stroke-width="1.70499" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default Calendar
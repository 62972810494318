import React, { useEffect, useState } from 'react'
import BackNavigator from '../../../components/BackNavigator/BackNavigator';
import LoadingWrapper from '../../../components/loadingWrapper/LoadingWrapper';
import CustomImage from '../../../components/CustomImage/CustomImage';
import { makeGalleryArray } from '../../../utils/galleryUtils';
import { useLocation } from 'react-router-dom';
import NothingToShowForCreator from '../../../components/NothingToShowForCreator';
import VideoPlayer from '../../../components/video/player/VideoPlayer';

const ViewWork = () => {
    const location = useLocation();
    const stateValue = location?.state;
    const [columns, setColumns] = useState([])
    const [loading, setLoading] = useState(false)

    const fetchAllCollection = async () => {
        setLoading(true)
        try {
            setColumns(makeGalleryArray(stateValue?.images || []))
            setLoading(false);
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }

    }

    useEffect(() => {
        fetchAllCollection()
    }, [])




    return (
        <div className="pt-[31px] pb-[50px]">
            <BackNavigator text="Back to profile" link={'/creator/profile'} />
            <div className="px-[30px]" >
                <LoadingWrapper loading={loading}>
                    <div className="flex justify-center items-center py-[28px] flex-col gap-y-12">
                        <h1 className="font-semibold text-[30px] text-primary-black text-center">{stateValue?.userName} store preview</h1>
                        {/* {
                            columns?.length===0&&
                            <NothingToShowForCreator/>
                        } */}
                    </div>


                    <div className="mt-[13px] parent">
                        {
                            columns && columns.length > 0 ?
                                columns.map((column, colIndex) => (
                                    <div className="column" key={colIndex}>
                                        {
                                            column.map((obj, index) => {
                                                return (
                                                    // ${selectedImages.includes(obj.id) ? 'imageSelected' : ''}
                                                    <div className={`image-container1 
                                                `} style={{ minHeight: '400px' }} key={index}>
                                                        {obj?.media_type === 'video' ?
                                                            <VideoPlayer videoUrl={obj.url} src={obj.thumbnailUrl} cacheKey={obj.thumbnailUrl} alt="image details page " imageContainerClass='max-w-[99%]' />
                                                            :
                                                            <CustomImage
                                                                key={index}
                                                                src={obj.url}
                                                                alt={obj.alt || "Image"}
                                                                imageClass="card-image"
                                                                imageContainerClass="girdImage"
                                                            >

                                                            </CustomImage>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                ))
                                :
                                <div className='flex justify-center items-center w-full mt-5'>

                                    <NothingToShowForCreator />
                                </div>
                        }
                    </div>
                </LoadingWrapper>
            </div>
        </div>
    )
}
export default ViewWork
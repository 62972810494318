import ComingSoon from "../pages/ComingSoon";
import RootLayout from "../pages/Root";

 const  commonRoutes = [
    {
      path: "*",
      element: (
        <RootLayout>
          <ComingSoon />
        </RootLayout>
      ),
    },
  ];
  

  export default commonRoutes
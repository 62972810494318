import { useState } from "react";
import TargetLocationIcon from "../../icons/TargetLocation";
import FileIcon from "../../icons/Files";
import moment from "moment";
import MediaTypeIcon from "../../icons/Mediatype";
import TimelineIcon from "../../icons/Timeline";
import { constants } from '../../constants/constants'
import RightArrowIcon from "../../icons/RightArrowIcon";
import { Link } from "react-router-dom";

import CommissionMedia from "../CustomImage/CommissionMedia";

const { commissionStatus } = constants

const CommissionTabView = ({ commissionToDisplay, fromContentPage }) => {
    const [activeTab, setActiveTab] = useState("basicdetails");

    return (
        <>
            {fromContentPage && <div className=" flex flex-col gap-[20px] mb-[46px]" >
                <p className="text-[30px] font-[600] text-primary-black">Your latest commission is being worked on!</p>
                <p className="text-[20px] text-[#475467]">Check back here soon to view your new commission gallery.</p>
            </div>}
            <div className="latest-commission">
                <div className="commission-details">
                    <div className="form-row">
                        {
                            fromContentPage ? null :
                                <>{
                                    commissionStatus.active === commissionToDisplay?.status ?
                                        <div className="statusContainer">
                                            <div className="status completedStatus">COMMISSION READY!</div>
                                            <Link
                                                to={`/comm-galleries?commissionid=${commissionToDisplay?.id}&commissionname=${commissionToDisplay?.name}`}
                                                className="flex gap-[10px] items-center no-underline"
                                            >
                                                <p className="text-[12px] text-success-green font-[500]">You may now view  it</p>
                                                <RightArrowIcon />
                                            </Link>
                                        </div>
                                        :
                                        <div className="form-group" style={{ paddingLeft: "10px" }}>
                                            <div className={`status ${commissionToDisplay?.status === commissionStatus.complete && 'completedStatus'}`}>{commissionToDisplay.status.replace('_', ' ')}</div>
                                        </div>
                                }</>
                        }
                    </div>
                    <div className="form-row">
                        <div className="form-group" style={{ paddingLeft: "10px" }}>
                            <div className="latest-commission-name">
                                {commissionToDisplay.name} details
                            </div>
                            <div className="latest-commission-name-footer">
                                {commissionToDisplay.campaign} What you have submitted to creators
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <div className="tabs">
                                <button
                                    className={activeTab === "basicdetails" ? "active" : ""}
                                    onClick={() => setActiveTab("basicdetails")}
                                >
                                    Basic Details
                                </button>
                                <button
                                    className={activeTab === "goals" ? "active" : ""}
                                    onClick={() => setActiveTab("goals")}
                                >
                                    Goals
                                </button>
                                <button
                                    className={activeTab === "format" ? "active" : ""}
                                    onClick={() => setActiveTab("format")}
                                >
                                    Format
                                </button>
                                <button
                                    className={activeTab === "creativedesign" ? "active" : ""}
                                    onClick={() => setActiveTab("creativedesign")}
                                >
                                    Creative Design
                                </button>
                                <button
                                    className={activeTab === "themesprompts" ? "active" : ""}
                                    onClick={() => setActiveTab("themesprompts")}
                                >
                                    Themes & Prompts
                                </button>
                            </div>
                            {activeTab === "basicdetails" && (
                                <div className="tab-content">
                                    <div className="details-row">
                                        <div className="fields">
                                            <TargetLocationIcon />
                                            <div className="details-right">
                                                <div>
                                                    <strong>Target Location</strong>{" "}
                                                </div>
                                                <div>{commissionToDisplay.target_location}</div>
                                            </div>
                                        </div>
                                        <div className="fields">
                                            <FileIcon />
                                            <div className="details-right">
                                                <div>
                                                    <strong>Links And Files</strong>{" "}
                                                </div>
                                                {/* <div>{commissionToDisplay.files}</div> */}
                                                <div>{commissionToDisplay.links}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="details-row">
                                        <div className="fields">
                                            <TimelineIcon />
                                            <div className="details-right">
                                                <div>
                                                    <strong>Timeline</strong>{" "}
                                                </div>
                                                <div>
                                                    {moment(commissionToDisplay.start_date).format('MMMM DD, YYYY')} -
                                                    {moment(commissionToDisplay.end_date).format('MMMM DD, YYYY')}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="fields  overflow-y-auto no-scrollbar" >
                                            <div className="details-right flex flex-row gap-2">
                                                {
                                                    commissionToDisplay?.images && commissionToDisplay?.images.length > 0 && commissionToDisplay?.images?.map((item) => {
                                                        return (
                                                            <div className="div relative w-full">
                                                                <CommissionMedia
                                                                    mediaData={item}
                                                                    width={"103px"}
                                                                    imageContainerClass="thump-image"
                                                                    imageClass="w-full h-full object-cover"
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="details-row">
                                        <div className="fields">
                                            <MediaTypeIcon />
                                            <div className="details-right">
                                                <div>
                                                    <strong>Media Type</strong>{" "}
                                                </div>
                                                <div style={{ whiteSpace: "pre-wrap" }}>
                                                    {commissionToDisplay.media_type?.replace(/,/g, ",\n")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeTab === "goals" && (
                                <div className="tab-content">
                                    <p>
                                        <strong>Content Usage:</strong>{" "}
                                        {commissionToDisplay.content_usage}
                                    </p>
                                    <p>
                                        <strong>Target Audience:</strong>{" "}
                                        {commissionToDisplay.target_audience}
                                    </p>
                                    <p>
                                        <strong>Tags:</strong> {commissionToDisplay.tags}
                                    </p>
                                    <p>
                                        <strong>Campaign Goal:</strong>{" "}
                                        {commissionToDisplay.campaign_goal}
                                    </p>
                                </div>
                            )}
                            {activeTab === "format" && (
                                <div className="tab-content">
                                    <p>
                                        <strong>Media Type:</strong>{" "}
                                        {commissionToDisplay.media_type?.replace(/,/g, ",\n")}
                                    </p>
                                    <p>
                                        <strong>Files:</strong>
                                        <div className="details-right flex flex-row gap-2 max-w-[70%] flex-wrap">
                                            {
                                                commissionToDisplay?.images?.map((item) => {
                                                    return (
                                                        <CommissionMedia
                                                            mediaData={item}
                                                            width={"103px"}
                                                            imageContainerClass="thump-image"
                                                            imageClass="w-full h-full object-cover"
                                                        />
                                                    )
                                                })
                                            }
                                            {/* <div className="div relative">
                                                            <PlayIcon center={true} />
                                                            <CustomImage src={item.thumbnailUrl || item.url} alt={item.key} imageContainerClass="thump-image" imageClass="w-full h-full object-cover" />
                                                        </div> */}
                                        </div>
                                    </p>
                                </div>
                            )}
                            {activeTab === "creativedesign" && (
                                <div className="tab-content">
                                    <p>
                                        <strong>Creative Design:</strong>{" "}
                                        {commissionToDisplay.creative_design}
                                    </p>
                                </div>
                            )}
                            {activeTab === "themesprompts" && (
                                <div className="tab-content">
                                    <p>
                                        <strong>Themes Prompts:</strong>{" "}
                                        {commissionToDisplay.themes_prompts}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CommissionTabView;
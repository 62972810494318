import React from 'react'

function SchoolSidebarMyProjectsIcon() {
    return (
        <svg className="bx bx-pie-chart-alt icon" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.0184 14.6821L8.11011 16.7738L12.8165 12.0675M8.11011 7.36109V4.43269C8.11011 3.26121 8.11011 2.67548 8.3381 2.22804C8.53864 1.83445 8.85863 1.51446 9.25221 1.31392C9.69965 1.08594 10.2854 1.08594 11.4569 1.08594H19.4054C20.5769 1.08594 21.1626 1.08594 21.61 1.31392C22.0036 1.51446 22.3236 1.83445 22.5242 2.22804C22.7521 2.67548 22.7521 3.26121 22.7521 4.43269V12.3812C22.7521 13.5527 22.7521 14.1384 22.5242 14.5859C22.3236 14.9794 22.0036 15.2994 21.61 15.5C21.1626 15.728 20.5769 15.728 19.4054 15.728H16.477M5.18171 22.0031H13.1302C14.3017 22.0031 14.8874 22.0031 15.3349 21.7751C15.7285 21.5746 16.0485 21.2546 16.249 20.861C16.477 20.4136 16.477 19.8278 16.477 18.6564V10.7078C16.477 9.53637 16.477 8.95063 16.249 8.50319C16.0485 8.10961 15.7285 7.78961 15.3349 7.58907C14.8874 7.36109 14.3017 7.36109 13.1302 7.36109H5.18171C4.01024 7.36109 3.4245 7.36109 2.97706 7.58907C2.58348 7.78961 2.26348 8.10961 2.06294 8.50319C1.83496 8.95063 1.83496 9.53637 1.83496 10.7078V18.6564C1.83496 19.8278 1.83496 20.4136 2.06294 20.861C2.26348 21.2546 2.58348 21.5746 2.97706 21.7751C3.4245 22.0031 4.01024 22.0031 5.18171 22.0031Z" stroke="#667085" strokeWidth="2.09172" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default SchoolSidebarMyProjectsIcon
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function PublicOnlyRoute({ children }) {
    const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
    if (isLoggedIn) {
      return <Navigate to="/" replace />;
    }
    return children;
  }

  
export default PublicOnlyRoute

const restrictedDomains = [
    'gmail.com',
    'yahoo.com',
    'outlook.com',
    'hotmail.com',
    'aol.com',
    'icloud.com',
    'mail.com',
    'zoho.com',
    'protonmail.com',
    'gmx.com',
]

const checkRestrictedEmailDomains = async(email) => {
    try {
        if (!email) return false; // Return false if no email provided

        const emailDomain = email.split('@')[1]; // Extract domain from email
        if (!emailDomain) return false; // Return false if no domain is found

        return restrictedDomains.includes(emailDomain); // Check if domain is restricted
    } catch (error) {
        console.error('Error checking restricted email domains:', error);
        return false; // Return false if there's an error
    }
}

export default checkRestrictedEmailDomains;

import React from 'react';

function DribbbleIcon() {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 23.9573C18.4203 23.9573 23.625 18.7526 23.625 12.3323C23.625 5.91197 18.4203 0.707275 12 0.707275C5.57969 0.707275 0.375 5.91197 0.375 12.3323C0.375 18.7526 5.57969 23.9573 12 23.9573Z" fill="#EA4C89" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0.332275C5.37527 0.332275 0 5.70755 0 12.3323C0 18.957 5.37527 24.3323 12 24.3323C18.6117 24.3323 24 18.957 24 12.3323C24 5.70755 18.6117 0.332275 12 0.332275ZM19.9262 5.86373C21.3579 7.60776 22.217 9.83335 22.243 12.2411C21.9046 12.1761 18.5206 11.4863 15.1106 11.9158C15.0325 11.7466 14.9675 11.5644 14.8894 11.3822C14.6811 10.8876 14.4469 10.38 14.2126 9.89845C17.9869 8.36268 19.705 6.15006 19.9262 5.86373ZM12 2.10234C14.603 2.10234 16.9848 3.07848 18.7939 4.67935C18.6117 4.93965 17.0629 7.00907 13.4186 8.37565C11.7397 5.29106 9.87855 2.76612 9.5922 2.37566C10.3601 2.19345 11.1671 2.10234 12 2.10234ZM7.63995 3.06546C7.91325 3.42989 9.73538 5.96785 11.4404 8.98735C6.65076 10.2629 2.42083 10.2369 1.96529 10.2369C2.62907 7.06113 4.77657 4.41904 7.63995 3.06546ZM1.74404 12.3453C1.74404 12.2412 1.74404 12.1371 1.74404 12.033C2.18655 12.0459 7.15835 12.111 12.2733 10.5753C12.5727 11.1479 12.846 11.7336 13.1063 12.3192C12.9761 12.3583 12.8329 12.3974 12.7028 12.4364C7.41865 14.1413 4.60738 18.8008 4.3731 19.1912C2.7462 17.3822 1.74404 14.9744 1.74404 12.3453ZM12 22.5882C9.6312 22.5882 7.44469 21.7813 5.71367 20.4277C5.89588 20.0503 7.97827 16.0416 13.757 14.0243C13.783 14.0112 13.7961 14.0112 13.8221 13.9983C15.2668 17.7336 15.8525 20.8702 16.0087 21.7683C14.7722 22.3019 13.4186 22.5882 12 22.5882ZM17.7136 20.8312C17.6096 20.2064 17.0629 17.213 15.7223 13.5297C18.9371 13.0221 21.7484 13.8551 22.0998 13.9722C21.6573 16.8225 20.0173 19.2824 17.7136 20.8312Z" fill="#C32361" />
        </svg>
    );
}

export default DribbbleIcon;

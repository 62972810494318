export function filterEmailsByDomain(data) {
    // Extract the domain from the invitedBy email
    const invitedByDomain = data.invitedBy.split('@')[1];
    
    // Filter the emails array to get emails with the correct domain
    const validEmails = data.emails.filter(email => email.split('@')[1] === invitedByDomain);
    
    // Return the array of emails that do not have the correct domain
    return data.emails.filter(email => email.split('@')[1] !== invitedByDomain);
  }
  
import { useNavigate } from 'react-router-dom'
import NoResultScreen from '../../../components/no-result/NoResultScreen';
import { useState } from 'react';
import OutllinedPrimaryBtn from '../../../components/buttons/OutllinedPrimaryBtn';
import no_result_Lscreen_icon_1 from '../../../components/no-result/no-result-iscreen-icon-1.svg'
import ProjectList from './ProjectList';
import { useSelector } from 'react-redux';


const data = {
  pastProject: [
  //   {
  //   name: "New col with video",
  //   isPublished: true,
  //   creator_id: "01J4P4TVNHZMJ1FZVE6HRYPPCE",
  //   creator_collection_id: "01J86W50JGJ52T620MPY3PK8K4",
  //   last_published: "2024-09-21T06:52:35.117Z",
  //   images: [
  //     {
  //       url: "https://d57iif4fnkmjl.cloudfront.net/gallery/01J4P4TVNHZMJ1FZVE6HRYPPCE/01J86W50JGJ52T620MPY3PK8K4/video/01J89NMQ3A5PB3XFEFGFGPDXQ7",
  //       thumbnailUrl: "https://d57iif4fnkmjl.cloudfront.net/gallery/01J4P4TVNHZMJ1FZVE6HRYPPCE/01J86W50JGJ52T620MPY3PK8K4/video/01J89NMQ3A5PB3XFEFGFGPDXQ7_thumbnail",
  //       s3_key: "gallery/01J4P4TVNHZMJ1FZVE6HRYPPCE/01J86W50JGJ52T620MPY3PK8K4/video/01J89NMQ3A5PB3XFEFGFGPDXQ7",
  //       media_type: "video",
  //       filename: "SampleVideo_1280x720_2mb.mp4"
  //     },
  //     {
  //       url: "https://d57iif4fnkmjl.cloudfront.net/gallery/01J4P4TVNHZMJ1FZVE6HRYPPCE/01J86W50JGJ52T620MPY3PK8K4/01J86W50JHSZDRZSSQAWVZ6WM3",
  //       thumbnailUrl: "",
  //       s3_key: "gallery/01J4P4TVNHZMJ1FZVE6HRYPPCE/01J86W50JGJ52T620MPY3PK8K4/01J86W50JHSZDRZSSQAWVZ6WM3",
  //       media_type: "photography",
  //       filename: "horizontal.jpg"
  //     }
  //   ],
  //   image_count: 1,
  //   video_count: 1
  // },
  // {
  //   name: "mm",
  //   isPublished: false,
  //   creator_id: "01J4P4TVNHZMJ1FZVE6HRYPPCE",
  //   creator_collection_id: "01J8KY4VFHNFY0N42GKBFKTVM1",
  //   last_published: "2024-09-25T06:33:29.937Z",
  //   images: [
  //     {
  //       url: "https://d57iif4fnkmjl.cloudfront.net/gallery/01J4P4TVNHZMJ1FZVE6HRYPPCE/01J8KY4VFHNFY0N42GKBFKTVM1/01J8KY4VFJTRQKHWFM3JJRJ9XV",
  //       thumbnailUrl: "",
  //       s3_key: "gallery/01J4P4TVNHZMJ1FZVE6HRYPPCE/01J8KY4VFHNFY0N42GKBFKTVM1/01J8KY4VFJTRQKHWFM3JJRJ9XV",
  //       media_type: "photography",
  //       filename: "download.jpeg"
  //     }
  //   ],
  //   image_count: 1,
  //   video_count: 0
  // }
],
  activeProject: [
    // {
    //   name: "asd",
    //   isPublished: true,
    //   creator_id: "01J4P4TVNHZMJ1FZVE6HRYPPCE",
    //   creator_collection_id: "01J8EWHV8N7M0MSE667HE53DES",
    //   last_published: "2024-09-25T11:54:23.373Z",
    //   images: [
    //     {
    //       url: "https://d57iif4fnkmjl.cloudfront.net/gallery/01J4P4TVNHZMJ1FZVE6HRYPPCE/01J8EWHV8N7M0MSE667HE53DES/video/01J8MGG7VBTTZXSX1G260K4SWR",
    //       thumbnailUrl: "https://d57iif4fnkmjl.cloudfront.net/gallery/01J4P4TVNHZMJ1FZVE6HRYPPCE/01J8EWHV8N7M0MSE667HE53DES/video/01J8MGG7VBTTZXSX1G260K4SWR_thumbnail",
    //       s3_key: "gallery/01J4P4TVNHZMJ1FZVE6HRYPPCE/01J8EWHV8N7M0MSE667HE53DES/video/01J8MGG7VBTTZXSX1G260K4SWR",
    //       media_type: "video",
    //       filename: "istockphoto-1499384294-mp4-480x480-is.mp4"
    //     },
    //     {
    //       url: "https://d57iif4fnkmjl.cloudfront.net/gallery/01J4P4TVNHZMJ1FZVE6HRYPPCE/01J8EWHV8N7M0MSE667HE53DES/video/01J8GW0YKZ4F1TAVD0S91Y7JPA",
    //       thumbnailUrl: "https://d57iif4fnkmjl.cloudfront.net/gallery/01J4P4TVNHZMJ1FZVE6HRYPPCE/01J8EWHV8N7M0MSE667HE53DES/video/01J8GW0YKZ4F1TAVD0S91Y7JPA_thumbnail",
    //       s3_key: "gallery/01J4P4TVNHZMJ1FZVE6HRYPPCE/01J8EWHV8N7M0MSE667HE53DES/video/01J8GW0YKZ4F1TAVD0S91Y7JPA",
    //       media_type: "video",
    //       filename: "istockphoto-1499384294-mp4-480x480-is.mp4"
    //     },
    //     {
    //       url: "https://d57iif4fnkmjl.cloudfront.net/gallery/01J4P4TVNHZMJ1FZVE6HRYPPCE/01J8EWHV8N7M0MSE667HE53DES/01J8EWKVFJYT3Y1PJN0NAW9ZDJ",
    //       thumbnailUrl: "",
    //       s3_key: "gallery/01J4P4TVNHZMJ1FZVE6HRYPPCE/01J8EWHV8N7M0MSE667HE53DES/01J8EWKVFJYT3Y1PJN0NAW9ZDJ",
    //       media_type: "photography",
    //       filename: "Screenshot from 2024-09-23 09-36-43.png"
    //     },
    //     {
    //       url: "https://d57iif4fnkmjl.cloudfront.net/gallery/01J4P4TVNHZMJ1FZVE6HRYPPCE/01J8EWHV8N7M0MSE667HE53DES/video/01J8EWHV8ZNQ4CXPAEC4VR1VNP",
    //       thumbnailUrl: "https://d57iif4fnkmjl.cloudfront.net/gallery/01J4P4TVNHZMJ1FZVE6HRYPPCE/01J8EWHV8N7M0MSE667HE53DES/video/01J8EWHV8ZNQ4CXPAEC4VR1VNP_thumbnail",
    //       s3_key: "gallery/01J4P4TVNHZMJ1FZVE6HRYPPCE/01J8EWHV8N7M0MSE667HE53DES/video/01J8EWHV8ZNQ4CXPAEC4VR1VNP",
    //       media_type: "video",
    //       filename: "12433259-sd_640_360_30fps.mp4"
    //     }
    //   ],
    //   image_count: 1,
    //   video_count: 3
    // }  
  ]
};

const WelcomeScreen = () => {
  const navigate = useNavigate()
  return (
    <NoResultScreen
      btn={<OutllinedPrimaryBtn label='Upload work' onClick={()=>navigate('/school/gallery/upload')} className='px-4' />}
      heading='My projects'
      text1="You don't have any brand commissioned projects yet."
      text2="Upload your existing student work to our Swiirl gallery for brands to view and license. "
      img={no_result_Lscreen_icon_1}
    />
  )
}

export const LOADING = 'LOADING'
function SchoolHomePage() {
  const navigate = useNavigate()
  const {isNew} = useSelector(state=>state.user)
  const [haveProjects, setHaveProjects] = useState(LOADING)

  return (
    <>
      {
        !haveProjects &&
        <WelcomeScreen />
      }
      {
        haveProjects === LOADING || haveProjects === true  ?
        <div className='w-full flex flex-col gap-6 mt-16'>
          <div className="ml-12 ">
            <p className="text-4xl text-slate-700 font-bold">My projects</p>
            <div className="flex flex-col gap-y-16 mt-12">
              <ProjectList haveProjects={haveProjects} setHaveProjects={setHaveProjects} listTitle='Active projects' projectStatus='in_progress' />

              <ProjectList haveProjects={haveProjects} setHaveProjects={setHaveProjects} listTitle='Past projects' projectStatus='submitted' />

            </div>
          </div>
        </div>
        : null
      }
    </>
  )
}

export default SchoolHomePage
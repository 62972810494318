import BrandLogin from "../components/auth/BrandLogin";
import HomeComponent from "../components/HomeComponent";
import PublicOnlyRoute from "../HOC/PublicOnlyRouteLayout";
import ResetPassword from "../pages/auth/password/ResetPassword";
import Registration from "../pages/auth/registration/Registration";
import ForgotPassword from "../pages/auth/password/ForgotPassword";
import AdminLogin from "../pages/admin/adminBeta/AdminLogin";
import BrandList from "../pages/admin/BrandList";



const authRoutes = [
  {
    path: "/",
    element: <HomeComponent />,
  },
  {
    path: "/forgot-password",
    element: (
      <PublicOnlyRoute>
        <ForgotPassword />
      </PublicOnlyRoute>
    ),
  },
  {
    path: "/reset-password",
    element: (
      <PublicOnlyRoute>
        <ResetPassword />
      </PublicOnlyRoute>
    ),
  },
  {
    path: "/registration",
    element: (
      <PublicOnlyRoute>
        <Registration />
      </PublicOnlyRoute>
    ),
  },
  {
    path: "/login",
    element: (
      <PublicOnlyRoute>
        <BrandLogin />
      </PublicOnlyRoute>
    )
  },

  {
    path: "/admin/login",
    element: <AdminLogin />,
  },

 
  // {
  //   path: "/signup/brand",
  //   element: (
  //     <PublicOnlyRoute>
  //       <BrandSignup />
  //     </PublicOnlyRoute>
  //   )
  // },
  // {
  //   path: "/login/creator",
  //   element: (
  //     <PublicOnlyRoute>
  //       <CreatorLogin />
  //     </PublicOnlyRoute>
  //   )
  // },
];


export default authRoutes
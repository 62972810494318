import React, { useState } from 'react'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import AdminPage from './Admin'
import BrandList from './BrandList'
import CreatorList from './CreatorList'
import SchoolList from './SchoolList'

const AdminHomePage = () => {
    const [tabNo, setTabNo] = useState(1)

    const Tab = ({ text, isSelected, onClick }) => {
        return (
            <div onClick={onClick} className={`text-primary-black text-[1.2rem] p-3 rounded cursor-pointer hover:bg-violet hover:text-white ${isSelected ? "text-white bg-violet" : "bg-slate-200 "}`}>
                {text}
            </div>
        )

    }
    const handleTabChange = (tab) => {
        setTabNo(tab)
    }
    return (
        <div className="col-lg-12  custom-max-width pt-5  p-[4rem] ">
            <h1 className='text-primary-black bolder '>Admin Portal</h1>
            <div className='flex  gap-4  mt-5 border-b-2 pb-2'>
                <Tab text='Brand List' isSelected={tabNo === 1} onClick={() => handleTabChange(1)} />
                <Tab text='Creator List' isSelected={tabNo === 2} onClick={() => handleTabChange(2)} />
                <Tab text='School List ' isSelected={tabNo === 3} onClick={() => handleTabChange(3)} />
                <Tab text='Assign commission to school ' isSelected={tabNo === 4} onClick={() => handleTabChange(4)} />
            </div>
            <div className='pt-3'>

                {
                    tabNo === 1 && <BrandList />

                }
                {
                    tabNo === 2 && <CreatorList />

                }
                {
                    tabNo === 3 && <SchoolList />

                }
                {
                    tabNo === 4 && <AdminPage></AdminPage>

                }

            </div>
        </div>
    )
}

export default AdminHomePage
import { useState, useEffect, useRef } from "react";
import classes from "./CustomImage.module.css";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setGalleryScrollPostion } from "../../redux/actions";

const CustomImage = ({
  src = "",
  alt = "",
  imageClass = "",
  skeltonClass = "",
  cacheKey,
  onImageClick = () => null,
  imageContainerClass = "",
  children
}) => {
  const [loadingState, setLoadingState] = useState(true);
  const [imageError, setImageError] = useState(false);
  const [imageUrl, setImageUrl] = useState(cacheKey ? "" : src);
  const elemRef = useRef()
  const dispatch = useDispatch();
  const placeholderSrc =
    "https://rezista.in/wp-content/uploads/2020/07/Image-Placeholder-Dark.png";
  const handleOnload = () => {
    setLoadingState(false);
  };
  const handleError = () => {
    setImageError(true);
    setLoadingState(true);
  };
  const handleLoadStart = () => {
    setLoadingState(true);
  };

  useEffect(() => {
    setImageError(false)
  }, [src])

  useEffect(() => {
    // setLoadingState(true)
    if (cacheKey) {
      const cachedUrl = JSON.parse(sessionStorage.getItem(cacheKey));
      if (
        cachedUrl &&
        cachedUrl.expiry &&
        moment(cachedUrl.expiry).isAfter(moment())
      ) {
        setImageUrl(cachedUrl.url);
      } else {
        setImageUrl(src);
        sessionStorage.setItem(
          cacheKey,
          JSON.stringify({ url: src, expiry: moment().add({ hour: 1 }) })
        );
      }
    }
    else {
      setImageUrl(src)
    }
  }, [src, cacheKey]);

  const handleImageClick = () => {
    if(elemRef.current) {
      dispatch(setGalleryScrollPostion(elemRef.current.offsetTop-elemRef.current.scrollTop))
    }
    onImageClick()
  }
  
  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  return (
    <div ref={elemRef} onContextMenu={handleContextMenu} className={`group relative h-full w-full  max-w-[650px] ${imageContainerClass}  border-1 border-slate-200 `}>
      {(!cacheKey || imageUrl !== "") && (
        <img
          onLoad={handleOnload}
          onLoadStart={handleLoadStart}
          onError={handleError}
          onClick={handleImageClick}
          src={imageError ? placeholderSrc : imageUrl}
          alt={alt}
          className={`${imageClass}`}
          style={
            loadingState
              ? {
                opacity: 0,
                position: "absolute",
              }
              : {}
          }
          loading="lazy"
        />
      )}
      {children ? children : null}
      <div
        style={{
          display: !loadingState && "none",
        }}
        className={`${classes.skeleton} ${skeltonClass} `}
      ></div>
    </div>
  );
};

export default CustomImage;

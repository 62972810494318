import React from 'react'
import LeftArrowIcon from '../../icons/LeftArrowIcon'
import { SCREENS, useAuthScreen } from '../../contexts/AuthScreenContext'

const  CommonHeading=({backBtnClick,header,subHeading })=> {
    // const {setCurrentScreen} = useAuthScreen()
    return (
        <div className="flex flex-col items-center pr-3">
            <div className="flex flex-row gap-2 items-center abs">
                <p className="font-bold text-3xl flex flex-row items-center gap-3 pr-2">
                    <button 
                    onClick={backBtnClick ? backBtnClick : ""}  //()=>setCurrentScreen(SCREENS.GET_STARTED)
                    >
                        <LeftArrowIcon />
                    </button>
                   {header||"Welcome to Swiirl"}
                </p>
            </div>
            <p className="text-slate-500 text-[16px] -mt-2 pl-6">{subHeading||"To get started, please sign in"}</p>
        </div>
    )
}

export default CommonHeading

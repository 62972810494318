// import React from 'react'

// function InputField({
//     type="text",
//     name=name,
//     className="",
//     required=true,
//     placeholder="",
//     value="",
//     onChange=() => {},
//     label,
//     labelClass

// }) {
//   return (
//     <>
//     {
//       label &&
//       <label htmlFor={name} className={`text-slate-500  ${labelClass} `}>{label}</label>
//     }
//       <input onChange={onChange} name={name} value={value} type={type} id={name} class={`${className} bg-gray-50 border outline-none appearance-none text-gray-900 text-sm rounded-lg focus:ring-4 focus:ring-purple-200 focus:border-purple-200 block w-full p-2.5 `} placeholder={placeholder} required={required} />
//     </>
//   )
// }


// export default InputField

















import React, { useState } from 'react';
import EyeOff from '../../icons/EyeOff';
import EyeOn from '../../icons/EyeOn';

function InputField({
  type = "text",
  name,
  className = "",
  required = true,
  placeholder = "",
  value = "",
  onChange = () => { },
  label,
  labelClass = "",
  disabled = false,
  onKeyDown = () => {}
}) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  // Determine the type of input field (password or text)
  const inputType = type === 'password' && !isPasswordVisible ? 'password' : 'text';

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="relative w-full">
      {label && (
        <label htmlFor={name} className={`text-slate-500 ${labelClass}`}>
          {label}
        </label>
      )}
      <input
        disabled={disabled}
        onChange={onChange}
        name={name}
        value={value}
        type={inputType}
        id={name}
        className={`${className} bg-gray-50 border outline-none appearance-none text-gray-900 text-sm rounded-lg focus:ring-4 focus:ring-purple-200 focus:border-purple-200 block w-full p-2.5 pr-10`} // Add padding-right to avoid overlap with the icon
        placeholder={placeholder}
        required={required}
        onKeyDown={onKeyDown}
      />
      {type === 'password' && (
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute inset-y-0 right-0 top-8 flex items-center px-2"
          tabIndex={-1} // Avoid focusing on the button when pressing tab
        >
          {isPasswordVisible ? <EyeOff /> : <EyeOn />}
        </button>
      )}
    </div>
  );
}

export default InputField;

import { useSelector } from "react-redux";
import { purchaseContentByBrand } from "../../services/brandServices";
import { useState } from "react";
import Popup from "./PopupLayout/PopupLayout";
import HyperLinkIcon from "../../icons/HyperLinkIcon";
import { constants } from "../../constants/constants";
import { Link } from "react-router-dom";
import { downloadImageUtil } from "../../utils/downloadImage";

const PurchasePopupContent = ({ isOpen = false, togglePopup = () => null, onPurchase = () => null, contentData = {}, onCloseAfterPurchase = () => null }) => {
    const { userId, brandId } = useSelector((state) => state.user);
    const [loading, setLoading] = useState(false);
    const [successPurchase, setSuccessPurchase] = useState(false)
    if (!isOpen) return;

    const handleConfirm = async () => {
        successPurchase ? downLoad() : purchaseContent()
    }

    const downLoad = async () => {
        let payload = {};
        if(contentData?.media_type==='video') {
          payload = { imageUrl: contentData?.url, imageName: `${contentData?.id}.mp4`, setLoading }
        } else {
          payload = { imageUrl: contentData?.url, imageName: `${contentData?.id}.jpg`, setLoading }
        }
        await downloadImageUtil({ ...payload })
        togglePopup()
        onCloseAfterPurchase()
    }

    const purchaseContent = async () => {
        try {
            setLoading(true)
            const payload = {
                brand_id: brandId,
                gallery_content_id: contentData?.id,
            }
            await purchaseContentByBrand(payload)
            setLoading(false)
            setSuccessPurchase(true)
            localStorage.setItem("purchasedContent", contentData?.id);
            onPurchase && onPurchase(contentData?.id)
        } catch (error) {
            if (error?.response?.status === 403) {
                alert('Opps , someone just purchased this item before you');
                onPurchase(contentData?.id)
            }
            setSuccessPurchase(false)
            setLoading(false)
        }
    }

    const handleTogglePopup = () => {
        togglePopup()
        successPurchase && onCloseAfterPurchase()
    }

    const handleLicense = () => {
        window.open(constants.EXCLUSIVE_LISCENCE_LINK, "_blank")
    }

    return (
        <Popup isOpen={isOpen} loading={loading} togglePopup={handleTogglePopup} type={"confirm"} handleConfirm={handleConfirm}
            primaryButtonText={successPurchase ? 'Download now' : 'Purchase'} secondayButtonText={successPurchase ? 'Dismiss' : 'Cancel'}>
            {
                successPurchase ? <div>
                    <p className="font-bold text-xl "> Purchase Successful!</p>
                    <p className="">Your item will now be accessible in <Link to="/my-content?content=purchase" className="text-violet font-medium no-underline">"My content" page under "Purchase" tab.</Link></p>
                </div>
                    :
                    <>
                        <div className="flex gap-2 items-center  mb-4">
                            <h2 className="text-xl font-bold mb-0 ">
                                Proceed with 2 year exclusive license purchase?
                            </h2>
                            <div className="flex items-center" onClick={handleLicense}><HyperLinkIcon /></div>
                        </div>
                        <p>
                            The price of $250 will be added to your monthly bill. You can
                            access this image in “My content” section later.{" "}
                        </p>

                        {/* <p className="mt-3">
                            90% of this amount will go directly to this emerging creator.
                        </p> */}
                    </>
            }
        </Popup>
    )
}

export default PurchasePopupContent;
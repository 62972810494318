import React from 'react';

function YouTubeIcon() {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_140_5591)">
                <path d="M23.5216 6.51769C23.3859 6.0071 23.1185 5.54111 22.7462 5.16634C22.3738 4.79158 21.9095 4.52119 21.3998 4.38223C19.5234 3.87769 12.0234 3.87769 12.0234 3.87769C12.0234 3.87769 4.52344 3.87769 2.64707 4.38223C2.13737 4.52119 1.6731 4.79158 1.30073 5.16634C0.928354 5.54111 0.660943 6.0071 0.525256 6.51769C0.0234376 8.40223 0.0234375 12.3322 0.0234375 12.3322C0.0234375 12.3322 0.0234376 16.2622 0.525256 18.1468C0.660943 18.6574 0.928354 19.1234 1.30073 19.4981C1.6731 19.8729 2.13737 20.1433 2.64707 20.2822C4.52344 20.7868 12.0234 20.7868 12.0234 20.7868C12.0234 20.7868 19.5234 20.7868 21.3998 20.2822C21.9095 20.1433 22.3738 19.8729 22.7462 19.4981C23.1185 19.1234 23.3859 18.6574 23.5216 18.1468C24.0234 16.2622 24.0234 12.3322 24.0234 12.3322C24.0234 12.3322 24.0234 8.40223 23.5216 6.51769Z" fill="#FF0302" />
                <path d="M9.56934 15.9009V8.76367L15.8421 12.3323L9.56934 15.9009Z" fill="#FEFEFE" />
            </g>
            <defs>
                <clipPath id="clip0_140_5591">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.332275)" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default YouTubeIcon;

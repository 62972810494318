import React from 'react'

function EyeOff({ className="",  color="#98A2B3"}) {
    return (
        <svg className={className} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path 
             d="M8.59448 3.20222C8.93316 3.15214 9.28253 3.12528 9.64238 3.12528C13.8965 3.12528 16.6881 6.87932 17.6259 8.3643C17.7395 8.54404 17.7962 8.6339 17.828 8.77251C17.8518 8.87661 17.8518 9.04084 17.8279 9.14493C17.7961 9.28354 17.739 9.374 17.6247 9.55492C17.3748 9.95041 16.9938 10.5062 16.4891 11.109M5.24563 4.55448C3.4439 5.7767 2.22074 7.47477 1.65962 8.36302C1.5456 8.54351 1.48859 8.63376 1.45681 8.77235C1.43294 8.87645 1.43293 9.04065 1.45679 9.14475C1.48855 9.28335 1.5453 9.37321 1.65881 9.55293C2.59665 11.0379 5.38821 14.792 9.64238 14.792C11.3577 14.792 12.8353 14.1816 14.0494 13.3558M2.14237 1.45862L17.1424 16.4586M7.87461 7.19085C7.4222 7.64326 7.14238 8.26826 7.14238 8.95862C7.14238 10.3393 8.26166 11.4586 9.64238 11.4586C10.3327 11.4586 10.9577 11.1788 11.4101 10.7264" 
             stroke={color} 
             strokeWidth="2" 
             strokeLinecap="round" 
             strokeLinejoin="round" 
            />
        </svg>

    )
}

export default EyeOff
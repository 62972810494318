import React, { useState, useRef, useEffect } from 'react';
import './CustomDateRangePicker.css';
import LeftArrowIcon from '../../../icons/Buttons/LeftArrowIcon';
import RightArrowIcon from '../../../icons/Buttons/RightArrowIcon';
import Calendar from '../../../icons/Calendar';

const CustomDateRangePicker = ({ startDate, endDate, setDateRange, onClose = () => null, direction = "end", buttonClassName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoverDate, setHoverDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const pickerRef = useRef(null);


  const toggleCalendar = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleDateClick = (date) => {
    if (!startDate || (startDate && endDate)) {

      setDateRange({ startDate: date, endDate: null });
    } else if (startDate && !endDate) {
      if (date < startDate) {

        setDateRange({ startDate: date, endDate: startDate });
      } else {
        setDateRange({ startDate, endDate: date });
      }
    }
  };

  const handleDateHover = (date) => {
    if (startDate && !endDate) {
      setHoverDate(date);
    }
  };

  const changeMonth = (offset) => {
    const newMonth = currentMonth + offset;
    const newYear = currentYear + Math.floor(newMonth / 12);
    setCurrentMonth((newMonth + 12) % 12);
    setCurrentYear(newYear);
  };

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      onClose();
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);



  const isSameDay = (date1 = '', date2 = "") => {
    return (
      date1?.getDate() === date2?.getDate() &&
      date1?.getMonth() === date2?.getMonth() &&
      date1?.getFullYear() === date2?.getFullYear()
    );
  };


  const renderCalendar = (month, year) => {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfWeek = new Date(year, month, 1).getDay();
    const weeks = [];
    let days = [];

    for (let i = 0; i < firstDayOfWeek; i++) {
      days.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = new Date(year, month, day);
      const isInRange =
        (startDate && !endDate && hoverDate && currentDate >= startDate && currentDate <= hoverDate) ||
        (startDate && endDate && currentDate >= startDate && currentDate <= endDate);
      days.push(
        <div
          key={day}
          className={`calendar-day${isInRange ? ' in-range' : ''}${isSameDay(currentDate, startDate) ? ' start' : ''}${isSameDay(currentDate, endDate) ? ' end' : ''}`}

          // className={`calendar-day${isInRange ? ' in-range' : ''}${currentDate.getDate() === startDate.getDate() ? ' start ' : ''
          //   }${currentDate === endDate ? ' end ' : ''}`}
          onClick={() => handleDateClick(currentDate)}
          onMouseEnter={() => handleDateHover(currentDate)}
        >
          {day}
        </div>
      );

      if ((firstDayOfWeek + day) % 7 === 0 || day === daysInMonth) {
        weeks.push(
          <div key={`week-${day}`} className="calendar-week">
            {days}
          </div>
        );
        days = [];
      }
    }

    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const weekDays = dayNames.map((day, index) => (
      <div key={index} className='weekdayName'>{day}</div>
    ));

    return (
      <>
        <div className='flex'>{weekDays}</div>
        {weeks}
      </>
    )
  };

  const renderCalendars = () => {
    const calendars = [];
    for (let i = 0; i < 2; i++) {
      const month = (currentMonth + i) % 12;
      const year = currentYear + Math.floor((currentMonth + i) / 12);
      calendars.push(
        <div key={`${month}-${year}`} className={`calendar ${i === 1 && "left-b"}`}>
          <div className="calendar-header ">
            {i === 0 && (
              <button className="nav-button w-1/12 text-[1.2rem]" onClick={() => changeMonth(-1)}> <LeftArrowIcon /> </button>
            )}
            <div className='flex w-11/12 justify-center'>
              {new Date(year, month).toLocaleString('default', { month: 'long' })} {year}
            </div>
            {i === 1 && (
              <button className="nav-button me-2" onClick={() => changeMonth(1)}><RightArrowIcon /></button>
            )}
          </div>
          <div className="calendar-body">{renderCalendar(month, year)}</div>
        </div>
      );
    }
    return calendars;
  };

  return (
    <div className="custom-date-range-picker" ref={pickerRef}>
      <button className={`date-range-buttons rounded-lg box-shadow-default w-full cursor-pointer h-[35px] border border-silver flex items-center px-2  text-primary-black ${buttonClassName}`} onClick={toggleCalendar}>
        <Calendar />
        <span className='ms-2 text-primary-black '>
          {startDate ? startDate.toDateString() : 'Start Date'} - {endDate ? endDate.toDateString() : 'End Date'}
        </span>
      </button>
      {isOpen && (
        <div className="calendar-container">
          <div className="flex">{renderCalendars()}</div>
          <div className="actions py-3">
            <button
              className="button-secondary-sw text-primary-black"
              style={{ width: "80px" }}
              onClick={(e) => {
                e.preventDefault();
                const today = new Date();

                setDateRange({ startDate: today, endDate: today });
                setIsOpen(false);
              }}
            >
              Clear
            </button>
            <button className="button-primary-sw" onClick={(e) => {
              onClose();
              toggleCalendar(e);
            }}
              style={{ width: "80px" }}>
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomDateRangePicker;

import React from 'react'

function AddRounded({ className }) {
    return (
        <svg className={className} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.64202 6.30884V12.9755M6.30869 9.64217H12.9754M17.9754 9.64217C17.9754 14.2445 14.2444 17.9755 9.64202 17.9755C5.03965 17.9755 1.30869 14.2445 1.30869 9.64217C1.30869 5.0398 5.03965 1.30884 9.64202 1.30884C14.2444 1.30884 17.9754 5.0398 17.9754 9.64217Z"
                stroke="#7F56D9"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>


    )
}

export default AddRounded
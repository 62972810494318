import React from 'react';
import './Tooltip.css';

const Tooltip = ({ text, children, position = 'top', disable = false }) => {
  return (
    <div className={`tooltip-container tooltip-${position}`}>
      {children}
      {!disable&&text ?
        <div className={`tooltip-text tooltip-text-${position}`}>
          {text}
          <div className={`tooltip-arrow tooltip-arrow-${position}`}></div>
        </div>
        : ""}
    </div>
  );
};

export default Tooltip;

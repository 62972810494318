import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import peopleImage from '../../../../images/my-work/creatorWelcomGroupImage.svg';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import Search from '../../../../components/searchGroup/Search';
import LoadingWrapper from '../../../../components/loadingWrapper/LoadingWrapper';
import useDebounce from '../../../../utils/debounce';
import OutllinedPrimaryBtn from '../../../../components/buttons/OutllinedPrimaryBtn'
import "./CreatorCollectionsPage.css";
import ProfileCompleteAlert from './ProfileCompleteAlert';
import { fetchCreatorCollection, searchCreatorCollection, setSearchQuery } from '../../../../redux/reducerFiles/creatorCollectionReducer';
import MediaGridCard from '../../../../components/cards/MediaGridCard';
import moment from 'moment';


const WelcomeScreen = ({ onUploadClick }) => (
    <>
        <div className='mt-4'>
            <h1 className='text-[30px] font-bold'>Welcome to swiirl!</h1>
            <p className='text-[20px] text-[#475467]'>It’s so good to see you here.</p>
        </div>
        <div className='flex flex-col justify-center items-center'>
            <div className='mt-[130px]'>
                <img src={peopleImage} alt='peoples' width={600} />
            </div>
            <Link to='/creator/upload'>
                <PrimaryButton text="Let’s upload your work!" className='p-[10px] rounded-2 font-bold mt-[3rem]' />
            </Link>
        </div>
    </>
);

const WorkScreen = ({ searchText, setSearchText, collections, loadMore }) => {
    const navigate = useNavigate()
    const { isNew } = useSelector(state => state.user);
    const { hasMore, isLoading } = useSelector(state => state.creatorCollection)

    return (
        <>
            <div className='mt-4'>
                <h1 className='text-[30px] font-bold'>My Work</h1>
            </div>
            <div className='flex justify-end gap-3 mt-4'>
                <Search className='w-[391px]' value={searchText} onChange={(value) => setSearchText(value)} />
                <Link to='/creator/upload' className='no-underline		'>
                    <PrimaryButton text="Upload more work" className='button-primary-sw' />
                </Link>
            </div>
            {
                isNew &&
                <div className="py-8 pt-10">
                    <ProfileCompleteAlert creator_id={collections?.[0]?.creator_id} />
                </div>
            }
            <div className='pt-[50px]'>
                <div className='card-container mx-0 px-0 grid grid-cols-3 '>

                    {collections?.map((card, index) => (

                        <div key={card?.name} className='position-relative'>

                            <MediaGridCard
                                containerClass="h-[300px] w-full"
                                mediaCount={card.image_count + card.video_count}
                                mediaItems={card.images}
                                onCardClick={() => {
                                    navigate(`/creator/collection/${card?.creator_collection_id || ""}`)
                                }}
                            >
                                <div>
                                    <h3 className="text-[20px] font-[600] text-primary-black mt-3">{card.name}</h3>
                                    <p className="font-[400] text-[#475467] mb-0">
                                        {`${card.image_count || 'No'} ${card.image_count > 1 ? "images" : "image"} and ${card.video_count || 'No'} ${card.video_count > 1 ? "videos" : "video"} last uploaded ${moment((card?.last_published || card?.updated_at))?.format('MMMM DD, YYYY')}` || card.campaign_goal}
                                    </p>
                                </div>
                            </MediaGridCard>
                            <div className={`min-w-[100px]  p-3 py-2 text-center font-[500]  rounded-pill  position-absolute top-4 right-4  border-[1px] ${card?.isPublished ? "publish-btn" : "unpublish-btn"}`}>
                                {card?.isPublished ? " Published" : "Unpublished"}
                            </div>

                        </div>
                    ))}
                    <LoadingWrapper loading={isLoading} />
                </div>
                {
                    hasMore &&
                    <div className="w-32 mx-auto mt-12">
                        <OutllinedPrimaryBtn onClick={loadMore} disabled={isLoading || !hasMore} label='Load more' />
                    </div>
                }
            </div>

        </>
    )
};

const CreatorCollectionsPage = () => {
    // const [isLoading, setIsLoading] = useState(true);
    const { userId } = useSelector((state) => state.user);
    const { data, hasMore, isLoading, error, query, startScreen } = useSelector((state) => state.creatorCollection);
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState(query);
    const debounceSearchText = useDebounce(searchText, 500);

    const dispatch = useDispatch();

    // Check if initial data has already been fetched before dispatching the action
    useEffect(() => {
        if (startScreen) {
            dispatch(fetchCreatorCollection({ page: 1, creatorId: userId }));
        }
    }, [dispatch, startScreen, userId]);

    // Trigger search only if the debounced search text changes and it's different from the existing query
    useEffect(() => {
        if (debounceSearchText !== query) {
            dispatch(setSearchQuery(debounceSearchText));
            dispatch(searchCreatorCollection({ query: debounceSearchText, creatorId: userId }));
            setPage(1); // Reset page when new search is initiated
        }
    }, [debounceSearchText, query, userId, dispatch]);

    // Load more data on button click
    const loadMore = () => {
        if (hasMore && !isLoading) {
            const nextPage = page + 1;
            dispatch(fetchCreatorCollection({ page: nextPage, creatorId: userId, query }));
            setPage(nextPage);
        }
    };


    return (
        <div className='grid-container'>
            <div className='col-lg-12 mb-5'>
                <div className='row align-items-center w-full custom-max-width'>
                    <LoadingWrapper loading={isLoading && startScreen}>
                        {
                            (startScreen && !searchText.length) ?
                                <WelcomeScreen /> :
                                <WorkScreen loadMore={loadMore} searchText={searchText} setSearchText={setSearchText} collections={data} />
                        }
                    </LoadingWrapper>
                </div>
            </div>
        </div>
    );
};

export default CreatorCollectionsPage;
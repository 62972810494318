import React from "react";

const MediaTypeIcon = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
      >
        <circle cx="30" cy="30" r="30" fill="#F4EBFF" />
        <svg
          x="15"
          y="15"
          width="30"
          height="30"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="camera-01">
            <g id="Icon">
              <path
                d="M3.34668 10.7215C3.34668 10.2836 3.34668 10.0647 3.36495 9.88026C3.54118 8.10159 4.94826 6.6945 6.72694 6.51827C6.91136 6.5 7.14213 6.5 7.60366 6.5C7.7815 6.5 7.87042 6.5 7.94591 6.49543C8.90994 6.43704 9.75412 5.8286 10.1144 4.9325C10.1426 4.86232 10.1689 4.78321 10.2217 4.625C10.2744 4.46679 10.3008 4.38768 10.329 4.3175C10.6892 3.4214 11.5334 2.81296 12.4974 2.75457C12.5729 2.75 12.6563 2.75 12.8231 2.75H18.8703C19.037 2.75 19.1204 2.75 19.1959 2.75457C20.1599 2.81296 21.0041 3.4214 21.3644 4.3175C21.3926 4.38768 21.4189 4.46679 21.4717 4.625C21.5244 4.78321 21.5508 4.86232 21.579 4.9325C21.9392 5.8286 22.7834 6.43704 23.7474 6.49543C23.8229 6.5 23.9119 6.5 24.0897 6.5C24.5512 6.5 24.782 6.5 24.9664 6.51827C26.7451 6.6945 28.1522 8.10159 28.3284 9.88026C28.3467 10.0647 28.3467 10.2836 28.3467 10.7215V20.5C28.3467 22.6002 28.3467 23.6503 27.938 24.4525C27.5784 25.1581 27.0048 25.7317 26.2991 26.0913C25.497 26.5 24.4469 26.5 22.3467 26.5H9.34668C7.24648 26.5 6.19638 26.5 5.39422 26.0913C4.68861 25.7317 4.11493 25.1581 3.75541 24.4525C3.34668 23.6503 3.34668 22.6002 3.34668 20.5V10.7215Z"
                stroke="#7F56D9"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.8467 20.875C18.6081 20.875 20.8467 18.6364 20.8467 15.875C20.8467 13.1136 18.6081 10.875 15.8467 10.875C13.0853 10.875 10.8467 13.1136 10.8467 15.875C10.8467 18.6364 13.0853 20.875 15.8467 20.875Z"
                stroke="#7F56D9"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </g>
        </svg>
      </svg>
    </div>
  );
};

export default MediaTypeIcon;

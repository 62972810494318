export const downloadImageUtil = async({setLoading=()=>null, imageUrl, imageName="image.jpg"}) => {
    if(!imageUrl) return;
    try {
        setLoading(true)
        const response = await fetch(imageUrl);
        const blob = await response.blob();
  
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', imageName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        setLoading(false)
      } catch (error) {
        console.log('Error downloading image:', error);
        setLoading(false)
      }
}
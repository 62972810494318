import React from 'react'
import NothingToShowSvg from '../images/my-work/NothingToShow.svg'
import { useNavigate } from 'react-router-dom'

function NothingToShowForCreator() {
    const navigate = useNavigate()
    return (
        <div className="flex flex-row gap-8">
            <img src={NothingToShowSvg} alt="NOthing to show" className='h-[400px]' />
            <div className="flex flex-col gap-y-4 w-96">
                <p className="font-semibold text-2xl text-primary-black"> You haven't published any of your content yet</p>
                <p className="font-medium text-xl text-primary-black"> Publish your collections to our store to start selling your work!</p>
                <button onClick={() => navigate('/creator')} className={`font-bold border-[1px] border-violet p-2 rounded text-violet w-72`}> View my collections</button>
            </div>
        </div>
    )
}

export default NothingToShowForCreator
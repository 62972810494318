import React from "react";

const DownloadIcon = ({stroke="#6941C6"}) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.40234 1.95557V15.9556M8.40234 15.9556L15.4023 8.95557M8.40234 15.9556L1.40234 8.95557"
        stroke={stroke||"#6941C6"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownloadIcon;

import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
        <div className=''>
            <footer className="footer">
                <div className="footer-content">
                    <img
                        alt="swiirl-logo"
                        className="swiirl-logo"
                        src="/home/swiirl-logo.png"
                    />
                    <span>2024, All rights reserved.</span>
                </div>
            </footer>
        </div>
    )
}

export default Footer